import React,{useState,useEffect,useCallback} from "react";
import MUIDataTable from "mui-datatables";
import  { Storage } from 'aws-amplify';
import { Auth } from 'aws-amplify';



Storage.configure({ level: 'Protected' });

export default function AmplifyComp() {

  const [dd,setDD]=useState([])
  const [filess,setFiless]=useState([])
  const [userData,setUserData]=useState({});
  
  const [fileURL1,setFileURL1]=useState("")

 const queryGroupInfo = useCallback( async  () => {
    setDD([]);
    console.log(dd);
 })
  
 async function onChange(e) {
  const file = e.target.files[0];
  setUserData(await Auth.currentCredentials());
  try {
    await Storage.put(file.name, file, {
      level: 'protected',
      contentType: 'image/png' // contentType is optional
    });
  } catch (error) {
    console.log('Error uploading file: ', error);
  }  
}

  useEffect(() => {
    queryGroupInfo()
    listFiles()
    console.log(editable)
    //selectFile("DharnishDemo.pdf")
  }, []); 

  const cols = [
      
      {name:"name",label:"Name"
    },
      {name:"description",label:"description",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button   onClick={() => {
              getImgUrl(value).then((aa) => {
                
                var link = document.createElement("a");
                link.download = '.';
                link.href = aa;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                
                console.log(aa)} )
              
            }} > prd</button> 
          );
        }
      }

    }
  ]

  const data1= [
    { name: 'Mehmet', surname: 'Baran', birthYear: 1987, id: 63 },
    { name: 'Zerya Betül', surname: 'Baran', birthYear: 2017, id: 34 },
  ]   
  
  const selectFile =  fileMame => {    
     Storage.get(fileMame)
  }

  const listFiles = async () => {
    setFiless( await Storage.list(''))

    console.log('s3')
    console.log(filess)
  }

  
  const  getImgUrl = async key => {
    console.log(key)
    return Storage.get(key,{level:'protected',download:false, contentType:"application/pdf"})
    .then(res => {       
       return (res)})
    .catch(err => console.log(err))
}

  const editable = dd.map(o => ({ ...o}));

  return (
    <>
        <div> Amplify with S3</div>
        <div>

        <input
              type="file"
              onChange={onChange}
        />

        {/* <Link href={fileURL1} target="_blank"> Selected File </Link> */}
        
        </div>  
         Total ${dd.length} groups
        <MUIDataTable
            title="Employee List"
            data={editable}
            columns={cols}         
            options={{
              selectableRows: 'none' 
            }}   
          />

        <div> Update data</div>
    </>
  );
}
