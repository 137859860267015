import React from 'react';
import axios from 'axios';

/**
 * @typedef {import('../types').Patient} Patient
 * @typedef {import('../types').PatientInfoError} PatientInfoError
 * @typedef {import('../types').PatientInfoCategorized} PatientInfoCategorized
 * @typedef {import('../types').PatientSearchOptions} PatientSearchOptions
 */

const nonDigitRegexp = /[^+\d]/g;
const validPhoneRegexp =
  /^(\+\d{1,2}\s?)?\(?(\d{3})\)?[\s.-]?(\d{3})[\s.-]?(\d{4})$/;

/**
 * Formats a phone number into a standard format of (XXX) XXX-XXXX
 * @param {String|Number} phoneNumber - The phone number to format
 * @returns Formatted number or input if invalid
 */
export function formatPhoneNumber(phoneNumber) {
  if (typeof phoneNumber !== 'string') return '';
  let phoneString = '' + phoneNumber;
  phoneString = phoneString.replace(nonDigitRegexp, '');
  let match = phoneString.match(validPhoneRegexp);
  if (match) {
    var intlCode = match[1] ? match[1] + ' ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return '' + phoneNumber;
}

/**
 * Formats an object key in a human readable string
 * @param {String} key - The object key to split
 * @returns Human readable key
 */
export function formatKey(key) {
  if (typeof key !== 'string') return '';
  const splitkey = key.split(/(?=[A-Z])/).join(' ');
  return splitkey.charAt(0).toUpperCase() + splitkey.slice(1);
}

/**
 * Check a patient information for errors
 *
 * @param {Patient} patient
 * @returns {PatientInfoError} - Patient information errors
 */
export function checkPatientErrors(patient) {
  if (!patient) return { errors: {}, hasError: false };
  const { dobError, insuranceError, primaryDobError, zipError } = patient;
  if (dobError || insuranceError || primaryDobError || zipError) {
    return {
      errors: {
        dobError,
        insuranceError,
        primaryDobError,
        zipError
      },
      hasError: true
    };
  }
  return { hasError: false, errors: {} };
}

/**
 * Categorizes a patient's information into different related categories
 * 
 * @param {Patient} patientInfo 
 * @returns {PatientInfoCategorized} - Categorized patient information
 */
export function categorizePatientInfo(patientInfo) {
  if(typeof patientInfo !== 'object') throw new Error("Invalid patient information");
  const categories = {
    'Patient': [
      'patientId',
      'firstName',
      'lastName',
      'dob',
      'phone',
      'sex',
      'address',
      'city',
      'state',
      'zip',
      'passport'
    ],
    'Insurance': [
      'provider',
      'providerOther',
      'memberId',
      'groupNumber',
      'insuranceStatus',
      'insuranceStatus',
      'insuranceVerified'
    ],
    'Primary Contact': [
      'primaryDob',
      'primaryFirstName',
      'primaryLastName',
      'relationToPrimary'
    ],
    'Other': [
      'referrer',
      'referrerOther'
    ],
    'Images': [
      'governmentId',
      'governmentIdKey',
      'insuranceCardBack',
      'insuranceCardBackKey',
      'insuranceCardFront',
      'insuranceCardFrontKey'
    ]
  };

  const patient = {}
  for(const [category, keys] of Object.entries(categories)) {
    patient[category] = {};
    for(const key of keys) {
      if (typeof patientInfo[key] === 'undefined') {
        // throw new Error(`Invalid patient object: missing field ${key}`)
        patient[category][key] = "";
        continue
      }
      patient[category][key] = patientInfo[key];
    }
  }
  return patient
};

/**
 * Searches for a patient using the given search options
 *
 * @param {Object} searchOptions 
 * @returns {Promise<Patient[]>} - Patient search results
 */
export async function searchPatients(searchOptions) {
  const { firstName, lastName, email } = searchOptions
  if (!firstName && !lastName && !email) throw new Error("Invalid search options");
  let response;
  try {
    response = await axios.get("https://kbq3anyani.execute-api.us-east-1.amazonaws.com/dev/patients", {
      params: {
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || ""
      }
    })
  } catch(e) {
    throw new Error("Error fetching patients: ", e);
  }
  const { status, data } = response;
  if (status !== 200) throw new Error(`Error fetching patients: ${status}`);
  return data;
}
