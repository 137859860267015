import React, {useState,useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {useNavigate} from 'react-router-dom';

import { Grid } from '@mui/material';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ScienceIcon from '@mui/icons-material/Science';
import SummarizeIcon from '@mui/icons-material/Summarize';


import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    locationButtonsContainer: {
       [theme.breakpoints.between("xs", "sm")]: {
            padding: '0 0',
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: '0 25%',
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: '0 30%',
        },
        [theme.breakpoints.up("lg")]: {
            padding: '0 35%',
        },
    },
    selectionsContainer: {
        [theme.breakpoints.between("xs", "sm")]: {
             padding: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
             padding: '0 15%',
         },
         [theme.breakpoints.between("md", "lg")]: {
             padding: '0 20%',
         },
         [theme.breakpoints.up("lg")]: {
             padding: '0 25%',
         },
     },
    tableMargins: {
        [theme.breakpoints.between("xs", "sm")]: {
            margin: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
            margin: '0 6%',
         },
         [theme.breakpoints.between("md", "lg")]: {
              margin: '0 10%',
         },
         [theme.breakpoints.up("lg")]: {
             margin: '0 15%',
         },
     },
     scanTubesMargins: {
         [theme.breakpoints.between("sm", "md")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.between("md", "lg")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.up("lg")]: {
            marginRight: '3%'
         },
         backgroundColor: 'royalblue', 
        color: 'white', 
        borderRadius: '6px', 
        padding: '10px 4px',
        transition: 'background-color .4s',
        "&:hover": {
            backgroundColor: 'lightgrey',
            color: 'royalblue'
        },
        cursor: 'pointer'
     },
     sendResultsMargins: {
         [theme.breakpoints.between("sm", "md")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.between("md", "lg")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.up("lg")]: {
             marginRight: '3%'
         },
        backgroundColor: 'royalblue', 
        color: 'white', 
        transition: 'background-color .4s',
        "&:hover": {
            backgroundColor: 'lightgrey',
            color: 'royalblue'
        },
        borderRadius: '6px', 
        padding: '10px 4px',
        cursor: 'pointer'
     }
}));

export default function ReportsMenu(props){
    const classes = useStyles();
    const navigate = useNavigate();
    const [approved,setApproved] = useState(0);

    //need to check useeffect to check with auth account is connected.
    // todo: check if we need to use useEffect when history is changed.
    useEffect(() => {
      async function checkAuth(){
        const groups = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload["cognito:groups"];
        if(!groups){
            setApproved(0);
            navigate('/app/error');
        }
        else if(!groups.includes('Compliance')){
            setApproved(0);
            navigate('/app/error');
        }
        else{
            setApproved(1);
        }
      }
      checkAuth();
    });

    return (
        <Grid container height="calc(100% - 84px - 64px)">
            {approved ? 
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <h1 style={{color: 'royalblue', marginBottom: '75px', textAlign: 'left', marginTop: '10px'}}>Welcome To the Manager Menu</h1>

                <Grid container className={classes.selectionsContainer}>

                    <Grid container style={{paddingTop:'75px'}} wrap="nowrap">
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid className={classes.sendResultsMargins} item onClick={() => navigate('/app/paymentstatus')} xs={12} >
                                <Grid item xs={12} style={{paddingTop: '17px'}}>
                                    <AttachMoneyIcon/>
                                </Grid> 
                                <h3 style={{marginTop: '7px'}}>Check Payments</h3>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid className={classes.sendResultsMargins} item onClick={() => navigate('/app/compliancereports')} xs={12} >
                                <Grid item xs={12} style={{paddingTop: '17px'}}>
                                    <ScienceIcon/>
                                </Grid>
                                <h3 style={{marginTop: '7px'}}>Lab Menu</h3>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Grid className={classes.sendResultsMargins} item onClick={() => navigate('/app/results')} xs={12} >
                                <Grid item xs={12} style={{paddingTop: '17px'}}>
                                    <SummarizeIcon/>
                                </Grid>
                                <h3 style={{marginTop: '7px'}}>Send Results</h3>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={3}>
                            <Grid className={classes.sendResultsMargins} item onClick={() => navigate("/app/sendresults")} xs={12} >
                                <Grid item xs={12} style={{paddingTop: '17px'}}>
                                    <MailIcon/>
                                </Grid>
                                <h3 style={{marginTop: '7px'}}>Send Multiplex</h3>
                            </Grid>
                        </Grid> */}
                    </Grid>

                </Grid>
            </Grid> : ""
            }
        </Grid>
    );
}

