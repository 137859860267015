import React, { useState } from 'react';
import {
  TextField,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';

import { Button } from '../../components/Wrappers/Wrappers';
import { searchPatients } from '../../utils';

export default function SearchForm({ setPatients, ...props }) {
  function onChange(e) {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  }
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      boxShadow={8}
      borderRadius={5}
      display='flex'
      flexDirection='column'
      justifyContent='space-around'
      p={2}
      marginRight='1rem'
      marginTop={{ sm: '-15rem' }}
      maxWidth='375px'
      width='100%'
      height='20rem'
    >
      <TextField
        label='First Name'
        name='firstName'
        autoComplete='off'
        value={formState.firstName}
        onChange={onChange}
      />
      <TextField
        label='Last Name'
        name='lastName'
        autoComplete='off'
        value={formState.lastName}
        onChange={onChange}
      />
      <TextField
        label='Email'
        name='email'
        autoComplete='off'
        value={formState.email}
        onChange={onChange}
      />
      <Button
        variant='contained'
        color='primary'
        onClick={async e => {
          e.preventDefault();
          try {
            const patients = await searchPatients(formState);
            setPatients(patients || []);
            if (!patients || patients.length === 0) {
              throw new Error("No patients found");
            }
          } catch (err) {
            setErrorMessage(err.message);
            setTimeout(() => setErrorMessage(''), 5000);
            console.log(err)
          }
        }}
      >
        Search
      </Button>
      <Snackbar open={!!errorMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ marginTop: '5rem' }}>
        <Alert severity='error'>{errorMessage}</Alert>
      </Snackbar>
    </Box>
  );
}
