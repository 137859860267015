
import moment from "moment";


export class autoFillFunctions { 

    formatNumToString(potentialNum){ 
        return potentialNum.replace(/[A-Za-z]/g, "") 
    }
    formatPhoneNumber(phone){ 
        //const phoneregex = '/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im'
        const phoneTest = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);

        phone = phone.trim();
        var results = phoneTest.exec(phone);
        if (results !== null && results.length > 8) {   
    
            return "(" + results[3] + ") " + results[4] + "-" + results[5] + (typeof results[8] !== "undefined" ? " x" + results[8] : "");
        }
        else {
             return phone;
        }
    }
    /**
     * 
     * @param {string}location 
     * Determines to location that the user is by comparing it our list     
     */
    parseDrugs(drugs) {
        // No Known Drug Allergies (NKDA), Penicillin, Sulfonamide, ACE inhibitors, Prozac, Amoxicillin, Other
        const drugArray = ['nkda', 'penicillin', 'sulfonamide', 'ace inhibitors', 'prozac', 'amoxicillin', 'other']
        const drugArrayFinal  = ['No Known Drug Allergies (NKDA)', 'Penicillin', 'Sulfonamide', 'ACE inhibitors', 'Prozac', 'Amoxicillin', 'Other']
        const drugA1 = ['no']
        const formattedDrug  = drugs.trim().toLowerCase()
        let finalDrug = []
        drugArray.forEach((drugs  , index ) => {
            if(formattedDrug.includes(drugs)){
                finalDrug.push(drugArrayFinal[index])

            }
        })
        if(formattedDrug.includes(drugA1[0])){
            finalDrug[0] = drugArrayFinal[0]
        }
        if(finalDrug.length === undefined){
            return {
                fieldName : 'drugAllergies' ,
                message : 'Was not able to parse any Drug Allergies. '     
            }
        }
        else{
            return finalDrug
        }
    }
    parseSymptoms(symptoms ) {
        //['Fever & Chills', 'Fatigue', 'Cough', 'Sore Throat', 'Loss of taste', 'Difficulty Breathing', 'Nausea and vimiting', 'Diarrahea', 'Myalgia', 'Headache', 'Unspecified Abdominal pain', 'Nasal Congestion', 'No Symptoms']
    const symptomsArray = ['fever & chills', 'fatigue', 'cough', 'sore throat', 'loss of taste', 'difficulty Breathing', 'nausea and vomiting', 'diarrahea', 'myalgia', 'headache', 'unspecified abdominal pain', 'nasal congestion', 'no symptoms']
    const symptomsArrayFinal = ['Fever & Chills', 'Fatigue', 'Cough', 'Sore Throat', 'Loss of taste', 'Difficulty Breathing', 'Nausea and vomiting', 'Diarrahea', 'Myalgia', 'Headache', 'Unspecified Abdominal pain', 'Nasal Congestion', 'No Symptoms']
    const symptomsA1 = ['no']
    const formattedSymptoms  = symptoms.trim().toLowerCase()
    let finalSymptoms  = []
    symptomsArray.forEach((symptoms , index) => {
        if(formattedSymptoms.includes(symptoms)){
            finalSymptoms.push(symptomsArrayFinal[index])
        }
    })
    if(formattedSymptoms.includes(symptomsA1[0])){
        finalSymptoms[0] = symptomsArrayFinal[0]
    }
    if(finalSymptoms.length === undefined){
        return {
            fieldName : 'symptoms' ,
            message : 'was not able to parse any symptoms. '
        }
    }
    else{
        return finalSymptoms
    }
 }

    parseMedical(medical){
        //['No Past Medical History', 'Hypertension', 'Diabetes Mellitus', 'Hyperlipidemia', 'Hypothyroidism', 'Asthma', 'COPD', 'Other']
       const medicalArray  = ['no past medical history', 'hypertension', 'diabetes mellitus', 'hyperlipidemia', 'hypothyroidism', 'asthma', 'copd', 'other']
       const medicalArrayFinal  = ['No Past Medical History', 'Hypertension', 'Diabetes Mellitus', 'Hyperlipidemia', 'Hypothyroidism', 'Asthma', 'COPD', 'Other']
       const medicalA1  = ['no']
       const formattedMedical  = medical.trim().toLowerCase()
       let finalMedical  = []
       medicalArray.forEach((medical,  index ) => {
        if(formattedMedical.includes(medical)){
            finalMedical.push(medicalArrayFinal[index])
         }
    })
    if(formattedMedical.includes(medicalA1[0])){
        finalMedical[0] = medicalArrayFinal[0]
    }
    if(finalMedical.length === undefined){
        return {
            fieldName : 'pastMedical' ,
            message : 'Was not able to parse any Past Medical History. '
        }
    }
    else {
        return finalMedical

    }}
    parseMedications(medication ){
        //["No Medications Reported" "General diabetes medication(insulin)" ,"General Birth Control" ,"General celiac disease medication","General antidepressant","General Thyroid","Genral Blood Pressure","Adderall","Hormone medication","Aspirin","Levothyroxine","Lyrica","Crestor","Humira","Lexapro","Prozac","Seasonale","Sovaldi","Synthroid","Zoloft","Other"]
       const medicationsArray  = ["no medications reported" ,"general diabetes medication(insulin)" ,"general birth control" ,"general celiac disease medication","general antidepressant","general thyroid","genral blood pressure","adderall","hormone medication","aspirin","levothyroxine","lyrica","crestor","humira","lexapro","prozac","seasonale","sovaldi","synthroid","zoloft","other"]
       const medicationsArrayFinal  = ["No Medications Reported" , "General diabetes medication(insulin)" ,"General Birth Control" ,"General celiac disease medication","General antidepressant","General Thyroid","Genral Blood Pressure","Adderall","Hormone medication","Aspirin","Levothyroxine","Lyrica","Crestor","Humira","Lexapro","Prozac","Seasonale","Sovaldi","Synthroid","Zoloft","Other"]
       const medicationsA1  = ['no']
       const formattedMedications  = medication.trim().toLowerCase()
       let finalMedications = []
         medicationsArray.forEach((medication, index  ) => {
        if(formattedMedications.includes(medication)){
            finalMedications.push(medicationsArrayFinal[index])
         }
    })
    if(formattedMedications.includes(medicationsA1[0])){
        finalMedications[0] = medicationsArrayFinal[0]
    }
    if(finalMedications.length === undefined){
        return {
            fieldName : 'medication' ,
            message : 'Was not able to parse any reported medications. '
        }
    }
    else {
        return finalMedications
    
    }}
    

    parseLocation(location){ 
        const locationArray = ['chelsea' , 'upper west' , 'noho' , 'upper east' , 'midtown' , 'chinatown' , 'astoria']
        const locationArrayFinal = ['Chelsea' , 'Upper West Side' , 'Noho' , 'Upper East Side' , 'Midtown East' , 'Chinatown' , 'Astoria']
        const formattedLocation = location.trim().toLowerCase()
        let finalLocation  = ''
        locationArray.forEach((checkedLocation , index) => {
            if(formattedLocation.includes(checkedLocation)){
                finalLocation = locationArrayFinal[index]
            }
            
        });

        if(finalLocation === ''){ 
            return { 
                fieldName : 'Location' , 
                message : 'Could not match this appointment to a known ClearMD location. Perhaps it is in Harlem? '
            }
        }
        else { 
            return finalLocation
        }
    }
    parseReason(reason) {
        if(reason.includes('potential') || reason.includes('Potential')){ 
            return 'Potential'
        }
       if(reason.includes('confirmed')|| reason.includes('Confirmed')){ 
            return 'Confirmed'
        }
        else { 
            return { 
                fieldName : 'Reason' , 
                message : 'Could not determine reason for Cod-19 test. Thou should investigate.'
            }
        }
    }

    parseVaccine(vaccine ){ 
        const vaccineTypes = ['None' , 'Johnson & Johnson' , 'Pfizer' , 'Moderna' , 'AstraZeneca' , 'Sinovac' , 'Sinopharm' , 'Other']
        const vaccineArray = ['no' , 'johns' , 'pfiz' , 'moder' , 'ast' , 'sinov' , 'sinoph' , 'other'] 
        const formattedVaccine = vaccine.trim().toLowerCase()
         let finalVaccine = ''
        vaccineArray.forEach((vaccine  , index ) => { 
             if(formattedVaccine.includes(vaccine.toLowerCase())){
                 console.log('Found')
                 finalVaccine = vaccineTypes[index]
             }
        })
    
         if(finalVaccine === ''){ 
             return { 
                 fieldName : 'vaccineType' , 
                 message : 'Could not determine the type of vaccine through inbuilt list. Do some investigation'
             }
         }
         else { 
             return finalVaccine
             //isReturnStatement : VaccinesOutlined
         }
     }
     
     parseDateOfService(dos) {
        return moment(new Date(dos)).format('MM/DD/YYYY hh:mm A')
     } 
     parseDateofBirth(dob){ 
        try{  
            console.log(`Date of Birth ${moment(new Date(dob)).format('MM/DD/YYYY')}`)
            return moment(new Date(dob)).format('MM/DD/YYYY hh:mm A')
        }
        catch(error){ 
            return { 
                fieldName : 'Date of Birth' , 
                message : 'Could not automaticaly discover DOB.'
            }
        }

     }

    parseHeartRate(heartRate) { 
        let finalHeartRate
        if(heartRate.trim() !== ''){ 
            if(parseInt(heartRate)){ 
                finalHeartRate = parseInt(heartRate)
            }
        }

        if(finalHeartRate === undefined || typeof(finalHeartRate)!== 'number'){ 
            return { 
                fieldName : 'Heart Rate' , 
                message : 'Could not decipher heart rate'
            }
        }
        else {  
            return finalHeartRate.toString()
        }
        
    }
    parseOxygen(oxygen) { 
        let finalOxygen
        if(oxygen.trim() !== ''){ 
            if(parseInt(oxygen)){
                finalOxygen = parseInt(oxygen).toString()
            }
            else if(parseFloat(oxygen)){ 
                finalOxygen = parseFloat(oxygen).toFixed(0).toString()
            }
        }

        if(finalOxygen === undefined){ 
            return {
                fieldName : 'Oxygen' , 
                message : 'Could not parse the oxygen rate. You should investigate'
            }
        }
        else {
            return finalOxygen
        }

    }
    parseTemp(temperature )  { 
        let finalTemp 
        if(temperature.trim() !== ''){ 
            if(parseFloat(temperature)){ 
                finalTemp = parseFloat(temperature).toFixed(1)
            }
            if(finalTemp === undefined){ 
                return {
                    fieldName : 'Temperature' , 
                    message : 'Could not find a value for temperature. Please investigate'
                }
            }
            else { 
                return finalTemp.toString()
            }
        }
        else { 
            return {
                fieldName : 'Temperature' , 
                message : 'No temeperature value entered. You might need to investigate. '
            }
        }
    }

    parseSmoker(status ) { 
        console.log('checking smoker')
        let finalStatus
        if(status === '' || status === undefined){ 
            return { 
                fieldName : 'Smoker' , 
                message : 'Could not automatically discover if   patient was a smoker'
            }
        }
        else { 
            if(status.toLowerCase().trim().includes('no') || status.toLowerCase().trim().includes('never')){ 
                finalStatus = 'No'
            }
            else if(status.toLowerCase().trim().includes('used')){ 
                finalStatus = 'Used to'
            }
            else if(status.toLowerCase().trim().includes('yes')){ 
                finalStatus = 'Yes'
            }
            
        }
        if(finalStatus){ 
            return finalStatus
        }
        else { 
            return { 
                fieldName : 'Smoker' , 
                message : 'Could not automatically discover if patient was a smoker'
            }
        }
    
    }

    parseTestResult(label) {
        if(label !== '' || label !== undefined){ 
            if(label.toLowerCase().trim().includes('pos')){ 
                return 'Positive' ;
            }
            else if(label.toLowerCase().trim().includes('neg')){ 
                return 'Negative' ; 
            }
            else { 
                return { 
                    fieldName : 'Smoker' , 
                    message : 'Could not automatically discover if patient was a smoker'
                }
            }
        }  
        else { 
            return { 
                fieldName : 'Smoker' , 
                message : 'Could not automatically discover if patient was a smoker'
            }
        } 
    }

}