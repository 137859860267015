import Dialog from '@mui/material/Modal';
import {useState} from 'react'
import Card from '@mui/material/Card'
import DialogTitle  from '@mui/material/DialogTitle';
import Box from '@mui/material/Box'
import { PatientCreationPage1 } from './patientCreationP1';
import { PatientCreationPage2 } from './patientCreationP2';
import { PatientCreationPage3 } from './patientCreationP3';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs from 'dayjs'


export function PatientCreation(props){ 

    const [open , setOpen] = useState(props.open)
    const handleClose = () => {setOpen(false); props.setOpen(false)} 
    const [firstName , setFirstName] = useState('')
    const [lastName , setLastName] = useState('')
    const [dob , setDOB]  = useState('')
    const [dos , setDOS] = useState(dayjs())
    const [email , setEmail] = useState('') 
    const [phone , setPhone] = useState('')
    const [currentStage , setCurrentStage] = useState(0)
    const [currentTests , setCurrentTests] = useState([])
    const [location , setLocation] = useState()
    const [testType , setTestType] = useState('sti')
    const [addons , setAddons] = useState('')
    const setPatientDetails = {
        setFirstName : setFirstName , 
        setLastName : setLastName , 
        setDOB : setDOB , 
        setDOS : setDOS , 
        setEmail : setEmail , 
        setPhone : setPhone , 
        setCurrentStage : setCurrentStage , 
        setCurrentTests : setCurrentTests , 
        setLocation : setLocation , 
        setTestType : setTestType ,
        setAddons : setAddons
    }
    const patient = {
        firstName : firstName , 
        lastName : lastName , 
        email : email , 
        dos : dos , 
        dob : dob , 
        phone : phone , 
        currentStage : currentStage , 
        currentTests : currentTests , 
        location : location , 
        testType : testType , 
        addons : addons
    }
 
    return ( 
        <Dialog open = {open} onClose = {()=> handleClose()} >
            <Card sx = {{margin : '15%' , marginBottom : '25%' , marginTop : '10%' , width : '80%' , height : '80vh' , paddingRight : '5%'}}>
                <DialogTitle id="alert-dialog-title">
                    Create Patient
                </DialogTitle>
                <Box sx={{display : 'flex' }}>

                    <Box sx = {{width : '20%'}}>
                        <Tabs
                        value={currentStage} onChange ={(event , value) => {setPatientDetails.setCurrentStage(value); console.log(value); console.log(currentStage)}}
                        orientation = 'vertical'>
                            <Tab label="Patient Demographics" value = {0} />
                            <Tab label="Patient Tests" value = {1}/>
                            <Tab label="Review" value = {2}/>
                        </Tabs>
                    </Box>
                    <Box sx={{ width : '100%'}}>
                        {currentStage === 0 && <Box><PatientCreationPage1 setPatientDetails = {setPatientDetails} patient = {patient}/></Box>}
                        {currentStage === 1 && <Box><PatientCreationPage2 setPatientDetails = {setPatientDetails} patient = {patient}/></Box>}
                        {currentStage === 2 && <Box><PatientCreationPage3 setPatientDetails = {setPatientDetails} patient = {patient} closeCreate = {setOpen}/></Box>}
                    </Box>

                </Box>
  
            </Card>

        </Dialog>
    )
}

// export function TabPanel(){ 
//     <Box>
//       <Box sx={{ display: 'flex' }}>
//         <Tabs
//           value={tabIndex}
//           onChange={handleTabChange}

//           orientation="vertical"
//         >
//           <Tab label="Tab 1" />
//           <Tab label="Tab 2" />
//           <Tab label="Tab 3" />
//         </Tabs>
//         <Box sx={{ margin: 2 }}>
//           {tabIndex === 0 && (
//             <Box>
//               <Typography>The first tab</Typography>
//             </Box>
//           )}
//           {tabIndex === 1 && (
//             <Box>
//               <Typography>The second tab</Typography>
//             </Box>
//           )}
//           {tabIndex === 2 && (
//             <Box>
//               <Typography>The third tab</Typography>
//             </Box>
//           )}
//         </Box>
//       </Box>
//     </Box>
// }