// import {Grid , Button , ButtonGroup, makeStyles  } from '@mui/core'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import makeStyles from '@mui/styles/makeStyles'
import { PatientTable2 } from './appointmentData'
import{useEffect , useState} from 'react'
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AlertBadgeMenu } from "./alertBadge";

import {getAllResults} from "./functions/resultLogs"
import {areApptsLateorDueNew} from "./functions/turnaround"

import { PatientCreation } from './patientCreation'
import {DisplayUnsentResults} from './notifpop'

// A comment to test github updates
// What does this need to do? 
// Needs to be able to see patients by clinic 
const useStyles = makeStyles(theme => ({
    locationButtonsContainer: {
        [theme.breakpoints.between("xs", "sm")]: {
             padding: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
             padding: '0 25%',
         },
         [theme.breakpoints.between("md", "lg")]: {
             padding: '0 30%',
         },
         [theme.breakpoints.up("lg")]: {
             padding: '0 15%',
         },
        },
         selectionsContainer: {
            [theme.breakpoints.between("xs", "sm")]: {
                 padding: '0 0',
             },
             [theme.breakpoints.between("sm", "md")]: {
                 padding: '0 15%',
             },
             [theme.breakpoints.between("md", "lg")]: {
                 padding: '0 10%',
             },
             [theme.breakpoints.up("lg")]: {
                 padding: '0 20%',
             },
        },
        centerContainer : {
            [theme.breakpoints.between("xs", "sm")]: {
                 padding: '0 0',
             },
             [theme.breakpoints.between("sm", "md")]: {
                 padding: '0 15%',
             },
             [theme.breakpoints.between("md", "lg")]: {
                 padding: '0 10%',
             },
             [theme.breakpoints.up("lg")]: {
                padding: '0 10%',
     
             },
        },
}))

export function PatientManagerLocation1(){ 
    const classes = useStyles()
    const [location ,setLocation] = useState('noho')
    const [searchDate , setSearchDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
    const [showPatientCreation, setShowPatientCreation] = useState(false)
    const [showCanceled , setShowCanceled] = useState(false) 

    const [appLocData, setAppLocData] = useState([])
    
    const [badgePopupData, setBadgePopupData] = useState([])
    //const [badgeValue ,setBadgeValue] = useState(0)
    const [showNotifPopup, setShowNotifPopup] = useState(false)

    console.log(searchDate)

    useEffect(() => { 

        // Update the document title using the browser API
        // getAppointment()

        async function setData(){
            console.log('Appt Data has changed GRRGRRGAAT')
            console.log(appLocData)
            console.log("This is all the results from GYasis getAllResultsFunction")
            let allResultsReturned = await getAllResults(appLocData)
            console.error(allResultsReturned)
            
            //appLocdata as the first parameter for areApptsLateorDue (original)
            setBadgePopupData(await areApptsLateorDueNew(allResultsReturned))
            
            console.log("DOne")
            // Once Data has been mafde available via props. Perform all our calculations. 
        }
        appLocData ? setData() : console.log('No Data available')
    
    } , [appLocData])
    const selectLocation = async (value) => {
        // await getAppointment(value)
        setLocation(value)
        console.log(value)
        console.log('uou')
    }

    

    return(
        <Grid container>
            <Grid container style ={{marginLeft : '30%'}}>
            <Grid item xs ={2}  >
                <Button onClick={(newValue) => {setSearchDate(dayjs(searchDate).subtract(1, 'day').format('YYYY-MM-DD'))}} >
                <div style={{fontSize :"2.5em"}}>
                        ←	
                </div>
                </Button>
 
            </Grid>
            <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>    
                <DatePicker
                    label="DateTimePicker"
                    value={searchDate}
                    renderInput={(props) => <TextField {...props} />}
                    onChange={(newValue) => {
                        console.log(newValue)
                        setSearchDate(dayjs(newValue).format('YYYY-MM-DD'))
                        console.log(searchDate)
                    }} 
                />
            </LocalizationProvider>
            </Grid>
            <Grid item xs ={2} >
                <Button onClick={(newValue) => setSearchDate(dayjs(searchDate).add(1, 'day').format('YYYY-MM-DD'))}>
                <div style={{fontSize :"2.5em" }} >
                            →	
                </div>
                </Button>

            </Grid>
            </Grid>
                <Grid container className={classes.locationButtonsContainer}>
                    <Grid container className={classes.selectionsContainer} style={{paddingTop:'10px'}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <ButtonGroup>
                                <Button onClick={() => selectLocation('noho')}>Noho</Button>
                                <Button onClick={() => selectLocation('chelsea')}>Chelsea</Button>
                                <Button onClick={() => selectLocation('chinatown')}>Chinatown</Button>
                                <Button onClick={() => selectLocation('uws')}>UWS</Button>
                                <Button disabled onClick={() => selectLocation('ues')}>UES</Button>
                                <Button onClick={() => selectLocation('midtown')}>Midtown</Button>
                                <Button onClick={() => selectLocation('astoria')}>Astoria</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.selectionsContainer} style={{paddingTop:'10px', justifyContent:"space-around", direction:"row"}}>
                        <Grid item xs={10} sm={11} md={11}>
                            <ButtonGroup variant = 'contained'>
                                <Button onClick={() => setShowPatientCreation(true) }>Add Patient</Button>
                                {/* <Button>Add Tests</Button>
                                <Button>Send Receipt</Button>
                                <Button onClick={() => {setShowCanceled(!showCanceled)}}>Show Canceled</Button> */}
                            </ButtonGroup>
                        </Grid>
                        
                        <Grid item xs={1} sm={1} md={1} style={{paddingRight:'10px'}}>
                                {/* {appLocData.length !== 0 ? <AlertBadgeMenu number={badgeValue} />: ''} */}
                                <AlertBadgeMenu number={badgePopupData.length} onClick={() => setShowNotifPopup(true)}/>
                        </Grid>
                    </Grid>
            <PatientTable2 location = {location} searchDate = {searchDate} showCanceled = {showCanceled} passUp = {setAppLocData}/>
        </Grid>
            {showPatientCreation ? <PatientCreation setOpen = {setShowPatientCreation} open = {showPatientCreation} /> : null}
            {showNotifPopup ? <DisplayUnsentResults setOpen={setShowNotifPopup} open={showNotifPopup} data ={badgePopupData} originalData={appLocData} setOriginalData={setAppLocData}/> : null }
            {console.error(`${showNotifPopup}`)}
        </Grid>
    )
}

export function PatientResultsSTI(){ 
    return (
        <PatientManagerLocation1/>
    )

}