import React, {useState,useEffect,useMemo} from "react";
import {
  Grid,
  Pagination
} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import { Auth } from 'aws-amplify';
import { makeStyles } from "@mui/styles";
import { Typography } from "../../components/Wrappers/Wrappers";
import SearchIcon from '@mui/icons-material/Search';
import { API } from "aws-amplify";
import date from 'date-and-time';
import LinearProgress from '@mui/material/LinearProgress';
import BuildPatientCards from './buildPatientCards.js';
import debounce from 'lodash.debounce';
import MuiAlert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    tableOverflow: {
      overflow: 'auto'
    },
    cardsContainer: {
       [theme.breakpoints.between("xs", "sm")]: {
            padding: '0 3%',
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: '0 12%',
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: '0 10%',
        },
        [theme.breakpoints.up("lg")]: {
            padding: '0 15%',
        },
    },
    searchBar: {
        borderRadius: '6px', 
        height: '35px', 
        borderStyle: 'solid', 
        borderColor: 'royalblue', 
        padding: '4px 12px', 
        borderWidth: '1px',
        [theme.breakpoints.between("xs", "sm")]: {
              width: '90%', 
        },
        [theme.breakpoints.between("sm", "md")]: {
              width: '90%', 
        },
        [theme.breakpoints.between("md", "lg")]: {
              width: '50%', 
        },
        [theme.breakpoints.up("lg")]: {
              width: '50%', 
        },
    },
    searchBarContainer: {
        textAlign: 'center', 
        [theme.breakpoints.between("xs", "sm")]: {
            marginTop: '40px',
            marginBottom: '60px',
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginBottom: '70px',
        },
        [theme.breakpoints.between("md", "lg")]: {
            marginBottom: '70px',
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: '70px',
        },
    },
    cachedButton: {
       marginLeft: '-1px', 
       position: 'absolute', 
       backgroundColor: 'transparent', 
       borderStyle: 'none', 
       marginTop: '5px',
       transition: 'transform .6s',
       "&:hover": {
          transform: 'rotate(-70deg)'
       }
    }
}))

const MAX_APPOINTMENTS_PER_PAGE = 50;

export default function MyReports() {

  const classes = useStyles();
  const [appointmentData,setAppointmentData]= useState([]);
  const [appointmentsAll, setAppointmentsAll] = useState([]);
  const [page, setPage] = useState(1); // todo: add buttons for pagination
  const [pages, setPages] = useState(1);
  const [originalData, setOriginalData] = useState([]);
  const [cols,setCols] =useState([])  
  const [loading, setLoading] = useState(0);
  const [authenticatedUsersEmail, setAuthenticatedUsersEmail] = useState('');
  const [openAlert,setOpenAlert] = useState(true);
  let headers=["Name", "Email" , "Date", "Test Type", "Status", "Report"];
  let tableList = [] 

  const getAuthEmails = async(patientId, patientAttr, authEmail) => {

      const apiName = 'clearmdrestapi';
      const path = `/appointments?id=${patientId}`;
      const token = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
      const myInit = { 
        headers: {    
          "Content-Type":"application/json",
          CHIAUTH: token
        }
      };

      API.get(apiName, path, myInit).then((response) => {
          response.Items.forEach((appt) => {
              let datetime = appt["appointmentDatetime"];
              let splitDateTime = datetime.split('T');
              let patientDate = splitDateTime[0];
              let patientTime = splitDateTime[1].split('-')[0];
              patientTime = date.transform(patientTime, 'HH:mm:ss', 'h:mm A');
              let newLabObj = {
                  "name": patientAttr["firstName"] + " " + patientAttr['lastName'],
                  "email": authEmail,
                  "date": patientDate,
                  "time":  patientTime,
                  "testType": appt["testType"],
                  "report": patientId + "/" + appt["appointmentDatetime"] + "/" + appt["testType"] + ".pdf",
                  "status": appt["testResult"].toUpperCase()
              }
              const apptEnd = page * MAX_APPOINTMENTS_PER_PAGE - 1;
              const apptStart = apptEnd - MAX_APPOINTMENTS_PER_PAGE + 1;

              setAppointmentsAll(appointments => [...appointments, newLabObj]);
              setAppointmentData(appt => {
                const newArr = [...appt, newLabObj]
                return newArr.slice(apptStart, apptEnd);
              });
              setOriginalData(originalData => [...originalData, newLabObj]);
          });

          setLoading(0);   
      })
      .catch(error => {
        console.log("patient Test Error", error)
      });

  }

  const getAuthPatients = async(authEmail) => {

    const apiName = 'clearmdrestapi';
    const path = `/patients?email=${authEmail}`;
    const token = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    const myInit = { 
        headers: {    
          "Content-Type":"application/json",
          CHIAUTH: token
        }
    };

        API.get(apiName, path, myInit).then((response) => {
          let patients = []
          response.Items.map((value) => {
            patients.push(value)
          })
          // todo: paginate this so we dont constantly fetch appointments
          const pEnd = page * MAX_APPOINTMENTS_PER_PAGE;
          const pStart = pEnd - MAX_APPOINTMENTS_PER_PAGE;
          const patientsSlice = patients.slice(pStart, pEnd);
          setPages(Math.ceil(patients.length / MAX_APPOINTMENTS_PER_PAGE));
          if (patients.length % MAX_APPOINTMENTS_PER_PAGE !== 0) {
            setPages(pages => pages + 1);
          }
          patientsSlice.forEach((items) => {
            getAuthEmails(items["patientId"], items, authEmail)
          })

          if(patients.length === 0){
            setLoading(0)
          }

        }).catch((error) => {
          console.log("ERROR: ", error);
        })



  }

  const refreshResults = () => {
    setLoading(1)
    tableList = [];
    setAppointmentData([]);
    setOriginalData([]);  
      let columns = headers.map(c => ({
        name: c,
        selector: c
      }));       
      setCols(columns)  

      Auth.currentAuthenticatedUser().then(authData => {      
        setAuthenticatedUsersEmail(authData.attributes['email'])
        getAuthPatients(authData.attributes['email'])
      }); 
  }

  useEffect(() => {   
    setLoading(1)
    tableList = [];
      setAppointmentData([]);
      setOriginalData([]);   
      let columns = headers.map(c => ({
        name: c,
        selector: c
      }));       
      setCols(columns)  

      Auth.currentAuthenticatedUser().then(authData => {      
        setAuthenticatedUsersEmail(authData.attributes['email'])
        getAuthPatients(authData.attributes['email'])
      });  
      return () => true 
           
  }, [])  ;

const [input, setInput] = useState('');
function search() {
    if(input === ""){
        setAppointmentData(appointmentData => [...originalData]);
    }
    else{
        let newAppointmentData = [];
        originalData.forEach((item) => {
            const currentInput = input.toLowerCase();
            if(item.name.toLowerCase().includes(currentInput)){
                newAppointmentData.push(item);
            }
            else if(item.date.toLowerCase().includes(currentInput)){
                newAppointmentData.push(item);
            }
            else if(item.testType.toLowerCase().includes(currentInput)){
                newAppointmentData.push(item);
            }
        });
        setAppointmentData(appointmentData => [...newAppointmentData]);
    }
}

const changeHandler = (event) => {
  console.log(event.target.value);
  setInput(event.target.value);
}

const debounceChangeHandler = useMemo(() => 
  debounce(changeHandler,200)
,[]);

function attemptSearch(keyPressed){
   if(keyPressed === "Enter"){
      search();
   }
}

  return (
    <React.Fragment>
        <Grid container style={{padding: '2% 3%'}}>
            <Grid item xs={8}>
                <h1 style={{color: 'royalblue', fontSize: '2.5em'}}>My Reports</h1>
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>
        <Grid container className={classes.cardsContainer}>
            <Grid container spacing={2} style={{borderRadius: '10px', marginTop: '1%'}}>
              <Grid item xs={12} className={classes.searchBarContainer}>
                <input onChange={debounceChangeHandler} onKeyPress={(e) => attemptSearch(e.key)} className={classes.searchBar} placeholder="Search" />
                <SearchIcon onClick={search} style={{marginLeft: '-45px', position: 'absolute', marginTop: '6px'}} />
                <button onClick={refreshResults} className={classes.cachedButton}><CachedIcon /></button>
              </Grid>
              {loading? <LinearProgress sx={{color: 'royalblue', width: '100%'}} />: <React.Fragment>{appointmentData.length === 0? <Grid item xs={12}>
                  <h2 style={{textAlign: 'center'}}>No Results</h2>
                </Grid>:<React.Fragment>
                <Grid item xs={12}>
                  <Collapse in={openAlert}>
                    <MuiAlert action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => {setOpenAlert(false);}}><CloseIcon fontSize="inherit" /></IconButton>} severity="info">Please enable pop-ups if you want to download your results properly!</MuiAlert>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <h2>Completed</h2>
                </Grid>
                {appointmentData.map((appointment) => {
                  if(appointment.status === "POSITIVE" || appointment.status === "NEGATIVE"){
                      return(
                          <Grid item xs={12} sm={12} md={6}>
                              <BuildPatientCards appointment={appointment}/>
                          </Grid>
                      );
                  }
                })}
                <Grid item xs={12}>
                  <h2>Pending</h2>
                </Grid>
                {appointmentData.map((appointment) => {
                  if(appointment.status !== "POSITIVE" && appointment.status !== "NEGATIVE"){
                      return(
                          <Grid item xs={12} sm={12} md={6}>
                              <BuildPatientCards appointment={appointment}/>
                          </Grid>
                      );
                  }
                })}
                </React.Fragment>
              }
           </React.Fragment>}</Grid>
           <Grid item justifyContent="center" display="flex" width="100%">
            {/** todo: we probably want to trigger an update to patients and appointments on change */}
            <Pagination count={pages} page={page} onChange={(e, p) => setPage(p)} />
           </Grid>
           
        </Grid>
    </React.Fragment>
  );
}
    //   <PageTitle title="My Reports"

    //   button={
    //           <Button
    //           onClick={refreshResults} 
    //           variant="contained"
    //           size="medium"
    //           color="secondary"
    //         >
    //     Refresh
    // </Button>} />
  
    //   <Grid item xs={12}>
        
    //       {loading? <CircularProgress style={{color:'royalblue'}} fontSize="medium"/> : 
          // <MUIDataTable     
          //   title= { "Results associated with email:" + authenticatedUsersEmail }      
          //   data={appointmentData}
          //   columns={tableColumns}
          //   options={{
          //     selectableRows: "none"              
          //   }}  
                                              
//            /> 
// }

//         </Grid>
//     </>
//   );
// }
