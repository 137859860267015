import {useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import ResultsPop from './resultspop'
import { ResultLogPopup } from "./resultLog";

// const emails = ['username@gmail.com', 'user02@gmail.com', 'b0', 'b1', 'b3', 'b4', 'b5', 'b6', 'b7', 'b8', 'b9'];


export function DisplayUnsentResults(props) {
  
  const [showPopup, setShowPopup] = useState(false)
  const [index, setIndex] = useState()

  const locations = { 
    '734 broadway' : 'noho' , 
    '105 W 72nd St' : 'uws' , 
    '600 6th avenue' : 'chelsea' , 
    '31-78 steinway street' : 'astoria' , 
    '187 centre street' : 'chinatown' , 
    '1051 third avenue' : 'midtown'
  }
  const handleClose = (value) => {
    props.setOpen(false)
  };

  const handleListItemClick = (value) => {
    // Write functionality for clicked unsent appointment
    
    //props.setOpen(false)
    setIndex(value)
    setShowPopup(true)
  };

  const testLabel = (value) => {
    return value.length > 0 ? value : "No panels added"
  }

  const testLabel2 = (value) => {
    return value !== "" ? value : "No test type selected"
  }


  return (
    <div>

      <Dialog onClose={handleClose} open={props.open} sx={{}}>
      <DialogTitle sx={{fontWeight: 'bold', textAlign: 'center'}}>Unsent Results</DialogTitle>
      <List sx={{ pt: 0 }}>
        {props.data ? props.data.map((appointment) => (
          <ListItem disableGutters sx={{padding:'2px', paddingRight: '10px', paddingLeft: '10px'}}>
            <ListItemButton onClick={() => handleListItemClick(appointment.index)} key={appointment} sx={{background:`${appointment.color}`, borderRadius: '8px', textAlign: 'center'}} >
              <ListItemText sx={{width: '25%', paddingRight: '3px'}} primary={`${appointment.firstName} ${appointment.lastName}`} />
              <ListItemText sx={{width: '15%', paddingRight: '3px'}} primary={testLabel2(appointment.testType)} /> 
              <ListItemText sx={{width: '30%', paddingRight: '3px'}} primary={testLabel(appointment.results)} />
              <ListItemText sx={{width: '30%'}} primary={appointment.dueDate} />
            </ListItemButton>
          </ListItem>
          
        )): console.log("No appointments to display")}
        {console.log(props.data)}
      </List>
    </Dialog>
    {showPopup && <ResultsPop setPopClose = {setShowPopup} patient = {props.originalData[index]} setApptData = {props.setOriginalData} apptData = {props.originalData} location={ locations[`${props.originalData[index].appointmentLocation}`]}/>}
 
    </div>
  );
}