import  Autocomplete from "@mui/material/Autocomplete"
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {DesktopDatePicker}  from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputAdornment from '@mui/material/InputAdornment';
import BiotechIcon from '@mui/icons-material/Biotech';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper'
import {PatternFormat} from 'react-number-format';
import blue from "@mui/material/colors/blue";
import {locations} from './const'
const options = [
    {name : 'Richard,Enbles' , firstName : 'Richard' , lastName : 'Enbles' , dob : '12/22/2002'},
    {name : 'Fillmye,Krevis' , firstName : 'Fillmye' , lastName : 'Krevis', dob : '12/22/2002'} , 
    {name : 'Jack,Hoff' , firstName : 'Jack' , lastName : 'Hoff' , dob : '12/22/2002'} , 
    {name : 'Dick,Henballs'  , firstName : 'Dick' , lastName : 'Henbulls' , dob : '12/22/2002'}
]   
export function PatientCreationPage1(props){ 

    const handleAutoCompleteChange = (value) => {
        props.setPatientDetails.setFirstName(value.split(',')[0]); 
        props.setPatientDetails.setLastName(value.split(',')[1])
        options.find(option => option.name === value)
    } 
    
    return ( 
        <Paper sx = {{backgroundColor : blue[50] ,  padding : '1%' ,marginBottom : '2%' , maxHeight: '100%', overflow: 'auto'}}>
            <Grid container sx = {{border : '2px'}}>
            <Autocomplete
                disablePortal
                disabled
                id="combo-box-demo"
                options={options.map(patient => (patient.name))}
                onChange = {(event , value) => {handleAutoCompleteChange(value)}}
                sx={{ width: '60%' , marginLeft : '20%' , marginRight : '20%'}}
                renderInput={(params) => 
                    <Grid xs={12}>   
                        <TextField {...params} label="Search Existing Patient List" 
                            InputProps={{ ...params.InputProps, 
                            endAdornment : ( 
                                <InputAdornment position="end"> <SearchIcon /> </InputAdornment> ) , 
                            disableUnderline: true }} />
                    </Grid>
                }/>
                <Grid sx= {{marginLeft : '20%' , marginTop : '5%' , marginRight : '20%'}} xs = {12} container >
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="outlined-required"
                            label="First Name"
                            defaultValue=""
                            onChange={(event) => props.setPatientDetails.setFirstName(event.target.value)}
                            value = {props.patient.firstName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Last Name"
                            defaultValue=""
                            onChange={(event) => props.setPatientDetails.setLastName(event.target.value)}
                            value = {props.patient.lastName}
                        />
                    </Grid>
                </Grid>
                <Grid sx= {{marginLeft : '20%' , marginTop : '5%' , marginRight : '20%'}} xs = {12} container >
                    <Grid item xs={5} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            inputFormat="MM/DD/YYYY"
                            value={props.patient.dob}
                            onChange={(value) => {props.setPatientDetails.setDOB(value); console.log(value)}}
                            renderInput={(params) => <TextField  {...params} />}
                        />
                    </LocalizationProvider>  
                    </Grid>
                    <Grid item xs= {1}/>
                    <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            showToolbar = {true}
                            label="Date of Service"
                            inputFormat="MM/DD/YYYY hh:mm A"
                            value={props.patient.dos}
                            onChange={(value) => props.setPatientDetails.setDOS(value)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>  
                    </Grid>
                </Grid>
                <Grid sx= {{marginLeft : '20%' , marginTop : '5%' , marginRight : '20%'}}  container >
                    <Grid item xs = {6}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={['info@clearmdhealth.com' , 'lab@clearmdhealth.com']} 
                    onChange = {(event , value) => {props.setPatientDetails.setEmail(value); console.log(value)}}
                    freeSolo
                    sx={{ width: '100%' , paddingRight : '20%'}}
                    renderInput={(params) => 
                    <Grid xs={12}>   
                        <TextField 
                         onChange = {(event , value) => {props.setPatientDetails.setEmail(event.target.value); console.log(value + event.target.value)}}
                        {...params} label="Enter Email" 
                            InputProps={{ ...params.InputProps, 
                            endAdornment : ( 
                                <InputAdornment position="end"> <SearchIcon /> </InputAdornment> ) , 
                                disableUnderline: true }} />
                    </Grid>}
                    />
                    </Grid>
                    <Grid item xs = {6} sx={{width : '100%'}}>
                    {/* This component takes the phone number input. */}
                    <PatternFormat
                        customInput={TextField}
                        format="+# (###)-###-####"
                        mask=""
                        allowEmptyFormatting
                        placeholder="(123) 456-7890"
                        value={props.patient.phone}
                        onValueChange={({ formattedValue }) => {
                            console.log('Value')
                            props.setPatientDetails.setPhone(formattedValue);
                            console.log(props.patient.phone)

                    }}
                    />
                    </Grid>
                    
                </Grid>
                <Grid sx= {{marginLeft : '20%' , marginTop : '5%' , marginRight : '20%'}}  container >
                    <Grid item xs = {12}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={Object.keys(locations).map(location => location )}
                        sx={{ width: '60%' , marginLeft : '20%' , marginRight : '20%'}} 
                        onChange = {(event , value) => {console.log('Value' , value); props.setPatientDetails.setLocation(locations[value])}}
                        renderInput={(params) => 
                        <Grid xs={12}>   
                            <TextField {...params} label="Pick a Test Location" 
                                InputProps={{ ...params.InputProps, 
                                startAdornment : (
                                    <InputAdornment position="start"> <BiotechIcon/> </InputAdornment> ) , 
                                disableUnderline: true }} />
                        </Grid>}
                    />    
                    </Grid>
                    
                </Grid>
            </Grid>

        </Paper>

    )
}