import React from "react";
import { Auth } from 'aws-amplify';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, userGroup: action.user.userGroup, email: action.user.email };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, userGroup: "", email: "" };
    case "LOGIN_FAILURE":
        return { ...state, isAuthenticated: false };    
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

/**
 * 
 * @param {String} username - username of the user
 * @param {String} email - email of the user
* @param {String} userGroup - main user group of the user
 */
export function authenticated(username, email, userGroup){
  return {type: 'LOGIN_SUCCESS', user: { username, email, userGroup }}
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut,signUp,confirmSignUp, resendUserVerificationCode,
  setNewPassword, submitFinalPassword};

// ###########################################################

async function loginUser(username, password, navigate, dispatch) {
    console.log("Logging in user");
    username = username.toLowerCase();
    try{
        let status = await Auth.signIn(username, password);
        console.log("Status:", status);
        let userGroup = "";
        let userGroups = [];
        let email = "";
        try{
          const response = await Auth.currentAuthenticatedUser();
          userGroups = response.signInUserSession.accessToken.payload['cognito:groups']
          userGroup = userGroups[0];
          email = response.attributes.email;
        } catch(err){
          userGroup = "Patient";
          userGroups = [userGroup];
        }
        localStorage.setItem("id_token", status.signInUserSession.idToken.jwtToken);
        dispatch(authenticated(username, email, userGroup ))
    
        return {STATUS: "success"};

    } catch(err){
      console.log(err);
      return {STATUS: err};
    } 

}

async function submitFinalPassword(username, password, code){
    try{
        username = username.toLowerCase();
        let status = await Auth.forgotPasswordSubmit(username, code, password);
        return {STATUS: "success"};
    } catch(error){
        return {STATUS: error};
    }
}

async function setNewPassword(username){
    try{
        username = username.toLowerCase();
        let status = await Auth.forgotPassword(username);
        return {STATUS: "success"};
    } catch(error){
        return {STATUS: error};
    }
}

async function resendUserVerificationCode(username){
    try{
        username = username.toLowerCase();
        await Auth.resendSignUp(username);
        return {STATUS: "success"};
    } catch(error){
        return {STATUS: error};
    }
}

async function signUp(username, password) {
    try {
          username = username.toLowerCase();
          await Auth.signUp({
            username,
            password,
          });
          return {STATUS: "success"};

    } catch (error) {
        return {STATUS: error};
    } 
}

async function confirmSignUp(username, confirmationCode) {
    try {
        username = username.toLowerCase();
        await Auth.confirmSignUp(username, confirmationCode);
        return {STATUS: "success"};
    } catch(error){
          return {STATUS: error};
    }

}

  // if (!!login && !!password) {
  //   setTimeout(() => {
  //     localStorage.setItem('id_token', user.token)
  //     setError(null)
  //     setIsLoading(false)
  //     dispatch({ type: 'LOGIN_SUCCESS' })

  //     history.push('/app/dashboard')
  //   }, 2000);
  // } else {
  //   dispatch({ type: "LOGIN_FAILURE" });
  //   setError(true);
  //   setIsLoading(false);
  // }
//}

function signOut(dispatch, navigate) {
  Auth.signOut()
    .then(() => console.log("User signed out"))
    .catch(err => console.log("Signout error: ", err))
    .finally(() => {
      localStorage.removeItem("id_token");
      dispatch({ type: "SIGN_OUT_SUCCESS" });
      navigate("/login");
    });
}

export async function checkUserSession(dispatch, navigate) {
  if (Auth.user) return Auth.user;
  let authData;
  try {
    authData = await Auth.currentAuthenticatedUser();
    const session = await Auth.currentSession();
    localStorage.setItem('id_token', session.getIdToken().getJwtToken());
  }catch(e) {
    localStorage.removeItem("id_token");
    dispatch({ type: "SIGN_OUT_SUCCESS" });
    navigate("/login");
    throw e
  }
  let cognitogroups = authData.signInUserSession.accessToken.payload['cognito:groups'];
  let email = authData.attributes['email'];
  let userGroup;
  if (cognitogroups) {
    [userGroup] = cognitogroups;
  } else {
    userGroup = "Patient"
    cognitogroups = [userGroup]
  }
  dispatch(authenticated(authData.username, email, userGroup));
  return authData;
}