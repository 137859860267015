// import axios from 'axios'
import axios from 'axios-https-proxy-fix'
import {AttributeValue as attr}  from 'dynamodb-data-types'
import crypto from 'crypto-browserify'
import './typedef'
import { resultsMap , sendOutTests } from './const'
import { getAllReturnedIds } from './functions/turnaround'
const locations = { 
    noho : '734 broadway' , 
    uws : '105 W 72nd St' , 
    ues : '' , 
    chelsea : '600 6th avenue' , 
    astoria : '31-78 steinway street' , 
    chinatown : '187 centre street' , 
    midtown : '1051 third avenue'
}
let testPermissions = { 
    Doctors : {canSend : ['all']} , 
    MedicalStaff : {canSend : ['Trichomoniasis (PCR)' , 'Chlamydia (PCR)' , 'Gonorrhea (PCR)'] } ,
    Compliance : {canSend : ['Trichomoniasis (PCR)' , 'Chlamydia (PCR)' , 'Gonorrhea (PCR)']}
}
  
const printerIps = {
    "734 Broadway" : "https://98.13.209.20:49300" , 
    "2007 Broadway" : "https://66.65.102.185:8077" , 
    "1051 Third Ave"  : "https://72.227.183.24:8077"  ,
    "600 6th Avenue" : "https://74.66.18.129:8077" , 
    "187 Centre Street" : "https://24.161.48.211:8077"
}

// let data = { 
//     firstName : "Gyasi" , 
//     lastName : 'Gittens' , 
//     dob : '07/29/1998' , 
//     phone : '6469124350' , 
//     email : 'ggittens@clearmdhealth.com' , 
//     tests : [{type : 'chl' , result : 'positive' , level : '16'}, {type : 'unprotected sex' , result : 'positive' , level : '16'}] , 
//     approvalDate : 'today' , 
//     collected : '070929' , 
//     reported : 'today' , 
//     patientId : '446086207122779', 
//     appointmentDatetime : '2022-09-01T08:00:00-0400' , 
//     testType : 'sti' , 
// }

export const types = { 

}
/** 
 * @returns {AppointmentInfo[]}
 * @param {String} location
 * @async
*/
export async function getAppointment(location , searchDate , getCanceled){ 
    console.log('Pre request')
    // User passed in either a location or a search date or both
    try { 
        let appts = []
        console.log('I am whhatever you say I am')
        const queryStr = `&appointmentLocation=${locations[location]}`
        const todaysAppointments = (`https://kbq3anyani.execute-api.us-east-1.amazonaws.com/dev/appointments?appointmentDate=${searchDate}${queryStr}`);
        console.log(todaysAppointments)
        let appointmentResponse = (await axios.get(todaysAppointments)).data
        console.log(appointmentResponse)
        console.time('Timer.Pushing all appointments together')
        // Pushes all the apppointments together into one larger appointments array 
        for (const appt of appointmentResponse){ 
            let patient = await getPatient(appt.patientId)
                console.log(patient)
                console.log(appt)

            appts.push({...appt, ...patient})
        }
        console.timeEnd('Timer.Pushing all appointments together')
        // Filter out canceled and fly appts as they arent really used for results at all. 
        appts = appts.filter(appt => { 
            // if(appt.testType === 'flu'){ 
            //     return false
            // }
            if(appt.canceled === true){ 
                // If show canceled appointment button is true this will show all appointment even canceleed oned.
                return getCanceled
            }
            else { 
                return true
            }
        })
        // Need to add in the results object if it has not been done.
        // If it has not been done the results object needs to be 
        appts.forEach((appt , index) => {
            // console.log(appt) 
            // console.log(Object.keys(appt))
            //console.log(Object.values(appt))
            console.log(appt)
            console.log(appt.results)
            // console.log(appt.results)
            // console.log(appt['results'])
            // console.log(appt)
            if(appt.results && appt.results.length>0){
                console.log(appt)
                try { 
                    console.log('Me is me')
                    appts[index].results.forEach((test , index2) => { 
                        console.log('I am me')
                        console.log(test)
                        console.log(attr.unwrap(test.M))
                        
                        appts[index].results[index2] = attr.unwrap(test.M)   
                    })
                }
                catch(error){ 
                    console.log(error)
                    console.log('What an error')
                }
 
            }
            else { 
                if(appt.addons && appt.addons.length > 0 && appt.testType === 'sti'){ 
                    console.log(appt.testType)
                    let results = new Set()
                    let resultsArr = []
                    console.log(appt.addons)
                    appt.addons.forEach(addon => { 
                        let testMap = resultsMap[addon.S]
                        console.log(`Patient ${appt.firstName} ${appt.lastName} had this addon ${addon.S}`)
                        testMap.forEach(testType => { 
                            results.add(testType)
                        })
                    })
                    console.log(results)
                    results.forEach(res => {
                        // An object representing all the custom options the test has. 
                        let testObj  = {testType : '' , res : ''}
                        testObj.testType = res 
                        if(res.includes('Collection')){
                            testObj.collectionType = ''
                        }
                        let sendOut = sendOutTests.find(sendOutAllowed => {
                            if(sendOutAllowed.testType === res){ 
                                return true
                            }
                        })
                        if(sendOut) { 
                            // If the test is a part of the sendout list, then set the lab location to the default value of sendout. 
                            testObj.labLocation = sendOut.defaultValue
                        }
                        resultsArr.push(testObj)
                    })
                    console.log(appts[index].results)
                    console.log(appts[index])
                    appts[index].results = resultsArr
                }
                else if(appt.addons && appt.addons.length  > 0 && appt.testType === 'respiratory'){ 
                    console.log(appt.testType)
                    let results = new Set()
                    let resultsArr = []
                    console.log(appt.addons)
                    resultsArr = MapGenericAddons(appt)
                    console.log(resultsArr)
                    console.log(results)
                    console.log(appts[index].results)
                    appts[index].results = resultsArr
                }
                else if(appt.addons && appt.addons.length > 0 && appt.testType === "women's health"){ 
                    let results = new Set()
                    let resultsArr = []
                    resultsArr = mapWomensHealth(appt)
                    appts[index].results = resultsArr
                }
                else { 
                    // Map Remaining Test Types if they are not already done
                   let resultsMap =  MapTests(appt)
                   let mapAddons = mapAddonTests(appt)
                   console.log(mapAddons)
                   resultsMap = resultsMap.concat(mapAddons)
                   console.log(resultsMap)
                   appts[index].results = resultsMap
                }
            }
        })
        console.log(appts)
        //console.log(getAllReturnedIds(test))
        return appts

    }
    catch(e){ 
        console.log('Error: ' + e)
        return []
    }


    
}
// const apiUrl = process.env.REACT_APP_ENV === 'prod' 
// ? "https://5hh32psqcj.execute-api.us-east-1.amazonaws.com/prod" 
// : "https://kbq3anyani.execute-api.us-east-1.amazonaws.com/dev";

export async function getPatient(id){ 
    let patientInfo = {}
    try { 
        // const config = {
        //     headers: {
        //       "Access-Control-Allow-Origin": "*",
        //       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        //     }
        //   };
        patientInfo = await axios.get(`https://kbq3anyani.execute-api.us-east-1.amazonaws.com/dev/patients?patientIds=[${id}]`); 
        console.log(patientInfo.data)
        return patientInfo.data[0]
    }
    catch(e){ 
        console.log(e)
        return patientInfo
    }

}

export async function patchAppt(patientId , appointmentDatetime, testInfo){
    console.log(testInfo)
    try { 
       let patientInfo = await axios.patch(`https://kbq3anyani.execute-api.us-east-1.amazonaws.com/dev/appointments/${patientId}/${appointmentDatetime}`, 
    {results : testInfo.results})
       console.log(patientInfo)
    }
    catch(error){ 
        console.log(error)
    }

}

export async function getAppt(patientId , appointmentDatetime, testType){ 
    let info  = {}
        try { 
            let todaysAppointments = axios.get(`https://kbq3anyani.execute-api.us-east-1.amazonaws.com/dev/patient/${patientId}/${appointmentDatetime}`)
            console.log(todaysAppointments)
            todaysAppointments = await todaysAppointments
            console.log(todaysAppointments) 
            // info = todaysAppointments.data.find(appt => {return appt.testType == testType})
            console.log('INFO : ' + info)
        }
        catch(error){
        
            console.log(error)
        }


        
}
/**
 * 
 * @param {*} patientId 
 * @param {*} appointmentDatetime 
 * @param {AppointmentInfo} patient 
 * @description Sends results to user using EC2 instance. 
 * @returns 
 */
export async function sendResults(patientId , appointmentDatetime, patient){ 
    let patientInfo = {
        firstName : patient.firstName , 
        lastName : patient.lastName , 
        dob : patient.dob , 
        phone : patient.phone , 
        email : patient.email , 
        tests : patient.results , 
        approvalDate : new Date() , 
        collected : patient.appointmentDatetime , 
        reported : new Date() , 
        patientId : patient.patientId, 
        appointmentDatetime : patient.appointmentDatetime , 
        testType : patient.testType , 
        acuityId : patient.acuityId , 
        location : patient.appointmentLocation , 
        followUpCare : patient.followUpCare === 'yes' ? true : false, 
        externalId : patient.externalId, 
        externalPatientId : patient.externalPatientId,
        appointmentSource : patient.appointmentSource , 
    }
    try { 
        patient.appointmentDatetime = appointmentDatetime
        patient.patientId = patientId
        patientInfo = await axios.post(`https://api.results.clearmdhealth.com`, patientInfo);       
        console.log(patientInfo)
        return patientInfo.data[0]
    }
    catch(e){ 
        console.log(e)
        return patientInfo
    }
}

export function neccessaryKeys(patientInfo){ 
const required_keys = [
        'firstName' ,'lastName' ,'dob','phone', 'email' , 
        'results' , 'patientId' , 'appointmentDatetime' , 'testType' ]
        if (required_keys.some(key => patientInfo[key] === undefined || patientInfo[key]==='')) {
            let missingKeys = required_keys.filter(
              key => patientInfo[key] === undefined || patientInfo[key]===''
            );
            console.log(missingKeys)
            let missingMessage = 'The patient is missing or has invalid enties in the following required information ' + missingKeys.toString() 
                const errorMessage = 
                `The results for a patient were not submitted with the required information. We think the results are for the patient: \n
                ${patientInfo.firstName} , ${patientInfo.lastName}\n
                They had a ${patientInfo.testType} test \n ${missingMessage}`
                console.log(errorMessage)
                return errorMessage
        }
        else { 
            return 'complete'
        }
}
/** 

* @param {AppointmentInfo} patient 
* @returns 
*/
export function isSendingDisabled(patient ,userGroup){ 
    console.log(userGroup)
    let posResults = patient.results.filter(res => (res.res === 'Detected' || res.res.includes('pos')))
    console.log(posResults)
    if(posResults===undefined || posResults.length === 0){ 
        console.log('Does not include positive result')
        return 'complete'
        // If there are no positive results you can send
    }
    else if((posResults!==undefined && posResults.length>0) && (userGroup.includes('Doctor') || userGroup.includes('Compliance'))){ 
        console.log('Includes positive result but user is a doctor')
        return 'complete'

        // If there are some positive results but the user group is a doctor 
    }
    else if((posResults!==undefined && posResults.length>0) && userGroup.includes('MedicalStaff')){ 
        let complete = ''
        for(const result of posResults){ 
            console.log(result)
            if(testPermissions.MedicalStaff.canSend.includes(result.testType)){
                console.log('Can send this test' + result)
                complete = 'complete'
            }
            else { 
                console.log('The patient result included an uncovered positive result')
               complete = 'The patient result included an uncovered positive result'
            }
        }
        return complete
    }
    else { 
        return 'The patient result included a positive result.'
        // There are some positive results and not aparat of an authorized user group 
    }
}

export function MapTests(patient){ 
    console.log('Mapping tests')
    if(patient.testType.includes('antigen')){
        console.log('Patient includes an antigen test')
        return [{testType : 'Antigen (Rapid)' , res : ''}]
    }
    else if(patient.testType.includes('pcr')){
        console.log('Patient includes a pcr test')
        return [{testType : 'SARS - CoV-2 (COVID-19)' , res : ''}]
    }
    else if(patient.testType.includes('pcr') && patient.testType.includes('antigen')){
        console.log('Patient includes a pcr test')
        return [{testType : 'SARS - CoV-2 (COVID-19)' , res : ''}, {testType : 'Antigen (Rapid)' , res : 'Antigen (Rapid)'}]
    }
    else if(patient.testType.includes('rsv')){ 
        console.log('Patient includes an RSV')
        return [{testType : 'Respiratory Syncytial Virus (PCR)' , res : ''}]
    }
    else if(patient.testType.includes('flu')){ 
        console.log('Patient includes a flu test')
        return [{testType : 'Influenza A' , res : ''}, {testType : 'Influenza B' , res : ''}]
    }
    else if(patient.testType.includes('strep') || patient.testType === 'strep1hr'){ 
        console.log('Patient includes a strep test')
        return [{testType : 'Strep A (PCR)' , res : ''}]
    }
    else if(patient.testType.includes('uti')){
        console.log('Patient had a uti test')
        return [{testType : 'Urinary Tract Infection (Rapid)' , res : ''}]
    }
    else if(patient.testType.includes('mvp')){
        console.log('Patient had a uti test')
        return [{testType : 'Candida (PCR)' , res : ''} ,  {testType : 'Bacterial Vaginosis (PCR)' , res : '' } ,{testType : 'Trichomoniasis (PCR)' , res : ''}]
    }
    else { 
        return []
    }
}
export function MapGenericAddons(patient){ 
    let currentTests = []
    console.log('Mapping tests')
 
    patient.addons.forEach(addon => { 
        console.log(addon)
        if(addon.S.toString().includes('antigen')){
            console.log('Patient includes an antigen test')
            currentTests.push({testType : 'Antigen (Rapid)\nAnterior Nasal Swab' , res : ''})
        }
        if(addon.S.toString().includes('pcr')){
            console.log('Patient includes a pcr test')
            currentTests.push({testType : 'SARS - CoV-2 (COVID-19)' , res : ''})
        }
        if(addon.S.includes('rsv')){ 
            console.log('Patient includes an RSV')
           currentTests.push({testType : 'Respiratory Syncytial Virus (PCR)' , res : ''})
        }
        if(addon.S.includes('flu')){ 
            console.log('Patient includes a flu test')
            currentTests.push({testType : 'Influenza A' , res : ''}, {testType : 'Influenza B' , res : ''})
        }
        if(addon.S.includes('strep') || addon.S === 'strep1hr'){ 
            console.log('Patient includes a strep test')
            currentTests.push({testType : 'Strep A (PCR)' , res : ''})
        }
        if(addon.S.includes('uti')){
            console.log('Patient had a uti test')
            currentTests.push({testType : 'Urinary Tract Infection (Rapid)' , res : ''})
        }
        if(addon.S.includes('mvp')){
            console.log('Patient had a uti test')
            currentTests.push({testType : 'Candida (PCR)' , res : ''} ,  {testType : 'Bacterial Vaginosis (PCR)' , res : '' } ,{testType : 'Trichomoniasis (PCR)' , res : ''})
        }
    })

    console.log(currentTests)
    return currentTests
}
export function mapAddonTests(patient){ 
    let resultsArray = []
    console.log('Mapping Addon')
    patient.addons && patient.addons.forEach(addon => { 
        let tests = resultsMap[addon.S || addon] || []
        tests && tests.length > 0 && tests.forEach(test => { 
            resultsArray = resultsArray.concat({testType : test , res : ''})
        })

    })
    return resultsArray
}

export async function previewPDF(patient){ 
    let patientInfo = {
        firstName : patient.firstName , 
        lastName : patient.lastName , 
        dob : patient.dob , 
        phone : patient.phone , 
        email : patient.email , 
        tests : patient.results , 
        approvalDate : new Date() , 
        collected : patient.appointmentDatetime , 
        reported : new Date() , 
        patientId : patient.patientId, 
        appointmentDatetime : patient.appointmentDatetime , 
        testType : patient.testType , 
        acuityId : patient.acuityId , 
        location : patient.appointmentLocation
    }
    try { 
        // patient.appointmentDatetime = appointmentDatetime
        // patient.patientId = patientId
        const { data } = await axios.post(`https://api.results.clearmdhealth.com/preview`, patientInfo , {
            responseType : 'arraybuffer'
        })
        //const { data, status } = await axios.post(`https://e05d-152-179-41-242.ngrok.io/preview`, patientInfo, {
        // if (status !== 200) {
        //     throw new Error(`Failed to fetch preview: ${status}`)
        // }
        const encodedData = Buffer.from(data).toString('base64')
        return `data:application/pdf;base64, ${encodeURI(encodedData)}`
    }
    catch(e){ 
        console.log(e)
        throw e
    }
}
//TODO : MOVE TO BACKEND
export function hashPatientInfo(patientNameEmailString){ 

        return parseInt(
          crypto
            .createHash("md5")
            .update(patientNameEmailString)
            .digest("hex")
            .substring(0, 13),
          16
        )
          .toString()
          .substring(0, 15);
      
}

export async function updatePatientTable(patientInfo){
    let patient = patientInfo
    patient.id = patientInfo.patientId ||  hashPatientInfo(patientInfo.firstName + patientInfo.lastName + patientInfo.email) 
    console.log(patient)
    patient.dos = new Date(patient.dos)
    patient.dob = new Date(patient.dob)
    await axios.post(`http://kbq3anyani.execute-api.us-east-1.amazonaws.com/dev/patients/${patient.id}` , {patient})

}   

export async function createPatient(patientInfo){ 
    console.log('Creating Patient')
    patientInfo.appointmentDatetime = new Date(patientInfo.dos).getTime()
    patientInfo.appointmentLocation = patientInfo.location
    patientInfo.addons = patientInfo.currentTests
    patientInfo.appointmentSource = 'portal'

    await axios.post(`https://api.clearmdhealth.com/v1/appointments/create/appointmentdb` , patientInfo , 
    {        
        headers: {
        "Content-Type": "application/json" , 
        'x-api-key' : 'ca2269e0cd9ca81b4e60ba3e43de689f'
        }
    })
}
export async function cancelAppointment(patient){ 
    await axios.post(`https://api.clearmdhealth.com/v1/appointments/patchdb` , {canceled : true , patientId : patient.patientId , appointmentDatetime : patient.appointmentDatetime} , 
    {        
        headers: {
        "Content-Type": "application/json" , 
        'x-api-key' : 'ca2269e0cd9ca81b4e60ba3e43de689f'
        }
    })
 
}

export async function getResultsLog(patientId){ 
    let results = await axios.get(`https://api.results.clearmdhealth.com/appointments/getResults?patientId=${patientId}`)
    if(results.data && results.data.length > 0){ 
        console.log(results.data)
        return results.data 
    }
    else { 
        return []
    }

}

export async function printLabel(patient , labels , location){
    console.log(new Date(patient.appointmentDatetime).toLocaleDateString())
    let data = { 
        firstName : patient.firstName , 
        lastName : patient.lastName , 
        dob : patient.dob,
        date : new Date(patient.appointmentDatetime).toLocaleDateString(),
        time : new Date(patient.appointmentDatetime).toLocaleTimeString(),
        testType : patient.testType,
        location : location,
        labels : labels
    }
    let response = await axios.post(`https://api.clearmdhealth.com/v1/appointments/labels/print` , data ,     
        {        
        headers: {
        "Content-Type": "application/json" , 
        'x-api-key' : 'ca2269e0cd9ca81b4e60ba3e43de689f'
        }}
    )
    if(response.status === 200){console.log('Good')}
}
export function mapWomensHealth(appt){ 
    let currentTests = []
    console.log('Mapping womens health')
    appt.addons.forEach(addon => { 
        console.log(addon)
        if(addon.S.toString().includes('pregnancy test')){
            console.log('Patient includes an pregnancy test')
            currentTests.push({testType : 'Urine beta-hCG (Pregnancy Test)' , res : ''})
        }
    })
    return currentTests
}

export function isTestWithinLimits(tests){ 

}