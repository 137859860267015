import React, {useState ,useEffect} from 'react';
import { makeStyles } from "@mui/styles";
import {Grid,Button,LinearProgress} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.between("xs", "sm")]: {
            margin: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
            margin: '0 6%',
         },
         [theme.breakpoints.between("md", "lg")]: {
              margin: '0 10%',
         },
         [theme.breakpoints.up("lg")]: {
             margin: '0 15%',
         },
    },
}))

const apiUrl = process.env.REACT_APP_ENV === 'prod'
? "https://5hh32psqcj.execute-api.us-east-1.amazonaws.com/prod" 
: "https://bjb0pwkls9.execute-api.us-east-1.amazonaws.com/dev";

export default function SendReports(props){
    const classes = useStyles();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(0);
    const [location,setLocation] = useState('');
    const [generalError,setGeneralError] = useState(0);
    const [generalErrorMessage,setGeneralErrorMessage] = useState('');
    const [appointmentList,setAppointmentList] = useState([]);

    const getAppointmentsToday = async () => {
        let errors = 0;
        let response;
        try{
            response = await axios.get(`${apiUrl}/appointment?date=2022-05-09`);
        }
        catch(e){
            console.log(e);
            errors+=1;
        }
        finally{
            if(errors){
                console.log("Some error occured");
            }
            else{
                setAppointmentList(response.data.message);
            }
        }
        console.log(response);
    }

    useEffect(async () => {
        setLoading(1);
        let errors = 0;
        // const controller = new AbortController();
        // async function fetchAppointments() {
        //   try{
        //     await getAppointmentsToday(controller);
        //   }
        //   catch(e){
        //       errors+=1;
        //   }
        //   finally{
        //       if(errors){
        //           setGeneralError(1);
        //           setGeneralErrorMessage('Something Went Wrong');
        //       }
        //       setLoading(0);
        //   }
        // }
        // fetchAppointments();
        // return () => controller.abort();
        try{
          await getAppointmentsToday();
        }
        catch(e){
            errors+=1;
        }
        finally{
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage('Something Went Wrong');
            }
            setLoading(0);
        }
    },[]);

    const goBack = () => {
      navigate(-1);
    }

    const selectLocation = (location) => {
      setLocation(location);
    }

    const renderCalendar = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {appointmentList[location].map((appointment) => {
                        return <tr>
                            <Button>{appointment.appointmentTime.S.split('-')[0]}</Button>
                        </tr>
                    })}
                </tbody>
            </table>
        )
    }

    return(
        <Grid container>
            <Grid container xs={12}>
                <Grid item xs={12}>
                    <Button onClick={() => goBack()} color="primary" style={{backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                        Back
                    </Button>
                </Grid>
                <Grid container className={classes.container}>
                    {loading ? 
                        <Grid item xs={12}>
                            <LinearProgress/>
                        </Grid> :
                        <Grid>
                            <Button onClick={() => selectLocation("734 broadway")}>Noho</Button>
                            <Button onClick={() => selectLocation("600 6th avenue")}>Chelsea</Button>
                            <Button onClick={() => selectLocation("2007 broadway")}>UWS</Button>
                            <Button onClick={() => selectLocation("187 centre street")}>Chinatown</Button>
                            <Button onClick={() => selectLocation("31-78 steinway street")}>Astoria</Button>
                            <Button onClick={() => selectLocation("1157 Madison Ave")}>UES</Button>
                            <Button onClick={() => selectLocation("1051 third avenue")}>Midtown</Button>
                            {!location ? <Grid>Choose a Location</Grid> : renderCalendar()}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}