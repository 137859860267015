import { Drawer } from "@mui/material"
import { port } from "../constants"
import { useEffect } from 'react'
export function HistoryComponent(props){ 
    // const [hist , setHist] : any = useState([]) 
    useEffect(() => {
        queryData()
        .then((response) => { 
            console.log(JSON.stringify(response))
        })
        .catch(error => { 
            console.log(error)
        })
      },[]);
    const queryData = (async() => { 
        fetch(port + ':5000/history', {
            headers: {
                "Accept": "application/pdf",
                "Content-Type": "application/json"
            },
            method : 'GET' , 
            
        }) 
    })
    return (
        <Drawer anchor='left' open = {props.toggle} onClose={() => props.setToggle(false)}>
            <div>
                {}
            </div>
        
        </Drawer>
    )
}