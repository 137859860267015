import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Autocomplete from '@mui/material/Autocomplete'
import BiotechIcon from '@mui/icons-material/Biotech';
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { TestTypes , addons } from './const';
export function PatientCreationPage2(props){
 
    const valueSelected = (value) => { 
        console.log(props.patient.currentTests)
        let testSet = new Set(props.patient.currentTests)
        value ? testSet.add(value) : console.log('There was a null value')
        console.log(testSet)
        props.setPatientDetails.setCurrentTests([...testSet])
    }
    const  testTypeChange = (value) => { 
        console.log(value)
        props.setPatientDetails.setTestType(value)
        console.log(props.patient.testType)
    }
    const handleDelete = (index) => { 
        console.log(index)
       let currentTests = props.patient.currentTests
       currentTests.splice(index , 1)
       console.log(currentTests)
       props.setPatientDetails.setCurrentTests([...currentTests])
    }
    return ( 
        <Grid container xs={12} >
            <Grid item xs={6}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={TestTypes}
                sx={{ width: '60%' , marginLeft : '20%' , marginRight : '20%'}} 
                onChange = {(event , value) => {console.log('Value' , value); testTypeChange(value)}}
                renderInput={(params) => 
                <Grid xs={12}>   
                    <TextField {...params} label="Search For a Test" 
                        InputProps={{ ...params.InputProps, 
                        startAdornment : ( 
                            <InputAdornment position="start"> <BiotechIcon/> </InputAdornment> ) , 
                        disableUnderline: true }} />
                </Grid>}
            />
            </Grid>
            <Grid item xs={6}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={addons[props.patient.testType] ? addons[props.patient.testType] : 'sti'  }
                sx={{ width: '60%' , marginLeft : '20%' , marginRight : '20%'}} 
                onChange = {(event , value) => {console.log('Value' , value); valueSelected(value)}}
                renderInput={(params) => 
                <Grid xs={12}>   
                    <TextField {...params} label="Search For a Test" 
                        InputProps={{ ...params.InputProps, 
                        startAdornment : ( 
                            <InputAdornment position="start"> <BiotechIcon/> </InputAdornment> ) , 
                        disableUnderline: true }} />
                </Grid>}
            />
            </Grid>

            <Box sx = {{width : '100%' , height: '40vh',  display : 'flex'}}>
                <Paper elevation={3} sx = {{m: 1, ml : '5%' ,  width: '90%', height: '90%', p : '3%'}}>
                    {props.patient.currentTests.map((test , index) => (
                            <Chip key = {index} label = {test} sx = {{ml : '2%'}} onDelete={(event) => {handleDelete(index); console.log(index)}}/>
           
                    ))}
                </Paper>
            </Box>
        </Grid>
    )
}