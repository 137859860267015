import { useState , useEffect} from "react"
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    lightblue: {
      main: '#EEE1FF',
    },
  },
});

export function AlertBadgeMenu(props) {
  
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
      console.log("HEYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY")
    }
  }; 

  return (
    <ThemeProvider theme={theme}>
      <IconButton aria-label={props.number} onClick={handleClick}>
        <Badge badgeContent={props.number} color="lightblue">
          <NotificationsIcon color="primary" />
        </Badge>
      </IconButton>
    </ThemeProvider>

  );
}

