import React, { useState,useEffect } from "react";
import {
  Grid,
  Button,
  Chip
} from "@mui/material";

import { API } from "aws-amplify";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import  { Storage,Auth } from 'aws-amplify';
import MUIDataTable from "mui-datatables";
// styles
import useStyles from "./styles";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";


const backgroundColorState = {
  Negative: "#9fee9f",
  Positive: "red",
  Inconclusive: "yellow" 
};
const foregroundColor= {
  Negative: "black",
  Positive: "white",
  Inconclusive: "black" 
};

export default function Dashboard(props) {
  var classes = useStyles();    
  var emailAddress = ""
  
  const [labOrderList,setLabOrderList]= useState([{}])
  const [cols,setCols] =useState([])  
  const [authUserEmail, setAuthUserEmail] = useState(null);
  //const [groupObj, setGroupObj] = useState({id:'',name:''});
  const [groupId, setGroupId] = useState('');
  const [groupNameList, setGroupNameList] = useState([]);

  useEffect(() => {  

      Auth.currentAuthenticatedUser().then(authData => {      
        console.log('Email set----------3402304',authData.attributes['email']);
        setAuthUserEmail(authData.attributes['email'])
        fetchLabOrder(authData.attributes['email'])      
      });            
    return () => true
  }, [])  
  let sessionToken;
  useEffect(() => {  
    console.log('Trying to Popuate the data')
    if (groupId!=='')
    {
     // populateLabOrderTable(groupId)
    }    
    Auth.currentAuthenticatedUser(user => {
      user.getSession((err, session) => {
        if(err) {
          throw new Error(err);
        }

        sessionToken = session.getIdToken();

    })});
    console.log("TOKENS: ", sessionToken)
  },
  [groupId])

  const downloadReportFile= async(value) => {

    const pdfURL = await getPDFUrl(value)    
    window.open(pdfURL, '_blank');
    return true
  } 

 
  const  getPDFUrl = async key => {
    console.log(key)
    return Storage.get(key,{level:'public',download:false, contentType:"application/pdf"})
    .then(res => {       
       return (res)})
    .catch(err => console.log(err))
}

  // Get Group Name
    const getGroupNames= ""//async(emailAddess) =>  await DataStore.query(GroupInfo,Predicates.ALL)

  // Send group Id
  const fetchLabOrdersByGroup = ""//async(groupId) => (await DataStore.query(LabOrder,Predicates.ALL)).filter(lo=> lo.GroupInfo.id===groupId)

  const getEmailAddress= async()=> {

    let emailAdd=''
    if (authUserEmail===''|| authUserEmail === undefined|| authUserEmail===null )
    {
      const authUser= await Auth.currentAuthenticatedUser()
      setAuthUserEmail(authUser.attributes['email'])  
      emailAdd= authUser.attributes['email'] 
    }
    else
        emailAdd= authUserEmail
    return emailAdd;
  }
        
  // authUserEmail??Auth.currentAuthenticatedUser().then(authData => {      
  //         console.log('Email set',authData.attributes['email']);
  //         setAuthUserEmail(authData.attributes['email'])      
  //         });    
  
  // V1.0
  const fetchLabOrders = async(groupId) => {
    
    let labOrders   = []
    if (groupId==null || groupId==='')
    {
      var emailId= await getEmailAddress() 
      const grpObj= 0

      if (grpObj.length>0)
      { 
        var grp= grpObj.filter(gp =>gp.UserAssociated.find(o=>o===emailId))
        if (grp.length>0)
        {
          //setGroupName(grp[0].name)
          setGroupId(grp[0].id)
          labOrders= fetchLabOrdersByGroup(grp[0].id)
          setGroupNameList(grp)
          console.table(grp)
        }
      }
    }
    else
    {
      labOrders= fetchLabOrdersByGroup(groupId)
    }
     return labOrders
  }


  const sendEmailToPatient = async()=> {
     console.log('Send email to Patient');

      const apiName = 'amplifySendEmail';
      const path = '/email';
      const myInit = { 
          body: {
            'bucket': 'clearmddata234037-dev',
            'fileName': 'LV5002345621.pdf',
            'First': 'Demo',
            'Last': 'God',
            'Toemail': 'dharnishr@gmail.com'
          }, 
          headers: {
            "Allow-Control-Allow-Origin" : "*", 
            //"Authorization" : `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            "Access-Control-Allow-Headers":"*",
            //"access-control-allow-headers": "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            //"access-control-allow-methods": "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT" 
          }, // OPTIONAL
      };
       
       API.post(apiName, path, myInit)
       .then(response => {         
         console.log(response)
       })
       .catch(error => {
         console.log(error.response);
       });

  } 
  const getPatients = () => {
    /*alert('Getting Patients');

   // let user = await Auth.currentSession().getIdToken().JwtToken();
    //console.log("TOKEN\n", user)
   // const sessionToken = ""//user.getIdToken().getJwtToken()
    //const sessionToken = await Auth.currentSession().getIdToken().jwtToken();

    const apiName = 'clearmdchirestapi';
    const path = '/patients';
    const myInit = { 
      headers: {    
        "Content-Type":"application/json",
      }
    };

    API.get(apiName, path, myInit).then((response) => {
      console.log("patient test success: ", JSON.stringify(response))
    })
    .catch(error => {
      console.log("patient Test Error", error)
    })*/
     
  }
  //
  const populateLabOrderTable= async (groupId)=> {

    var labOrder=[]
    labOrder= await fetchLabOrders(groupId)
    
    if (labOrder && labOrder.length >0)
    { 
    const tableList  = labOrder.map( (lb,index) => {
      return ( {
        id:lb.id, 
        name: lb.Patient.firstname +' ' + lb.Patient.lastname ,                                 
        email: lb.Patient.email,
        phone: lb.Patient.mobile,
        lvlube: lb.OrderId,
        collected: new Date(lb.CollectedDate).toLocaleDateString('en-US'), 
        resulted: new Date(lb.ReportedDate).toLocaleDateString('en-US'),
        report:lb.ResultFile,
        status: lb.Result
      })
      } )

      setLabOrderList(tableList)    

      var headers=[ "name", "email" , "phone", "lvlube", "collected", "resulted","report", "status"]

      const columns = headers.map(c => ({
                                          name: c,
                                          selector: c
                                          }));         
      setCols(columns) 
    }  
  }

  const groupItems = groupNameList.map((menuItem)=> 
                                      <MenuItem  key={menuItem.id} value={menuItem.id}>{menuItem.Name}</MenuItem>
  )
  //improve the code
  const fetchLabOrder = async(emailId) => {    

    
    // Auth.currentAuthenticatedUser().then(authData => {      
    //   console.log('Email set',authData.attributes['email']);
    //   setAuthUserEmail(authData.attributes['email'])      
    //   emailId=authData.attributes['email'];
    // });
    
    let labOrders   = [] 
    const grpObj= 0
    if (grpObj.length>0)
    { 
        console.table(grpObj)
        
        var grp= grpObj.filter(gp =>gp.UserAssociated.find(o=>o===emailId))
        var listItem= grp.map((menuItem)=> <MenuItem key={menuItem.id} value={menuItem.id}>{menuItem.name}</MenuItem>)
        setGroupNameList(listItem)

        console.table('After Filter of email',emailId,grp)
        console.table('Group Items' ,listItem)
        if (grp.length>0)
        {
          setGroupId(grp[0].id)
          console.log('Group Info',grp[0].id,grp[0].name)
          //labOrders = (await DataStore.query(LabOrder,Predicates.ALL)).filter(lo=> lo.GroupInfo.id===grp[0].id)
          //console.table(labOrders)
      } 
    }
    else
    {
        console.log(' Group list is empty')
    }
              
              
    const tableList   = labOrders.map( (lb,index) => {
                          return ( {
                            id:lb.id, 
                            name: lb.Patient.firstname +' ' + lb.Patient.lastname ,                                 
                            email: lb.Patient.email,
                            phone: lb.Patient.mobile,
                            lvlube: lb.OrderId,
                            collected: new Date(lb.CollectedDate).toLocaleDateString('en-US'), 
                            resulted: new Date(lb.ReportedDate).toLocaleDateString('en-US'),
                            report:lb.ResultFile,
                            status: lb.Result
                          })
                          } )

      setLabOrderList(tableList)    
      
      var headers=[ "name", "email" , "phone", "lvlube", "collected", "resulted","report", "status"]

      const columns = headers.map(c => ({
        name: c,
        selector: c
      }));         
      setCols(columns)          
  }
const groupChangeEvent = (event) => {
                                  console.log(event.target.value);
                                  setGroupId(event.target.value);

  }; 
const tableColumns= [  
                      {name: "name",label:'Name'} , 
                      {name: "email" , label:'Email'} ,
                      {name:"phone", label:'Date Of Birth'} ,
                      {name:"lvlube", label:'Test Type'} ,
                      {name:"report", label:'Report', 
                            options:{
                                      filter:false,
                                      customBodyRender: (value, tableMeta, updateValue) => {
                                        return  <Button key={value} variant="outlined" size="small" color="primary" className={classes.margin} onClick={()=>downloadReportFile(value)}><ArrowDownwardIcon fontSize="inherit" />{value}</Button>
                                      }
                                    }
                      } ,
                      {name:"status", label:'Test Result',
                              options:{
                                      customBodyRender: (value, tableMeta, updateValue) => {
                                          return <Chip style={{backgroundColor:backgroundColorState[value], color:foregroundColor[value]}} label={value}/>
                                      } 
                              }
                      }
                    ]
  return (
    <>
      <PageTitle title={<>                    
                        <Select
                          id="groupSelect"
                          value={groupId}
                          onChange={groupChangeEvent}
                        >                             
                           {groupNameList} 
                          {/* {groupItems} */}
                      </Select>
                    <span>Groups...</span> 
                  </>} 
      button={
              <Button
              onClick={sendEmailToPatient} 
              variant="contained"
              size="medium"
              color="secondary"
            >
        Refresh
    </Button>} />
      <Grid container spacing={4}>

        <Grid item xs={12}>
        
        
          <Widget
            title= { 'Patient List'} 
            upperTitle
            noBodyPadding
            bodyClass={classes.tableWidget}
            disableWidgetMenu
          >
             {                           
             <MUIDataTable           
            data={labOrderList}
            columns={tableColumns}  
            options={{
              selectableRows: "none"              
            }}

          /> }

          </Widget>
        </Grid>
        <Grid item xs={12}>
          <button onClick={getPatients}>TEST REQUEST</button>
        </Grid>
      </Grid>
    </>
  );
}





