import  Dialog  from "@mui/material/Dialog"
import  DialogTitle  from "@mui/material/DialogTitle"
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField"
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card'
import { useEffect , useState } from "react"
import { getResultsLog } from "./getAppointments"

export function ResultLogPopup(props){
    const [results , setResults] = useState([]) 
    useEffect(() => {
        async function getResults(){ 
            if(props.open === true){ 
                console.log(props.patient.patientId)
                let results = await getResultsLog(props.patient.patientId)
                console.log(await  getResultsLog(props.patient.patientId))
                setResults(results)
                if(results.length > 0){ 
                    console.log(results[0].submitTime)
                    console.log(new Date(parseInt(results[0].submitTime)))
                }
               }
               console.log(results)
               console.log(results.length)
        }
        getResults()

    },[props.open , props.patient.patientId])
    const handleClose = () => { 
      props.showPopup(false)
    }

    const resultPopup = async () => { 

    }
     return ( 
      <Dialog open={props.open} onClose={handleClose}>
             <DialogTitle>Patient Result Sent Info: {`${props.patient.firstName} ${props.patient.lastName}`} </DialogTitle>
              {/** Check if the results array exists and it contains reuslts. If it just contains a lone reuslts with patientId with no results arry we don't use it. */}
             {results.length > 0 && results[0].results ? 
             <Card>
                {results && results[0].results && results.map((result , index) => (
                    <Accordion>
                        <AccordionSummary>
                        Submit Time {new Date(parseInt(result.submitTime)).toLocaleString()}  
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Test Type
                                        </TableCell>
                                        <TableCell>
                                            Result
                                        </TableCell>
                                    </TableRow>
                                    
                                </TableHead>
                                    {result.results && result.results.map((panel , index) => ( 
                                <TableRow>
                                    <TableCell>
                                        {panel.testType}
                                    </TableCell>
                                    <TableCell>
                                        {panel.res}
                                    </TableCell> 
                                </TableRow>
                            ))}

                            </Table>    
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Card>
            : <Card> No Sent Result Info Available </Card>}
             {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
   

      </Dialog>
    )
  }
  