import React from "react";
import {
  Route,
  Routes,
  Navigate,
  Outlet
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@mui/material'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
  mdiInstagram as InstagramIcon
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
// import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Myreports from "../../pages/myreports/MyReports";
import ReportsMenu from "../../pages/myreports/ReportsMenu";
import PaymentStatus from "../../pages/myreports/PaymentStatus";
import ResultsSearch from "../../pages/myreports/ResultsSearch";
import ComplianceReports from "../../pages/myreports/ComplianceReports";
import SendResults from "../../pages/myreports/SendReports";
import AccessDenied from "../../pages/myreports/accessdenied";
import PatientLookup from "../../pages/patientlookup/PatientLookup";
import { ProgressFormComponent } from "../../pages/generator";

import AmplifyComp from "../../pages/amplifycomp/Amplifycomp";
import UploadPatientData from "../../pages/uploadpatientdata/UploadPatientData";
import UploadReports from "../../pages/uploadreports/UploadReports";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";
import { PatientResultsSTI } from "../../pages/results/results";

function defaultRoute(userGroup) {
  switch (userGroup) {
    case "Compliance":
      return "reportsmenu";
    case "Admin":
      return "reportsmenu";
    case "Doctor":
      return "patientlookup";
    case "Billing":
      return "patientlookup";
    case "MedicalStaff":
      return "results";
    default:
      return "myreports";
  }
}

function accessGroups(userGroup) {
  const accessGroups = [
    'Admin',
    'Compliance',
    'Doctor',
    'Billing',
    'MedicalStaff',
    'Patient',
  ]
  return accessGroups.slice(accessGroups.indexOf(userGroup))
}


function Layout(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();
  const { userGroup } = useUserState();

   // todo: theres probably a better way to do this
  function AccessRestrictedRoute({allowed=["Patient"], redirect="error", children}) {
    return (
      !allowed.includes(userGroup) ? <Navigate to={redirect} />
      : children 
        ? <> {Array.isArray(children) ? children.map(child => child) : children} </> 
        : <Outlet />
    )
  }

  return (
    <div className={classes.root}>
        <>
          <Header />

          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Routes>
            <Route path="app/*" > 
              <Route
                index
                element={<Navigate to={defaultRoute(userGroup)} />}
              />
              {/* Patient */}
              <Route path="myreports" element={<Myreports/>} />

              {/* Admin */}
              <Route path="*" element={
                <AccessRestrictedRoute allowed={["Admin"]} redirect="error" />}>
              </ Route>

              {/* Compliance */}
              <Route path="*" element={
                <AccessRestrictedRoute allowed={["Admin", "Compliance"]} redirect="error" />
              }>
                <Route path="dashboard" element={<Dashboard/>} />
                <Route path="typography" element={<Typography/>} />
                <Route path="tables" element={<Tables/>} />
                <Route path="notifications" element={<Notifications/>} />
                <Route path="amplifycomp" element={<AmplifyComp/>} />
                <Route path="compliancereports" element={<ComplianceReports/>} />
                <Route path="progressnotes" element={<ProgressFormComponent/>} />
                <Route path="ui/*" >
                  <Route
                      index
                      element={<Navigate to="icons" />}
                    />
                  <Route path="maps" element={<Maps/>} />
                  {/* <Route path="icons" element={<Icons/>} /> */}
                  <Route path="charts" element={<Charts/>} />
                </Route> 
              </Route>

              {/* Doctors */}
              <Route path="*" element={
                <AccessRestrictedRoute allowed={["Admin", "Compliance", "Doctors"]} redirect="error" />
              }>
                <Route path="uploadpatientdata"  element={<UploadPatientData/>} />
                <Route path="uploadreports"  element={<UploadReports/>} />
                <Route path="reportsmenu" element={<ReportsMenu/>} />
              </Route>

              {/* Medical Staff */}
              <Route path="*" element={
                <AccessRestrictedRoute allowed={["Admin", "Compliance", "Doctors",  "MedicalStaff"]} redirect="error" />
              }>
                <Route path="paymentstatus" element={<PaymentStatus/>} />
                <Route path="resultsearch" element={<ResultsSearch/>} />
                <Route path="sendresults" element={<SendResults/>} />
                <Route path="patientlookup" element={<PatientLookup/>} />
                <Route path='results' element={<PatientResultsSTI/>}/>
              </ Route>
    
              <Route path="error" element={<AccessDenied/>} />
            </Route>
            </Routes>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
              sx={{
                position: "fixed",
                bottom: '0px'
              }}
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://www.clearmdhealth.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Clear MD
                </Link>
                <Link
                  color={'primary'}
                  href={'https://www.clearmdhealth.com/about-us'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>

              </div>
              <div>
                <Link
                  href={'https://www.facebook.com/ClearMDRapidTesting/'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/clearmdhealth'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://www.instagram.com/clearmdhealth/'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="instagram"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={InstagramIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default Layout;
