

export function canSubmitMultiplex(data){ 
    let alertArray  = []
    if(data.firstName === ''){ 
        alertArray.push({ 
            field : 'First Name:' , 
            message : 'field is empty.'  
        })
    }
    if(data.lastName === ''){ 
        alertArray.push({ 
            field : 'Last Name:' , 
            message : 'field is empty.'
        })
    }
    if(data.testType === '' || data.testType.length === 0){
        alertArray.push({
            field : 'Test Type:' ,
            message: 'field is empty.'
        })
    }
    if(data.result === ''){
        alertArray.push({
            field: 'Test Result:' ,
            message: 'field is empty.'
        })
    }
    if(data.DOB === 'Invalid Date'){
        alertArray.push({
            field: 'Date of Birth:' ,
            message: 'field is invalid.'
        })
    }
    if(data.DOS === ''){
        alertArray.push({
            field: 'Date of Service:' ,
            message: 'field is incorrect.'
        })
    }
    if(data.email === ''){
        alertArray.push({
            field: 'Email:' ,
            message: 'field is empty.'
        })
    }
    if(data.reason === ''){
        alertArray.push({
            field: 'Reason for visit:' ,
            message: 'field is empty.'
        })

    }
    // if(!data.claimNumber){
    //     alertArray.push({
    //         field: 'Claim Number:' ,
    //         message: 'field is empty.'
    //     })
    // }

    return alertArray
}
