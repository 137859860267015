import React, { useEffect, useState } from "react";
import { 
  Route, 
  Routes, 
  Navigate, 
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import { CircularProgress, Box } from '@mui/material';

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
import { useUserState, useUserDispatch, checkUserSession, signOut } from "../context/UserContext";

export default function App() {
  const { isAuthenticated } = useUserState();
  const userDispatch = useUserDispatch();
  const {userGroup} = useUserState();
  const { navigate } = useNavigate();

  const [isLoading, setIsLoading] = useState(isAuthenticated && !userGroup);

  useEffect(() => {
    if (isAuthenticated && !userGroup) {
      checkUserSession(userDispatch, navigate)
      .then(() => console.log("Authenticated user"))
      .catch(e => {
        console.log("Error checking user session:", e)
        signOut(userDispatch, navigate);
      })
      .finally(() => setIsLoading(false));
    }
  })

  return (
    <Routes>
      <Route  path="/" element={ <Navigate to="/app" /> } />
      <Route element={<PrivateRoute />} >
        <Route path="/*" element={<Layout/>} />
      </Route>
      <Route element={<PublicRoute/>}>
          <Route path="login" element={<Login/>} />
      </Route>
      <Route path="*" element={<Error/>} />
    </Routes>
  );

  // #######################################################################

  function PrivateRoute({ redirect="/login", children }) {
    const location = useLocation();
    return (
      isLoading 
      ?  <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
          width: '100vw',
        }}>
          <CircularProgress/>
        </Box>
      : !isAuthenticated 
      ?  <Navigate to={{ pathname: redirect, state: { from: location } }} />
      : children ? children : <Outlet />
    )
  }

  function PublicRoute() {
    const { isAuthenticated } = useUserState();
    return (
      isAuthenticated 
      ? < Navigate to= "/" />
      : < Outlet />
    );
  }
}
