import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Modal,
  Box,
  IconButton
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { RotateLeft, RotateRight } from '@mui/icons-material';

import { Label, Value, Heading, IdImage } from './styled';

export default function ImageModal({patientInfo, open, onClose, ...props}) {
  const [active, setActive] = React.useState(0);
  let [rotations, setRotations] = React.useState({});
  const [images, setImages] = React.useState([]);

  useEffect(()=> {
    const validImages = Object.keys(patientInfo.Images)
      .map(key => key.includes('Key') || !patientInfo.Images[key] ? null : key)
      .filter(key => key);
    setImages(validImages);
    for (const key of validImages) {
      setRotations(prev => ({...prev, [key]: 0}));
  
    }
  }, [patientInfo.Images])

  const handleRotateLeft = () => {
    let newRotations = {...rotations};
    if (rotations[images[active]] === 0) {
      newRotations[images[active]] = 270;
      setRotations(newRotations)
    } else {
      newRotations[images[active]] -= 90;
      setRotations(newRotations)
    }
  }

  const handleRotateRight = () => {
    let newRotations = {...rotations};
    if (rotations[images[active]] === 270) {
      newRotations[images[active]] = 0;
      setRotations(newRotations)
    } else {
      newRotations[images[active]] += 90;
      setRotations(newRotations)
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='Patient Information'
      aria-describedby='Patient Information Modal'
      sx={{
        position: 'absolute',
        top: '10%',
        left: '12vw',
        width: '75vw',
        height: {
          xs: '70vh',
          sm:'90vh',
        },
        maxHeight: '50rem',
        bgcolor: 'transparent',
      }}
    >
      <Card
        variant='outlined'
        sx={{
          display: 'inline-block',
          width: '100%',
          height: '100%',
          padding: 1,
          boxShadow: 3,
          borderRadius: 5,
          bgcolor: 'transparent',
        }}
      >
        <CardContent
          sx={{
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Carousel
            autoPlay={false}
            animation='slide'
            navButtonsAlwaysVisible={{xs: false, sm: true}}
            navButtonsProps={{
              sx: {
                opacity: .25,
                '&:hover': {
                  backgroundColor: '#3f51b5',
                }
              }
            }}
            height='70%'
            indicatorContainerProps={{
              style: {
                position: 'absolute',
                margin: 'auto',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }
            }}
            sx={{
              height: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
            }}
            dynamicHeight="false"
            next={(now, previous) => setActive(now)}
            prev={(now, previous) => setActive(now)}
          >
            {patientInfo 
            ? Object.keys(patientInfo.Images).map((key, index) => {
                if(key.includes('Key') || !patientInfo.Images[key]) return null;
                
                return (
                  <div key={index} style={{
                    position: 'relative',
                    top: '0%',
                    left: '0%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <IdImage 
                      src={patientInfo.Images[key]} 
                      boxShadow={0}
                      sx={{
                        position: 'relative',
                        rotate: `${rotations[key]}deg`,
                        scale: {
                          md: '2',
                        },
                        top:  '50%',
                        '@media (min-width: 900px) and (max-width: 1200px': {
                          translate: '-75% 0'
                        },
                        '@media (max-height: 950px)': {
                          scale: '.75',
                          translate: '-12.5% 0'
                        },
                      }}
                    />
                  </div>
                )
              }).filter(image => image)
            : <></>}
          </Carousel>
          <Box 
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '-1rem',
          }}>
            <IconButton onClick={handleRotateLeft} sx={{ margin: '0 5rem'}}>
              <RotateLeft/>
            </IconButton>
            <IconButton onClick={handleRotateRight} sx={{ margin: '0 5rem'}}>
              <RotateRight />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}