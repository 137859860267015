import React  from 'react';
import {
  Card,
  CardContent,
  CardMedia
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Typography } from '../../components/Wrappers/Wrappers';
import { Label, Value } from './styled';

import { formatPhoneNumber } from '../../utils';

export default function InfoCard({ patient, setSelectedPatient, ...props }) {
  return (
    <div onClick={setSelectedPatient} style={{ cursor: 'pointer' }}>
      <Card
        variant='outlined'
        sx={{
          display: 'inline-block',
          width: { xs: 250, sm: 220 },
          height: 380,
          padding: 1,
          boxShadow: 3,
          borderRadius: 5,
          // background: '#FAF9F6',
        }}
      >
        <CardMedia
          component='img'
          //image={patient.image}
          title={patient?.firstName || ''}
          src={patient?.governmentId}
          sx={{
            width: '100%',
            height: 200,
            borderRadius: '10%',
            marginBottom: -1
          }}
        />
        <CardContent>
          <Typography gutterBottom variant='h6'>
            {patient?.firstName || ''} {patient?.lastName || ''}
          </Typography>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              display='flex'
              justifyContent='space-between'
              wrap='false'
            >
              <Label variant='body2'>Id:</Label>
              <Value variant='body2'>{patient?.patientId || ''}</Value>
            </Grid>
            <Grid
              item
              xs={12}
              display='flex'
              justifyContent='space-between'
              wrap='false'
            >
              <Label variant='body2'>Sex:</Label>
              <Value variant='body2'>{patient?.sex || ''}</Value>
            </Grid>
            <Grid
              item
              xs={12}
              display='flex'
              justifyContent='space-between'
              wrap='false'
            >
              <Label variant='body2'>DOB:</Label>
              <Value variant='body2'>
                {
                  patient && patient.dobError === ''
                    ? patient.dob
                    : '' /** todo: Display DOB error */
                }
              </Value>
            </Grid>
            <Grid
              item
              xs={12}
              display='flex'
              justifyContent='space-between'
              wrap='false'
            >
              <Label variant='body2'>Phone:</Label>
              <Value variant='body2'>
                {formatPhoneNumber(patient?.phone || '')}
              </Value>
            </Grid>
          </Grid>
        </CardContent>
        {/* <CardActions>
              <Button size='small' color='primary'>
                  Edit
              </Button>
              <Button size='small' color='primary'>
                  More Info
              </Button>
          </CardActions> */}
      </Card>
    </div>
  );
}