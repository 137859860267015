import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";

export function MissingAlerts(props ){ 
    const closeAlert = () => { 
        props.setShowAlert(false)
    }
    return ( 
        <Dialog open = {props.showAlert} style = {{width : '50%'}} onClose = {closeAlert}>
            {props.missingAlert2.map((alert ) => (
                <Alert severity="warning" key={alert.field}>
                    <div>
                        {alert.field} {alert.message}
                    </div>
                </Alert>
   
            ))}
        </Dialog>
    )





}