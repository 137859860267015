import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu
} from "@mui/material";
import {
  Person as AccountIcon
} from "@mui/icons-material";
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components'
// import { DataStore } from '@aws-amplify/datastore';
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { SideNav } from "./SideNav.js";

// context
import { useUserState, useUserDispatch, signOut, checkUserSession } from "../../context/UserContext";

export default function Header(props) {
  var classes = useStyles();

  // global
  const userDispatch = useUserDispatch();
  const navigate = useNavigate();

  // local
  // var [mailMenu, setMailMenu] = useState(null);
  // var [isMailsUnread, setIsMailsUnread] = useState(true);
  // var [notificationsMenu, setNotificationsMenu] = useState(null);
  // var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  const [profileMenu, setProfileMenu] = useState(null);
  // var [isSearchOpen, setSearchOpen] = useState(false);

  const { userGroup, email } = useUserState();
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    if (authState === undefined) {
      checkUserSession(userDispatch)
      .then(authData => {
        setAuthState(AuthState.SignedIn);
        setUser(authData.username);
      })
      .catch(e => {
        // no-op
        console.log("Check user session error", e);
        signOut(userDispatch, navigate);
      });
    }
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  });  

  return (
    <AppBar position="fixed" className={classes.appBar} enableColorOnDark >
      <Toolbar className={classes.toolbar}>
        {userGroup !== 'Patient' ? <SideNav /> : <></>}
        <div 
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/app')}
        >
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            ClearMD   {userGroup}
          </Typography>
        </div>

        <div className={classes.grow} />
        <div className={classes.profileMenuUser}>
            <Typography variant="body2" weight="medium" sx={{
              display: { xs: 'none', sm: 'block' }
            }}>
            {email} 
            </Typography>
       
          </div>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
    <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h5" weight="medium">
            {email} 
            </Typography>

          </div>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={ async() => {
                // await DataStore.clear();
                signOut(userDispatch, navigate)
               }
              }
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
