
import { useEffect , useState , forwardRef , Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import  Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment';
import { patchAppt, getAppointment , sendResults, neccessaryKeys , isSendingDisabled , previewPDF , cancelAppointment , printLabel} from './getAppointments';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { useUserState } from "../../context/UserContext";
import OutlinedInput from '@mui/material/OutlinedInput'
import HomeIcon from '@mui/icons-material/Home';
import Switch, { SwitchProps } from '@mui/material/Switch';
import dayjs from 'dayjs' 
import Chip from '@mui/material/Chip'
import TextField  from '@mui/material/TextField';
import { listOfTests, resultsMap } from './const';
import DialogContent  from '@mui/material/DialogContent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { sendOutTests } from './const';
import { Menu } from '@mui/material';

/// Make the marginTop Higher 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * 
 * @param {Object} props 
 * @param {AppointmentInfo} props.patient
 * @returns 
 */
export default function ResultsPop(props) {
  const [open, setOpen] = useState(true);
  const [popup , showPopup] = useState(false)
  const [patientInfo, setPatientInfo] = useState(props.patient)
  const [popupS , showPopupS]  = useState(false) 
  const [popupConfirmation , setPopupConfirmation] = useState(false)
  const [previewPopup , setPreviewPopup] = useState(false)
  const [previewData , setPreviewData] = useState({})
  const {userGroup} = useUserState()
  const [update , setUpdate] = useState(false)
  const [deletePopup , showDeletePopup] = useState(false)
  const [sendReceiptPopup , showSendReceiptPopup] = useState(false)
  const [showLabelPopup , setShowLabelPopup] = useState(false)
  const [showNewTestPopup , setShowNewTestPopup] = useState(false)
  const [createPtSuccessPopup , showCreatePatientSuccessPopup] = useState(false)
  const [showBalanceDuePopup , setShowBalanceDuePopup] = useState(false)
  let addonList = new Set()

  const [canSubmit , setCanSubmit] = useState(neccessaryKeys(patientInfo)==='complete')

  const handleClose = () => {
    setOpen(false);
    props.setPopClose(false)
  };
  useEffect(() => {
    if(neccessaryKeys(patientInfo)!=='complete'){ 
      console.log('Hiya')
      setCanSubmit(false)
    }
    // else if(isSendingDisabled(patientInfo)!=='complete'){ 
    //   console.log(isSendingDisabled(patientInfo))
    //   setCanSubmit(false)
    // }
    else { 
      setCanSubmit(true)
    }
  }, [patientInfo, canSubmit]);  

  const sendResultChange = async() => { 
    if(isSendingDisabled(patientInfo , userGroup)!=='complete'){ 
      showPopup(true)
    } 
    else { 
      console.log('Done Results')
      await sendResults(patientInfo.patientId , patientInfo.appointmentDatetime , patientInfo)
      console.log('Done Results')
      showPopupS(true)
    }

  }
  const updatePatient = async() => { 
    console.log(patientInfo)
    await patchAppt(patientInfo.patientId , patientInfo.appointmentDatetime , patientInfo)
    props.setApptData(await getAppointment(props.location))
    console.log("Saving patient...")
    //await getAppt(props.patient.patientId , props.patient.appointmentDatetime , 'sti')
  }
  const preview = async() => { 
    try {
      let data = await previewPDF(patientInfo);
      if(data){ 
        console.log("Preview Data:", previewData);
        setPreviewData(data);
        setPreviewPopup(true);
        
      }
      else { 
        
      }
    } catch(e) {

    }
  }
  const removeTest = async(info , index) => { 
  //  let ind = patientInfo.results.find(res => res.res = info)
  console.log(patientInfo.results)
  let tempPatient = patientInfo
   tempPatient.results.splice(index , 1)
  setPatientInfo(tempPatient)
  setUpdate(!update)

  }
  const changeLocationRun = async(event , index) => { 
    const testLocationValue = patientInfo.results[index].labLocation === '(Rapid)' ? '(Send Out)' : '(Rapid)'
    console.log(index)
    console.log(patientInfo.results[index])
    const p = patientInfo
    p.results[index].labLocation = testLocationValue
    console.log(p)
    setPatientInfo(p)
    // I hate myself for doing this. Writing to an doesnt trigger a re render. 
    setUpdate(!update)
  }
  

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid container>
            <Grid item xs = {12}>
            <AppBar sx={{ position: 'relative', marginBottom : '10%' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Patient Results
            </Typography>
            <Button autoFocus color="inherit"  onClick={preview} disabled={!canSubmit}>
              preview
            </Button>
            <Button autoFocus color="inherit"  onClick={sendResultChange} disabled={!canSubmit}>
              send
            </Button>
            <Button autoFocus color="inherit" onClick={updatePatient}>
              save
            </Button>
          </Toolbar>
        </AppBar>
            </Grid>

        <Grid container sx={{marginTop : '2%'}}>
          <Grid item xs = {2}>

          </Grid>
          <Grid item xs = {8}>
            <Card>    
              <List>
              <Grid container>
                <Grid item xs = {6}>
                  <ListItem>
                    Patient Name : {patientInfo.firstName} , {patientInfo.lastName}
                  </ListItem>
                </Grid>
                <Grid item xs = {6}>
                  <ListItem>
                    Patient Date of Birth : {patientInfo.dob}
                  </ListItem>
                </Grid>
                <Grid item xs = {6}>
                  <ListItem>
                    Patient Date of Service : {dayjs(patientInfo.appointmentDatetime).format('MM/DD/YYYY')})
                  </ListItem>
                </Grid>
                <Grid item xs = {6}>
                  <ListItem>
                    Patient Type of Test: {patientInfo.testType}
                  </ListItem>
                </Grid>
                <Grid item xs = {6}>
                  <ListItem>
                    Patient Addons:  {patientInfo.addons?.map((addon) => {
                            return [`${[addon.S || addon]} \n`] })
                    }
                  </ListItem>
                </Grid>
                <Grid item xs = {6}>
                  <ListItem>
                    Patient Expected Panels/Current Panels: {addonList.length}
                  </ListItem>
                </Grid>
                <Grid item xs = {6}>
                  <ListItem>
                    Error Message: {neccessaryKeys(patientInfo)}
                  </ListItem>
                </Grid>
              </Grid>
              </List>
            </Card>
            <Card >
              <Grid container>
                
                <Grid item xs= {2}/>
                <Grid item xs = {8}>
                  <Stack
                   direction="row" justifyContent={'center'}>
                    <Item>
                    <Typography >{patientInfo.testType.toUpperCase()}</Typography>
                    </Item>
                  </Stack>
                </Grid>
                <Grid item xs= {2}/>
                <Grid item xs= {2}/>
                <Grid item xs = {8}>
                  <Stack direction="column" justifyContent={'space-evenly'}>
                    
                    {patientInfo.results.map((test , index) => (
                      <Item>
                        <Grid  container>
                          <Grid item xs = {patientInfo.results[index].labLocation ? 6 : 12}>                  
                          <Button onClick = {(event) => removeTest(test.testType , index)}>Remove {test.testType}</Button>
                          </Grid>
                          {patientInfo.results[index].labLocation ?
                          <Fragment>
                            <Grid item xs = {6}>                  
                              <Button onClick = {(event) => console.log('Hello')}>Location Run : {patientInfo.results[index].labLocation}</Button>
                            </Grid> 
                            <Switch checked = {patientInfo.results[index].labLocation === '(Rapid)' ? true : false} onChange = {(event) => changeLocationRun(event , index)}>

                            </Switch>
                          </Fragment>

                            : '' }

                        </Grid>
  
                        <Accordion>
                          
                        <AccordionSummary>{test.testType}{test.collectionType}</AccordionSummary>
                          <ResultsInput patient = {patientInfo} test={test} setPatient= {setPatientInfo} index = {index} 
                          result = {patientInfo.results && patientInfo.results[index] && patientInfo.results[index].res !== undefined?patientInfo.results[index].res : ''}
                          level = {patientInfo.results[index].level ? patientInfo.results[index].level : undefined}
                          collectionType = {patientInfo.results[index]?.collectionType} labLocation = {patientInfo.results[index].labLocation}/>
                        </Accordion>
                      </Item>
                    ))}
                    </Stack>
                </Grid>

              </Grid>


            </Card>
            <Grid container>
            <Grid xs={6}>
              <Button onClick={(event) => {showDeletePopup(true)}} disabled = {patientInfo.appointmentSource === 'portal' ? false : true}> Delete</Button>
              <Button onClick={(event) => {showSendReceiptPopup(true)}} disabled>Send Receipt</Button>
              <Button onClick={(event) => {setShowLabelPopup(true)}}>Print Label</Button>
              {/*<Button onClick={(event) => {setShowBalanceDuePopup(true)}} disabled >Balance Due</Button>*/}
              <Button onClick={(event) => {setShowNewTestPopup(true)}} >Add New Tests</Button>
              </Grid>
            </Grid>

          </Grid>

        </Grid>
        
      </Grid>
      {popup ? <WarningPopup showPopup={showPopup} popup = {popup}/> : ''}
      {popupS ? <SuccessPopup showPopupS={showPopupS} popupS = {popupS}/> : ''}
      {popupConfirmation ? <SuccessPopup setPopupConfirmation={setPopupConfirmation} popupConfirmation = {popupConfirmation} patient = {patientInfo}/> : ''} 
      {previewPopup ? <PDFPreview imageData={previewData} open ={previewPopup} setOpen = {setPreviewPopup}/> : '' } 
      {deletePopup ? <DeletePopup showPopup = {showDeletePopup} popup = {deletePopup} patient ={patientInfo} setPopClose ={props.setPopClose}/> : ''}
      {sendReceiptPopup ? <ReceiptPopup showPopup = {showSendReceiptPopup} open = {sendReceiptPopup} patient = {patientInfo}></ReceiptPopup> : ''}
      {showLabelPopup ? <LabelPopup showPopup = {setShowLabelPopup} open = {showLabelPopup} patient = {patientInfo}></LabelPopup>: ''}
      {showBalanceDuePopup ? <BalanceDuePopup showPopup = {setShowBalanceDuePopup} open = {showBalanceDuePopup} patient = {patientInfo}/> : ''}
      {showNewTestPopup ? <AddNewTestsPopup showPopup = {setShowNewTestPopup} open = {showNewTestPopup} patient = {patientInfo} setPatient = {setPatientInfo}/> : ""}
      </Dialog>
    </div>
  );
}

export function AddNewTestsPopup(props){ 
  const [testsToAdd , setTestsToAdd] = useState([]) 
  const handleClose = () => {
    props.showPopup(false)
  };
  const handleNewSelection = (event , newTests) => {
    console.log(newTests) 
    console.log(testsToAdd)
    setTestsToAdd(newTests)
  }
  /**
   * 
   * @param {*} event 
   * @description - Function adds tests into the localized patient object copy. Should trigger a redraw on the patient results popup page. 
   */
  const addTests = (event) => { 
    let testInfo = testsToAdd.map(test => {
      let testObj = {testType : test , res : ''}
      if(test.toLowerCase().includes('collection')){
        testObj.collectionType = ''
      }

      let sendOut = sendOutTests.find(sendOutAllowed => {
        if(sendOutAllowed.testType === test){ 
            return true
        }
        return false
    })
      if(sendOut) { 
          // If the test is a part of the sendout list, then set the lab location to the default value of sendout. 
          testObj.labLocation = sendOut.defaultValue
      }
      return testObj
    })
    let tempPatient = props.patient
    tempPatient.results =  [...props.patient.results , ...testInfo]
    props.setPatient(tempPatient)
    console.log(tempPatient)
  }

  return ( 
    <Dialog  onClose={handleClose} open={props.open} sx= {{height : '50vh'}}>
      <DialogTitle sx={{display :'flex' , justifyContent : 'space-between'}}>
        <p>Add New Tests</p>
        <Button onClick={addTests}>Done</Button>
      </DialogTitle>
      <DialogContent sx={{textAlign : "center"}}>
        <ToggleButtonGroup orientation='vertical' value = {testsToAdd} onChange={handleNewSelection} >          
          {listOfTests.map((test , index) => 
            <ToggleButton color="info" value = {test}>{test}</ToggleButton>
          )}      
        </ToggleButtonGroup>

      </DialogContent>

    </Dialog>
  )
}

export function DeletePopup(props){ 
  const handleClose = () => {
    props.showPopup(false)
  };

  const deletePatient = async() => { 
    let patient = {
      patientId : props.patient.patientId ,
      appointmentDatetime : props.patient.appointmentDatetime,    
      canceled : true 
    }
    await cancelAppointment(patient)
    props.setPopClose(false)
  }

  return ( 
    <Dialog onClose={handleClose} open={props.popup}>
      <DialogTitle>Are you sure you would like to delete this patient. </DialogTitle> 
      <Button onClick={deletePatient}>Yes</Button>
      <Button onClick={handleClose} > No</Button>
    </Dialog>
  )
}
export function ResultsInput(props){ 
  const [result , setResult] = useState(props.result)
  const [level , setLevel] = useState(props.level)
  const [collectionType , setCollectionType] = useState(props.collectionType)
  const onChange = (event) => { 
    console.log('This is the incoming event value: ' + event.target.value)
    setResult(event.target.value)
    // Find the location of this result in the patient object by comparing the patient testType and the prop testType passed from the same object
    // If the patient prop patient object is equal to the test
    // This code will first test if there is an element in teh array with the same testing location . If there is, then it will set then we will test the locations 
    // only for matching test types. 
    let index = props.patient.results.findIndex(res => {

      if(res.testType === props.test.testType && res.labLocation === props.test.labLocation){
        console.log('Found the index of the test in the patient object')
        return true
      }
      else { 
        return false
      }
    })
    console.log(index)
    index === -1 ? index = props.patient.results.findIndex(res => res.testType === props.test.testType) : console.log('Index already found')
    if(props.patient.results[index].res === 'Detected' && event.target.value === 'Not Detected'){
      console.log('Result flipped from detected to not detected')
      setLevel(undefined)
      let tempPatient = props.patient 
      console.log(index)
      console.log(tempPatient.results[index])
      console.log(result)
      tempPatient.results[index].res = event.target.value
      tempPatient.results[index].level = undefined
      console.log(tempPatient.results[index].res)
      console.log(tempPatient)
      props.setPatient(tempPatient)
    }
    else { 
      console.log('Not switching that way')
      let tempPatient = props.patient 
      console.log(index)
      console.log(tempPatient.results[index])
      console.log(result)
      tempPatient.results[index].res = event.target.value
      console.log(tempPatient.results[index].res)
      console.log(tempPatient)
      props.setPatient(tempPatient)
    }

  }
  const levelChange = (event) => { 
    console.log(event.target.value)
    // parseFloat(event.target.value).toFixed(1) > 1 ? setLevel(parseFloat(event.target.value).toFixed(1)) : setLevel(1)
   let tempLevel = parseFloat(event.target.value).toFixed(2) > .9 ? parseFloat(event.target.value).toFixed(2) : .9
   setLevel(tempLevel)
    console.log('Templevel : ' + tempLevel)
    let index = props.patient.results.findIndex(res => {
      console.log(res)
      console.log(props.test)
      if(res.testType === props.test.testType){ 
        return true
      }
      else { 
        return false
      }
    })
    let tempPatient = props.patient 
    console.log(index)
    console.log(tempPatient.results[index])
    tempPatient.results[index].level = tempLevel
    console.log(tempPatient.results[index].level)
    console.log(tempPatient.results[index])
    console.log(tempPatient)
    props.setPatient(tempPatient)

  }
  const onCollectionChange = (event) => { 
    let index = props.patient.results.findIndex(res => {
      if(res.testType === props.test.testType){ 
        return true
      }
      else { 
        return false
      }
    })
    console.log(event.target.value)
    console.log(index)
   let tempPatient = props.patient 
   tempPatient.results[index].collectionType = event.target.value
   props.setPatient(tempPatient)
   console.log(props.patient.results[index].collectionType)
  }
  return(
    <div>
      <Grid container >
        <Grid item xs={props.level ? 6 : 12}>
          <Select label = 'Result' onChange={(event) => onChange(event)} defaultValue={result} sx ={{width : '48%'}}>
            <MenuItem value={''}>PENDING</MenuItem>
            <MenuItem value={'Detected'}>Detected</MenuItem>
            <MenuItem value={'Not Detected'}>Not Detected</MenuItem>
          </Select>
          {result === 'Detected' && 
          <OutlinedInput sx = {{marginLeft : '2%' , width : '48%'}} defaultValue = {1.0}value = {level} placeholder='1.0' type='number' onChange ={(event) => levelChange(event)} inputProps={{step: level <= 1 ? .01 : .1 ,}}>
          </OutlinedInput>}
          {props.collectionType !== undefined ? 
                    <Select label = 'Collection Type' onChange={(event) => onCollectionChange(event)} defaultValue={collectionType} sx ={{width : '48%'}}>
                    <MenuItem value={'Urine'}>Urine Collection</MenuItem>
                    <MenuItem value={'Throat'}>Throat Collection</MenuItem>
                    <MenuItem value={'Genital'}>Genital Collection</MenuItem>
                    <MenuItem value={'Rectal'}>Rectal Collection</MenuItem>
                  </Select> : ''}
        </Grid>
 
       </Grid>
    </div>
  )
}

export function WarningPopup(props) {
  const handleClose = () => {
    props.showPopup(false)
  };

  return ( 
    <Dialog onClose={handleClose} open={props.popup}>
      <DialogTitle>Sorry not currently allowing Positive Results to be sent from this screen. </DialogTitle> 
    </Dialog>
  )
}
export function SuccessPopup(props) { 
  const handleClose = () => {
    props.showPopupS(false)
  };

  return ( 
    <Dialog onClose={handleClose} open={props.popupS}>
      <DialogTitle>Thank You. Result Submitted. </DialogTitle> 
    </Dialog>
  )
}
export function ConfirmationPopup(props) { 
  return ( 
    <Dialog>
      <DialogTitle>Please Confirm the Following Information </DialogTitle>
        <Grid container>
            <Grid item xs= {6}>Patient Name : {`${props.patient.firstName}, ${props.patient.lastName}`}</Grid>
            <Grid item xs= {6}>Patient Date of Service: {`${props.patient.firstName}, ${props.patient.lastName}`}</Grid>
        </Grid>
    </Dialog>
  )
}

export function PDFPreview(props){ 
  const handleClose = () => { 
    props.setOpen(false)
    
  }
  return ( 
    <Dialog open={props.open} onClose={handleClose}>
      {/* ReactPDF.renderToStream(<MyDocument />); */}
      {console.log(props.imageData)}
      <iframe title={'Huasi'} src={props.imageData} alt='PDF Preview' height={'560px'} width = {'560px'}></iframe>
      {/* <embed src={props.imageData} alt='PDF Preview'/> */}
      {/* <iframe src={props.imageData} alt='PDF Preview'/> */}
    </Dialog>
  )
}
export function ReceiptPopup(props){ 
  const handleClose = () => { 
    props.showPopup(false)
  }
  return ( 
    <Dialog open={props.open} onClose={handleClose}>
           <DialogTitle>Send A New Receipt to a Patient </DialogTitle>
        <Grid container sx={{}}>
            <Grid item xs= {12}><Typography >Patient Name : {`${props.patient.firstName}, ${props.patient.lastName}`}</Typography></Grid>
            <Grid item xs= {12}>Patient Date of Service: {`${props.patient.appointmentDate}, ${new Date(props.patient.appointmentDatetime).toLocaleTimeString()}`}</Grid>
            <Grid item xs= {12}><TextField  startAdornment={<InputAdornment position="start">$</InputAdornment>} label="Cost"></TextField></Grid>
        </Grid>
    </Dialog>
  )
}

export function LabelPopup(props){ 
  const handleClose = () => { 
    props.showPopup(false)
  }
  const handleDelete = (index) => { 
    console.log(index)
   let currentTests = labelsList.slice()
   console.log(currentTests)
   currentTests.splice(index , 1)
   console.log(currentTests)
   setLabelsList([...currentTests])
}
  const handleChange = (event) => { 
    //console.log(event.target.value)
    let labelSet = new Set(labelsList)
    console.log(labelSet)
    console.log(event.target.value)
    labelSet.add(event.target.value)
    console.log(labelSet)
    setLabelsList(Array.from(labelSet))
    console.log(labelsList)

  }
  const [labelsList , setLabelsList] = useState([])
  const [location , setLocation] = useState('')
  return ( 
    <Dialog open={props.open} onClose={handleClose}>
           <DialogTitle>Send A New Label to be printed </DialogTitle>
        <Grid container
         alignItems="center"
         justifyContent="center">
            <Grid item xs= {8}><Typography >Patient Name : {`${props.patient.firstName}, ${props.patient.lastName}`}</Typography></Grid>
            <Grid item xs= {8}>Patient Date of Service: {`${props.patient.appointmentDate}, ${new Date(props.patient.appointmentDatetime).toLocaleTimeString()}`}</Grid>
            <Grid item xs ={6}> 
                <Select sx={{width : '80%'}} onChange={handleChange} >
                  <MenuItem value = "STREP">STREP</MenuItem>
                  <MenuItem value = "CHLAMYDIA">CHLAMYDIA</MenuItem>
                  <MenuItem value = "GONORRHEA">GONORRHEA</MenuItem>
                  <MenuItem value = "HIV">HIV</MenuItem>
                  <MenuItem value = "HSV">HSV</MenuItem>
                  <MenuItem value = "SYPHILIS">SYPHILIS</MenuItem>
                  <MenuItem value = "LIPID PANEL">LIPID PANEL</MenuItem>
                  <MenuItem value = "LIVER PANEL PLUS">LIVER PANEL PLUS</MenuItem>
                  <MenuItem value = "RENAL FUNCTION PANEL">RENAL FUNCTION PANEL</MenuItem>
                  <MenuItem value = "COMPLETE BLOOD COUNT">COMPLETE BLOOD COUNT</MenuItem>
                  <MenuItem value = "MYCOPLASMA GENITALIUM">MYCOPLASMA GENITALIUM</MenuItem>
                  <MenuItem value = "UREAPLASMA UREALYTICUM">UREAPLASMA UREALYTICUM</MenuItem>
                  <MenuItem value = "UTI">UTI</MenuItem>
                  <MenuItem value = "HEPATITIS B">HEPATITIS B</MenuItem>
                  <MenuItem value = "7 PANEL">7 PANEL</MenuItem>
                  <MenuItem value = "TRICHOMONIASIS">TRICHOMONIASIS</MenuItem>
                  <MenuItem value = "HEPATITIS C">HEPATITIS C</MenuItem>
                  <MenuItem value = "COVID PCR">COVID PCR</MenuItem>
                  <MenuItem value = "COVID ANTIGEN">COVID ANTIGEN</MenuItem>
                  <MenuItem value = "BACTERIAL VAGINOSIS (PCR)">BACTERIAL VAGINOSIS (PCR)</MenuItem>
                  <MenuItem value = "CANDIDA (PCR)">CANDIDA (PCR)</MenuItem>
                  <MenuItem value = "CANDIDA (ANTIGEN)">CANDIDA (ANTIGEN)</MenuItem>
                  
                </Select>
              </Grid>
              <Grid item xs = {12}>
              <Box sx = {{width : '100%' , height: '40vh',  display : 'flex'}}>
                <Paper elevation={3} sx = {{m: 1, ml : '5%' ,  width: '90%', height: '90%', p : '3%'}}>
                    {labelsList.map((test , index) => (
                            <Chip key = {index} label = {test} sx = {{ml : '2%'}} onDelete={(event) => {handleDelete(index)}}/>
           
                    ))}
                </Paper>
            </Box>
              </Grid>
              <Grid item xs = {6}>
              <Select sx={{width : '80%'}} 
               label = 'Enter Location to Print' onChange={(event) => setLocation(event.target.value)} value = {location}>
                <MenuItem value = "734 Broadway">NOHO</MenuItem>
                <MenuItem value = "2007 Broadway">UWS</MenuItem>
                <MenuItem value = "1051 Third Ave">Midtown</MenuItem>
                <MenuItem value = "600 6th Avenue">Chelsea</MenuItem>
                <MenuItem value = "187 Centre Street">Chinatown</MenuItem>
              </Select>
            </Grid>
        </Grid>
        <Grid container alignItems='flex-end'>
          <Grid item> <Button onClick={() => printLabel(props.patient , labelsList , location)}>Send Label</Button> </Grid>
        </Grid>
    </Dialog>
  )
}

export function BalanceDuePopup(props){ 
  const handleClose = () => { 
    props.showPopup(false)
  }
  return ( 
    <Dialog open={props.open} onClose={handleClose}>
    <DialogTitle>Send A New Past Due Balance Request to a Patient.  </DialogTitle>
    <Grid container
         alignItems="center"
         justifyContent="center">
          <Grid item xs={6} >
            <TextField 
              style={{width : '90%' , paddingLeft : '10%'}}     
              multiline
              maxRows={4}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              />
            </Grid>
            <Grid item xs = {6}>
            <Select style={{width : '90%'}}>
              <MenuItem>Past Due Balance</MenuItem>
            </Select>
          </Grid>
    </Grid>
    </Dialog>
  )
}





