import React, {useState,useMemo, useEffect} from 'react';
import { makeStyles } from "@mui/styles";
import {Grid,Button, CircularProgress} from '@mui/material';
import { Modal,Tooltip} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Document, Page, pdfjs} from 'react-pdf';
import ImgViewer from 'react-images-viewer';
import {Auth} from 'aws-amplify';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_ENV === 'prod' 
? "https://5hh32psqcj.execute-api.us-east-1.amazonaws.com/prod" 
: "https://bjb0pwkls9.execute-api.us-east-1.amazonaws.com/dev";

const useStyles = makeStyles(theme => ({
    tableContainer: {
        [theme.breakpoints.between("xs", "sm")]: {
            margin: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
            margin: '0 6%',
         },
         [theme.breakpoints.between("md", "lg")]: {
              margin: '0 10%',
         },
         [theme.breakpoints.up("lg")]: {
             margin: '0 15%',
         },
    },
    searchBarContainer: {
        [theme.breakpoints.between("xs", "sm")]: {
            margin: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
            margin: '0 6%',
         },
         [theme.breakpoints.between("md", "lg")]: {
              margin: '0 10%',
         },
         [theme.breakpoints.up("lg")]: {
             margin: '0 15%',
         },
         justifyContent:'center',
         borderStyle:"solid",
         borderWidth:'1px',
         borderColor:'royalblue',
         marginBottom: "15px",
    },
    searchButton: {
        margin: "2px",
    },
    patientHeader: {
        textAlign:"center",
    },
    patientRow:{
        
    },
    table: {
        borderStyle: 'solid',
        borderColor: 'royalblue', 
        borderWidth: '1px',
        width:'100%',
        textAlign:'center',
    },
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:'white',
        border: '1px solid royalblue',
        width: "50%",
        height: "50%",
    },
    appointmentHeader: {
        fontSize: "20px",
        fontWeight: "bold",
        textAlign:'center',
        marginBottom:"5px",
    },
    appointmentTableRow: {
        textAlign:'center',
    },
    appointmentTableContainer: {
        maxHeight:"50vh",
        overflow:"auto",
    },
    appointmentInputBarContainer:{
        display:"flex",
        alignItems:'center',
        justifyContent:'center',
    },
    appointmentInputBar: {
        width:"100%",
        margin: '0 20% 10px 20%',
    },
    circularLoading:{
        display:"flex",
        justifyContent:"center",
    },
}));


export default function ResultSearch(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [approved,setApproved] = useState(0);
    const [tableData,setTableData] = useState([]);
    const [tableDataBackup,setTableDataBackup] = useState([]);
    const [appointmentTableData,setAppointmentTableData] = useState([]);
    const [governmentIdPages,setGovernmentIdPages] = useState(null);
    const [governmentIdPageNumber,setGovernmentIdPageNumber] = useState(1);
    const [searchFirst,setSearchFirst] = useState('');
    const [searchLast,setSearchLast] = useState('');
    const [searchDob,setSearchDob] = useState('');
    const [searchEmail,setSearchEmail] = useState('');
    const [loading,setLoading] = useState(0);
    const [loadingModal,setLoadingModal] = useState(0);
    const [generalError,setGeneralError] = useState(0);
    const [generalErrorMessage,setGeneralErrorMessage] = useState('');
    const [openModal,setOpenModal] = useState(0);
    const [currentPatient,setCurrentPatient] = useState({governmentId:{S:'none'},insuranceCardBack:{S:'none'},insuranceCardFront:{S:'none'}});
    const [disable,setDisable] = useState(0);
    const [snackbarError,setSnackbarError] = useState(0);
    const [snackbarErrorMessage,setSnackbarErrorMessage] = useState('');
    const [imageModal,setImageModal] = useState(0);
    const [frontInsurance,setFrontInsurance] = useState('');
    const [insuranceBack,setInsuranceBack] = useState('');
    const [url,setUrl] = useState('');
    const [url2,setUrl2] = useState('');
    const [url3,setUrl3] = useState('');
    const [imageViewer,setImageViewer] = useState(false);
    const [imagePage,setImagePage] = useState(0);
    const [images,setImages] = useState([]);

    useEffect(async () => {
      // const controller = new AbortController();
      // async function checkAuth() {
      //   const groups = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload["cognito:groups"];
      //   if (controller.aborted) return;
      //   if(!groups){
      //       setApproved(0);
      //       navigate('/app/error');
      //   }
      //   else if(!groups.includes('Compliance')){
      //       setApproved(0);
      //       navigate('/app/error');
      //   }
      //   else{
      //       console.log('access granted');
      //       setApproved(1);
      //       pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      //   }
      // }
      // checkAuth()
      // return () => controller.abort();
      const groups = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload["cognito:groups"];
      if(!groups){
          setApproved(0);
          navigate('/app/error');
      }
      else if(!groups.includes('Compliance')){
          setApproved(0);
          navigate('/app/error');
      }
      else{
          console.log('access granted');
          setApproved(1);
          pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    },[])

    const governmentPages = ({numPages}) => {
        setGovernmentIdPages(numPages);
    }

    const handleOpen = () => {
        setOpenModal(1);
    }

    const handleClose = () => {
        setOpenModal(0);
        setCurrentPatient({});
    }

    const replaceDomain = (array) => {
        return array.map(link => {
            if(link.src.includes('acuity-patient-identification')){
                link.src = 'https://du4m0xw29iwvx.cloudfront.net' + link.src.slice(64);
            }
            return link;
        })
    }

    const handleImageOpen = async (data) => {
        if(!data && !currentPatient.governmentId){
            console.log("No Image available");
        }
        else{
            // await something(data ? data : currentPatient.governmentId.S);
            const sources = [{src: data.governmentId.S,caption:'Government ID'},{src: data.insuranceCardBack.S, caption:'Insurance Card Back'},{src: data.insuranceCardFront.S, caption: 'Insurance Card Front'}];
            setUrl(data.governmentId.S ? data.governmentId.S : currentPatient.governmentId.S);
            setUrl2(data.insuranceCardBack.S ? data.insuranceCardBack.S : currentPatient.insuranceCardBack.S);
            setUrl3((data.insuranceCardFront.S ? data.insuranceCardFront.S : currentPatient.insuranceCardFront.S));
            setImages(replaceDomain(sources));
            setImageModal(1);
            setImageViewer(true);
        }
    }

    const handleImageViewerClose = () => {
        setImageViewer(false);
        setImagePage(0);
    }
    
    const handleImageClose = () => {
        if(!imageViewer)
            setImageModal(0);
    }

    const setpage = (page) => {
        setImagePage(page);
    }

    const handleAppointmentInput = () => {
        
    }

    const getInvoice = async (patient) => {
        setDisable(1);
        setSnackbarError(0);
        setSnackbarErrorMessage('');
        let response;
        let notFound = false;
        try{
            response = await axios.get(`${apiUrl}/invoice?patientId=${patient.patientId.S}&appointmentDatetime=${patient.appointmentDatetime.S}&testType=${patient.testType.S}`);
            if(response.data.message === "No Such Key"){
                notFound = true;
            }
            else{
                // window.open(response.data.message,'_blank',{name:`${currentPatient.firstName.S} ${currentPatient.lastName.S}`});
                let link = document.createElement('a');
                link.href = response.data.message;
                link.download = `${currentPatient.firstName.S} ${currentPatient.lastName.S}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        catch(e){
            console.log(e);
        }
        finally{
            setDisable(0);
            if(notFound){
                setSnackbarError(1);
                setSnackbarErrorMessage("File not Found");
            }
        }
    }

    const getResult = async (appointment) => {
        setDisable(1);
        setSnackbarError(0);
        setSnackbarErrorMessage('');
        let response;
        let notFound = false;
        try{
            response = await axios.get(`${apiUrl}/results?patientId=${appointment.patientId.S}&appointmentDatetime=${appointment.appointmentDatetime.S}&testType=${appointment.testType.S}&firstName=${currentPatient.firstName.S}&lastName=${currentPatient.lastName.S}`);

            if(response.data.message === "No Such Key"){
                notFound = true;
            }
            else{
                // window.open(response.data.message,'_blank',{name:`${currentPatient.firstName.S} ${currentPatient.lastName.S}`});
                let link = document.createElement('a');
                link.href = response.data.message;
                link.download = `${currentPatient.firstName.S} ${currentPatient.lastName.S}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        catch(e){
            console.log(e);
        }
        finally{
            setDisable(0);
            if(notFound){
                setSnackbarError(1);
                setSnackbarErrorMessage('File not Found')
            }
        }
    }

    const handleCloseSnackbar = () => {
        setSnackbarError(0);
        setSnackbarErrorMessage('');
    }

    const handleClickPatient = async (patient) => {
        handleOpen();
        setLoadingModal(1);
        setCurrentPatient(patient);
        let response;
        let errors = 0;
        try{
            response = await axios.get(`${apiUrl}/getappointments?patientId=${patient.patientId.S}`);
        }
        catch(e){
            console.log(e);
            errors+=1;
        }
        finally{
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage("Network Error");
            }
            else if(response.data.data === "ERROR"){
                setGeneralError(1);
                setGeneralErrorMessage(`Something went wrong when getting appointments for ${patient.firstName} ${patient.lastName}`);
            }
            else{
                setAppointmentTableData(response.data.message);
            }
            setLoadingModal(0);
        }
    }

    const goBack = () => {
      navigate(-1);
    }

    const searchPatients = async () => {
        setGeneralError(0);
        setGeneralErrorMessage('');
        setLoading(1);

        let errors = 0;
        try{
            if(searchEmail === '' && searchDob === '' && searchFirst === ''){
                const e = new Error("Can no accept empty fields");
                e.name = "EMPTY";
                throw e;
            }
            const response = await axios.get(`${apiUrl}/getPatient?email=${searchEmail.trim().toLowerCase()}&dob=${searchDob.trim()}&firstName=${searchFirst.trim()}`);
            if(response.data.data === "SUCCESS"){
                setTableData(response.data.message);
                setTableDataBackup(response.data.message);
            }
            else{
                errors+=1;
            }
        }
        catch(e){
            console.log(e);
            errors+=1;
        }
        finally{
            setLoading(0);
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage("Something went wrong when getting patients");
            }
        }
    }

    const handleHover = (event) => {
        event.target.style.background = 'grey';
        event.target.style.borderColor = 'royalblue';
        event.target.style.borderStyle = 'solid';
        event.target.style.borderWidth = '1px';
    }

    const renderTable = useMemo(() => {
        return(
            (tableData.length !== 0) ? 
                <Grid container className={classes.table} xs={12}>
                    <Grid className={classes.patientHeader} xs={3}>Name</Grid>
                    <Grid className={classes.patientHeader} xs={3}>Dob</Grid>
                    <Grid className={classes.patientHeader} xs={3}>Email</Grid>
                    <Grid className={classes.patientHeader} xs={3}>images</Grid>
                    {tableData.map((row) => {
                        return(
                            <Grid container xs={12}>
                                <Grid item xs={9}>
                                    <Button style={{textTransform:'none'}} onClick={() => handleClickPatient(row)} fullWidth={true}>
                                        <Grid item xs={4}>{row.firstName.S + " " + row.lastName.S}</Grid>
                                        <Grid item xs={4}>{row.dob.S}</Grid>
                                        <Grid item xs={4}>{row.email.S}</Grid>
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button style={{textTransform:'none'}} fullWidth={true}> 
                                        <Grid disabled={imageModal} onClick={async () => await handleImageOpen(row)} item>Images</Grid>
                                    </Button>
                                </Grid>
                            </Grid>
                        ) 
                    })}
                </Grid>:
            <Grid xs={12} style={{textAlign:"center",fontSize:"2em", borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px',marginTop:"15px"}}>No Results</Grid>
        )
    },[tableData]);

    const renderAppointmentTable = useMemo(() => {
        return(
            (appointmentTableData.length !== 0) ?
                <table style={{borderCollapse:"collapse"}} className={classes.table}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Location</th>
                            <th>Test Type</th>
                            <th>Result</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appointmentTableData.map((row,index) => {
                            return(
                                <tr key={row.patientId.S + "-" + index}>
                                    <td>{moment(row.appointmentDatetime.S).format('MM/DD/YYYY hh:mm a')}</td>
                                    <td>{row.appointmentLocation.S}</td>
                                    <td>{row.testType.S}</td>
                                    <td>{row.testResult.S}</td>
                                    <td><Button disabled={disable} onClick={() => getResult(row)}>Result</Button> <Button disabled={disable} onClick={() => getInvoice(row)}>Invoice</Button> </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table> :
                <Grid className={classes.appointmentTableRow} xs={12}>No Result</Grid>
        )
    })

    return(
        <Grid container>
            {approved ? 
            <Grid container>
                <Snackbar
                    open={snackbarError}
                    autoHideDuration={1500}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical:'top', horizontal:'center' }}
                >
                    <MuiAlert severity="error">{snackbarErrorMessage}</MuiAlert>
                </Snackbar>
                <Grid container>
                    <Modal
                        open={openModal}
                        onClose={handleClose} 
                    >
                        <Grid container style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: "60%",
                                height: "60%",
                                backgroundColor: '#f6f7ff',
                                border: '1px solid royalblue',
                                boxShadow: 24,
                                p: 4,
                                }}>
                            
                            <Grid item xs={12}>
                                <Grid item xs={12} className={classes.appointmentHeader}>Appointments</Grid>
                                {(loadingModal) ? 
                                    <Grid item xs={12} className={classes.circularLoading}>
                                        <CircularProgress/>
                                    </Grid> : 
                                    <Grid container className={classes.appointmentTableContainer} xs={12}>
                                        <input className={classes.appointmentInputBar} onChange={(event) => handleAppointmentInput(event.target.value)} placeholder='Enter in Date' />
                                        {renderAppointmentTable}
                                    </Grid>
                                    }
                            </Grid>
                        </Grid>
                    </Modal>
                    <Modal
                    open={imageModal}
                    onClose={handleImageClose}>
                        <Grid container style={{
                            position: 'absolute',
                            top:'50%',
                            left:'50%',
                            transform: 'translate(-50%,-50%)',
                            width: '90%',
                            height:'70%',
                            backgroundColor:'#f6f7ff',
                            border:'1px solid royalblue',
                            boxShadow: 24,
                            p:4,
                        }}>
                            <Grid container xs={12}>
                                <Grid xs={4}>
                                    <Document file={url} error={'Image is not a PDF'} >
                                        <Page height={550} pageNumber={1} />
                                    </Document>
                                    Government ID
                                </Grid>
                                <Grid xs={4}>
                                    <Document file={url2} error={'Image is not a PDF'}>
                                        <Page height={550} pageNumber={1} />
                                    </Document>
                                    Insurance Card Back
                                </Grid>
                                <Grid xs={4}>
                                    <Document file={url3} error={'Image is not a PDF'}>
                                        <Page height={550} pageNumber={1} />
                                    </Document>
                                    Insurance Card Front
                                </Grid>
                                <ImgViewer imgs={images} isOpen={imageViewer} currImg={imagePage} onClickPrev={() => setpage(imagePage-1)} onClickNext={() => setpage(imagePage+1)} onClose={() => handleImageViewerClose()} />
                            </Grid>
                        </Grid>
                    </Modal>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Button onClick={() => goBack()} color="primary" style={{backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                            Back
                        </Button>
                    </Grid>
                    <Grid container className={classes.searchBarContainer}>
                        <Grid item>
                            <Grid style={{textAlign:"center",fontSize:"2em",fontWeight:"bold"}}>Search</Grid>
                            <Tooltip title="Lowercase all letters"><input className={classes.searchButton} onChange = {event => setSearchEmail(event.target.value)} placeholder='Email'/></Tooltip>
                            <Tooltip title="MM/DD/YYYY"><input className={classes.searchButton} onChange = {event => setSearchDob(event.target.value)} placeholder='Dob'/></Tooltip>
                            <Tooltip title="Case Sensitive"><input className={classes.searchButton} onChange = {event => setSearchFirst(event.target.value)} placeholder='First Name'/></Tooltip>
                            <Button onClick = {() => searchPatients()} style={{marginLeft:'10px',justifySelf: "right"}}>Submit</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.tableContainer}>
                        {loading ? <LinearProgress style={{width: '100%', marginTop:"10px",marginBottom: '10px'}} /> : ""}
                    </Grid>
                    <Grid container xs={12} className={classes.tableContainer}>
                        {renderTable}
                    </Grid>
                </Grid>
            </Grid> : ""}
        </Grid> 
    )
}