import { getResultsLog } from "../getAppointments"

      

export async function getAllResults(patientArray){
    let patientRequestArray = []
    let finalPatientDataArray  = []
    //Check
    console.log(patientArray)

    patientArray.forEach(patient => { 
        patientRequestArray.push(getResultsLog(patient.patientId))
        
    })
    const requestResults = await Promise.all(patientRequestArray)
    console.log('The result request for the result')
    console.log(requestResults)
    console.log(patientRequestArray[0])
    console.log("Fin")
    requestResults.forEach(patientsResults => {
        const initialPatientIndex =  patientArray.findIndex(patient => patient.patientId === patientsResults[0].patientId)
        const initialPatient = patientArray[initialPatientIndex]
        const initialTests = initialPatient.results
        let allTests = initialTests
        const appointmentDatetime = initialPatient.appointmentDatetime
        console.log("Initial")
        console.log(initialTests)
        let finalObj = {}
        
        // This is where we start looking through each patient. 
        if(!(patientsResults.length === 1 && patientsResults[0].results === undefined)){
            console.log(patientsResults) 
            patientsResults.forEach(resultsRequestMade => { 
                // Once we look through each patient find all their returned result requests
                console.log(resultsRequestMade)
                    //  Once we have a list of all the result requests we go through the individual panel noted on them. 
                    resultsRequestMade.results.forEach((result, index )=> {
                        // For each result we check if the same object fields exists on the initial results object. 
                        // If it does and the new results have data you replace the objects. 
                        initialTests.forEach((test, index) => {
                            // If the inital test object contains an empty result and the returned results object doesn't. 
                            // Change the index of the initial test Array to the returned result value 
                            if(test.testType === result.testType && test.res === '' && result.res !== ''){ 
                                allTests[index].res = result.res
                            }
                        })
                        // Check if this particular result even exists in the array. 
                        const doesTestExist = allTests.findIndex(allTestEntry =>  allTestEntry.testType === result.testType) 
                        // The test does not exist in the object 
                        console.log(doesTestExist)
                        if(doesTestExist === -1){
                            console.log(doesTestExist) 
                            allTests.push({testType : result.testType , res : result.res})
                        }
                })
    
            })
            finalObj.results = allTests
            finalObj.firstName  = initialPatient.firstName
            finalObj.lastName  = initialPatient.lastName
            finalObj.patientId = initialPatient.patientId
            finalObj.index = initialPatientIndex
            finalObj.initalObjectIndex = initialPatient
            finalObj.addons = initialPatient.addons 
            finalObj.testTime = initialPatient.appointmentTime
            finalObj.testType = initialPatient.testType 
            finalObj.appointmentDateTime = initialPatient.appointmentDatetime

        }
        else { 
            finalObj.firstName  = initialPatient.firstName
            finalObj.lastName  = initialPatient.lastName
            finalObj.results = allTests
            finalObj.index = initialPatientIndex
            finalObj.patientId = initialPatient.patientId
            finalObj.addons = initialPatient.addons 
            finalObj.testTime = initialPatient.appointmentTime
            finalObj.testType = initialPatient.testType 
            finalObj.appointmentDateTime = initialPatient.appointmentDatetime

        }

        console.log(initialTests)
        finalPatientDataArray.push(finalObj)
        
    })
    console.log(finalPatientDataArray)
    return finalPatientDataArray


}


/*
    //     requestResults['addons'] = currentPatient.addons
    // })
    // patientArray.forEach(patient => {
    //     let patientInfoBlock = {}
    //     patientInfoBlock.patientId = {
    //         appointmentTime : patientArray[patient.patientId] , 
    //         addons : patientArray.addons , 

    //     }
    //     patientInfoBlock.
    //     patientInfoBlock.addons = patientArray.addons
    //     patientInfoBlock.testType = patientArray.testType
    //     patientInfoBlock.oldResults = patientArray.results
    //     finalPatientDataArray.push(patientInfoBlock)
    // })
       if(patientsResults.length !== 0 ){ 
            const patientId = patientsResults[0].patientId
            const currentPatient = patientArray.find(patient => {return patient.patientId == patientId})
            patientArray.forEach(patientObj => {
                
                if (finalObj[`${patientId}`] == undefined){

                    finalPatientDataArray = []
                }
                
                const newObj = {}
                newObj.addons = currentPatient.addons
                newObj.testTime = currentPatient.appointmentTime
                newObj.testDate = currentPatient.appointmentDate
                newObj.testType = currentPatient.testType
                newObj.oldResults = currentPatient.results
                newObj[patientId] = patientsResults

                finalPatientDataArray.push(newObj)

                finalObj[`${patientId}`] = finalPatientDataArray


            })
        }
*/