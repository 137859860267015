import {Drawer , Button } from '@mui/material'
import { useState } from 'react'
import {autoFillFunctions} from '../functions/format'
import dayjs from 'dayjs'


export function HelpComponent(props) { 
    const autoFill = new autoFillFunctions()
    const [missingAlertData , setMissingAlertData ]  = useState([])
    const runAutoFillFunction  = (event) => { 
        event.preventDefault()
        console.log(event?.target.value)
        let index = event.target.value
        console.log(props.data[index].firstName)
        console.log(autoFill.formatPhoneNumber(props.data[index].phone))
        props.actions.setFirst(props.data[index].firstName)
        props.actions.setPhone(autoFill.formatPhoneNumber(props.data[index].phone))
        props.actions.setEmail(props.data[index].email)


        let parsedLocation = autoFill.parseLocation(props.data[index].location)
        typeof(parsedLocation) == 'string' ? props.actions.setLocation(parsedLocation) : (missingAlertData.push(parsedLocation) && setMissingAlertData(missingAlertData))
        console.log(parsedLocation)

        let parsedDrugs = autoFill.parseDrugs(props.data[index].drugAllergies || '')
        Array.isArray(parsedDrugs) ? props.actions.setDrugs(parsedDrugs) : (missingAlertData.push(parsedDrugs) && setMissingAlertData(missingAlertData)) 
        console.log(parsedDrugs)

        let parsedReason = autoFill.parseReason(props.data[index].reason || '')
        typeof(parsedReason) == 'string' ? props.actions.setReason(parsedReason) : (missingAlertData.push(parsedReason) && setMissingAlertData(missingAlertData))
        console.log(parsedReason)

        let parsedVaccine = autoFill.parseVaccine(props.data[index].vaccineType || '')
        typeof(parsedVaccine) == 'string' ? props.actions.setVaccine(parsedVaccine) : (missingAlertData.push(parsedVaccine) && setMissingAlertData(missingAlertData))
        console.log(parsedVaccine)

        let parsedHeart = autoFill.parseHeartRate(props.data[index].heartRate || '')
        typeof(parsedHeart) == 'string' ? props.actions.setHeartRate(parsedHeart) : (missingAlertData.push(parsedHeart) && setMissingAlertData(missingAlertData))
        console.log(parsedHeart)

        let parsedOxygen = autoFill.parseOxygen(props.data[index].oxygen || '')
        typeof(parsedOxygen) == 'string' ? props.actions.setOxygen(parsedOxygen) : (missingAlertData.push(parsedOxygen) && setMissingAlertData(missingAlertData))
        console.log(parsedOxygen)

        let parsedTemp = autoFill.parseTemp(props.data[index].temperature || '')
        typeof(parsedTemp) == 'string' ? props.actions.setTemp(parsedTemp) : (missingAlertData.push(parsedTemp) && setMissingAlertData(missingAlertData))
        console.log(parsedTemp)

        let parsedSmoker = autoFill.parseSmoker(props.data[index].smoker)
        typeof(parsedSmoker) == 'string' ? props.actions.setSmoker(parsedSmoker) : (missingAlertData.push(parsedSmoker) && setMissingAlertData(missingAlertData))
        console.log(parsedSmoker)

        let parsedTestResult = autoFill.parseTestResult(props.data[index].label)
        typeof(parsedTestResult) == 'string' ? props.actions.setResult(parsedTestResult) : (missingAlertData.push(parsedTestResult) && setMissingAlertData(missingAlertData))
        console.log(parsedTestResult)
        
        let parsedDateOfService = autoFill.parseDateOfService(props.data[index].apptDate)
        typeof(parsedDateOfService) == 'string' ? props.actions.setDOS(parsedDateOfService) : (missingAlertData.push(parsedDateOfService) && setMissingAlertData(missingAlertData))
        console.log('Date of Service ' + parsedDateOfService)

        let parsedDateOfBirth = autoFill.parseDateofBirth(props.data[index].dob || '')
        typeof(parsedDateOfBirth) == 'string' ? props.actions.setDOB(parsedDateOfBirth) : (missingAlertData.push(parsedDateOfBirth) && setMissingAlertData(missingAlertData))
        console.log('Date of Birth ' + parsedDateOfBirth)

        let parsedMedical = autoFill.parseMedical(props.data[index].pastMedical || '')
        Array.isArray(parsedMedical) ? props.actions.setMedical(parsedMedical) : (missingAlertData.push(parsedMedical) && setMissingAlertData(missingAlertData))
        console.log(parsedMedical)

        let parsedSymptoms = autoFill.parseSymptoms(props.data[index].symptoms || '')
        Array.isArray(parsedSymptoms) ? props.actions.setSymptoms(parsedSymptoms) : (missingAlertData.push(parsedSymptoms) && setMissingAlertData(missingAlertData))
        console.log(parsedSymptoms)

        let parsedMedications = autoFill.parseMedications(props.data[index].medication || '')
        Array.isArray(parsedMedications) ? props.actions.setMedicationsI(parsedMedications) : (missingAlertData.push(parsedMedications) && setMissingAlertData(missingAlertData))
        console.log(parsedMedications)
        console.log(missingAlertData)
    }  

    return ( 
        <Drawer anchor='left' open  = {props.toggle} onClose={() => props.setToggle(false)} sx={{
            width: '25vw',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: '25vw',
              top: '7.5vh',
              boxSizing: 'border-box',
            },
          }}>
            {props.data?.map((person , index ) =>     
            <Button style ={{width : '100%'}} value = {index}
             onClick = {(event ) => {runAutoFillFunction(event)}}>  
                {person.firstName} {person.lastName}---- {dayjs(person.apptDate || '').format('MM/DD/YYYY hh:mm A')} {person.label || ''}
                <br/>
                {person.testType}
       
            </Button>  
            )}
        </Drawer>)
}