import {useState} from 'react';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import Avatar from '@mui/material/Avatar';
import Snackbar from '@mui/material/Snackbar';
import {
    Grid,
    Button,
    Chip
} from "@mui/material";
import Alert from '@mui/material/Alert';
import  { Storage } from 'aws-amplify';
import { Typography } from "../../components/Wrappers/Wrappers";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    tableOverflow: {
      overflow: 'auto'
    },
    viewReportButton: {
        borderStyle: 'none', 
        width: '100%',  
        cursor: 'pointer',
        backgroundColor: 'royalblue', 
        color: 'white', 
        borderRadius: '4px',
        transition: 'background-color .2s ease-in',
        [theme.breakpoints.between("xs", "sm")]: {
            height: '40px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: '40px'
        },
        [theme.breakpoints.between("md", "lg")]: {
            height: '45px'
        },
        [theme.breakpoints.up("lg")]: {
            height: '45px'
        },
        "&:hover": {
            backgroundColor: 'white',
            color: 'royalblue'
        }
    },
    disabledReportButton: {
        borderStyle: 'none', 
        width: '100%',  
        cursor: 'pointer',
        backgroundColor: '#EBEBE4', 
        color: 'white', 
        borderRadius: '4px',
        [theme.breakpoints.between("xs", "sm")]: {
            height: '40px'
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: '40px'
        },
        [theme.breakpoints.between("md", "lg")]: {
            height: '45px'
        },
        [theme.breakpoints.up("lg")]: {
            height: '45px'
        },
    },
    dateHeader: {
        float: 'left', 
        color: 'black', 
        paddingBottom: '10px',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '.9em'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '1em'
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '1em'
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '1em'
        },
    },
    chip: {
       
    },
    appointmentNameInfo: {
        marginTop: '0', 
        marginBottom: '0',
        color: 'royalblue',
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: '1em'
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: '1em'
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: '1.1em'
        },
        [theme.breakpoints.up("lg")]: {
            fontSize:'1.1em'
        },
    }
}));

export default function BuildPatientCards(props) {

    const classes = useStyles();

    const [reportError, setReportError] = useState(0);
    const [loading,setLoading] = useState(0);

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    function openReport(path){

        async function getReport(){
            setLoading(1);
            setReportError(0);
            setOpen(false);
            const requestConfig = {
                customPrefix: {
                    public: '',
                    protected: '',
                    private: '',
                    download: false
                },
                level: 'public',
                cacheControl: 'no-cache',
            };
            let items = await Storage.list(path, requestConfig); 
            let items2 = (path.includes('pcr')) ? await Storage.list(path.slice(0,-4) + '_CoV.pdf', requestConfig) : [];
        
            if(items.length === 0 && items2.length === 0){
                setReportError(1);
                setOpen(true);
                return("FALSE");

            }
            else{
                let uniquePath = (items.length) ? await Storage.get(path, requestConfig) : '' ;
                let uniquePath2 = (items2.length) ? await Storage.get(path.slice(0,-4) + '_CoV.pdf', requestConfig) : '';
                return [uniquePath,uniquePath2];
            }
        }

        getReport().then(function(response) {
            console.log(response);
            if(response !== "FALSE"){
                if(response[0]){
                    let link = document.createElement('a');
                    link.href = response[0];
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setTimeout(()=>{
                    if(response[1]){
                    let link2 = document.createElement('a');
                    link2.href = response[1];
                    document.body.appendChild(link2);
                    link2.click();
                    document.body.removeChild(link2);
                    }
                    setLoading(0);
                },500);
            }
        }).catch(()  => {
            setLoading(0);
        })
        
    }

    function selectChip(status){
        if(status === "NEGATIVE"){
            return(<Chip size="small" label="Negative" color="success" variant="outlined" className={classes.chip} style={{backgroundColor: 'green', color: 'white'}} />);
        }
        else if(status === "POSITIVE"){
            return(<Chip size="small" label="Positive" color="success" variant="outlined" className={classes.chip} style={{backgroundColor: 'red', color: 'white'}} />);
        }
        else{
            return(<Chip size="small" label="Pending" color="success" variant="outlined" className={classes.chip} style={{backgroundColor: 'grey', color: 'white'}} />);
        }
    }

    const [open, setOpen] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return(
        <Typography>
            <Grid container style={{borderRadius: '20px', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;'}}>
            <Grid container style={{height: '7px', backgroundColor: 'royalblue', borderTopRightRadius: '20px', borderTopLeftRadius: '20px'}}></Grid>
            <Grid container style={{backgroundColor: 'white', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', color: 'black', padding: '3% 3%'}}>
                {reportError?
                    <Snackbar open={open} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                            Report is still pending!
                        </Alert>
                    </Snackbar>
                : ''}
                <Grid item xs={7}>
                    <b className={classes.dateHeader}>{props.appointment.date}</b>
                </Grid>
                <Grid item xs={5} style={{padding: '0 1%', textAlign: 'right'}}>
                    {selectChip(props.appointment.status)}
                </Grid>
                <Grid item xs={1}>
                    <Avatar sx={{backgroundColor: 'royalblue'}} variant="rounded">
                        <CoronavirusIcon fontSize="large" style={{color: 'white'}} />
                    </Avatar>
                </Grid>
                <Grid item xs={11} style={{padding: '0 7%'}}>
                    <p className={classes.appointmentNameInfo}><b>{props.appointment.name}</b></p>
                    <p style={{marginTop: '0'}}>{props.appointment.testType}</p>
                </Grid>
                {props.appointment.status === "POSITIVE" || props.appointment.status === "NEGATIVE"?
                <Grid item xs={12} style={{marginTop: '12px', borderRadius: '8px'}}>
                    <button disabled={loading} onClick={() => openReport(props.appointment.report)} className={(loading) ? classes.disabledReportButton : classes.viewReportButton}>View Report</button>
                </Grid>: ""}
            </Grid>
            <Grid container style={{height: '7px', backgroundColor: 'royalblue', borderBottomRightRadius: '20px', borderBottomLeftRadius: '20px'}}></Grid>
            </Grid>
        </Typography>
    );
}