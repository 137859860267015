import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Modal,
  IconButton,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'
import { RotateLeft, RotateRight } from '@mui/icons-material';

import { Button } from '../../components/Wrappers/Wrappers';
import { Label, Value, Heading, IdImage } from './styled';
import ImageModal from './ImageModal';

import {
  formatPhoneNumber,
  formatKey,
  checkPatientErrors,
  categorizePatientInfo
} from '../../utils';

const InfoModal = ({children, ...props}) =>  (
  <Modal
    sx={{
      position: 'absolute',
      top: '10%',
      left: '12vw',
      width: '75vw',
      height: '80vh',
      maxHeight: '50rem',
    }}
    {...props}
  >
    {
      Array.isArray(children) 
      ? (<> {children.map((child, index) => (child))} </>)
      : (children)
    }
  </Modal>
)

// todo: check for errors and display them
export default function PatientInfo({ open, onClose, patient, ...props }) {
  const [patientErrors, setPatientErrors] = useState({});
  const [patientInfo, setPatientInfo] = useState({});
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [showImageSection, setShowImageSection] = useState(false);
  useEffect(() => {
    const { errors, hasError } = checkPatientErrors(patient);
    if (hasError) {
      setPatientErrors(errors);
    }
    try {
      setPatientInfo(categorizePatientInfo(patient));
    } catch (e) {
      setPatientErrors({ ...errors, patientInfo: e.message });
    }
  }, [patient]);

  return (
    <InfoModal
      open={open}
      onClose={onClose}
      aria-labelledby='Patient Information'
      aria-describedby='Patient Information Modal'
    >
      <Card
        variant='outlined'
        sx={{
          display: 'inline-block',
          width: '100%',
          height: '100%',
          padding: 1,
          boxShadow: 3,
          borderRadius: 5,
          background: '#FAF9F6',
          overflowY: 'scroll'
        }}
      >
        <CardContent>
          <Grid container spacing={1} width='100%' height='100%' rowGap={3}>
            <Grid width='100%' display='flex' justifyContent='center'>
              <Heading gutterBottom variant='h3' color='#182848'>
                Patient Information
              </Heading>
            </Grid>
            <Grid container columns={12} rowGap={2} height="100%">
              {Object.keys(patientInfo).map((category, index) => {
                if (category === 'Images') return null;
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={`patient-info-category-${index}`}
                  >
                    <Heading variant='h5' gutterBottom color='#182848'>
                      {category}
                    </Heading>
                    <Grid container rowGap={1}>
                      {Object.keys(patientInfo[category]).map((key, index) => {
                        if (key.includes('Error')) return null;
                        return (
                          <Grid item xs={12} sm={6} key={`patient-info-${key}`}>
                            <Label variant='body2'>
                              {formatKey(key) + ':'}
                            </Label>
                            <Value variant='body2'>
                              {key === 'phone'
                                ? formatPhoneNumber(patientInfo[category][key])
                                : patientInfo[category][key]}
                            </Value>
                          </Grid>
                        );
                      }).filter(item => item)}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Button
              sx={{
                width: '100%',
                '&:hover': {
                  background: 'transparent'
                },
              }}
              onClick={() => {
                setImageModalOpen(true);
              }}
            >{showImageSection ? 'Hide' : 'Show'} IDs</Button>
            {showImageSection && (
              <Grid item xs={12} display='flex'>
                {patientInfo?.Images 
                ? (
                  Object.keys(patientInfo.Images).map((key, index) => {
                    if(key.includes('Key') || !patientInfo.Images[key]) return null;
                    return (
                      <Grid item xs={12} sm={8} margin='auto'>
                        <ZoomImage key={key} imageKey={key} image={patientInfo.Images[key]}/>
                      </Grid>
                    );
                  })
                ) : (
                  <></>
                )}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <ImageModal patientInfo={patientInfo} open={imageModalOpen} onClose={() => {
        setImageModalOpen(false);
      }} />
    </InfoModal>
  );
}

function ZoomImage({imageKey, image, ...props}) {
  const [rotation, setRotation] = useState(0);
  return (
    <>
      <Zoom 
        style={{
          float: 'left',
        }}
        classDialog={`zoom-dialog-${imageKey}`}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <IdImage 
            src={image} 
            alt={formatKey(imageKey)} 
            sx={{
              transform: `rotate(${rotation}deg)`,
            }}
          />
        </div>
      </Zoom>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '1rem',
      }}>
        <IconButton 
          onClick={()=>{
            rotation === 270 ? setRotation(0) : setRotation(rotation + 90)
            const zoomImg = document.querySelector(`zoom-dialog-${imageKey} [data-rmiz-modal-img]`)
            if (zoomImg) {
              zoomImg.style.transform = `rotate(${rotation}deg)`;
            }
          }}
        >
          <RotateLeft/>
        </IconButton>
        <IconButton>
          <RotateRight />
        </IconButton>
      </Box>
    </>
  )
}