import { makeStyles } from "@mui/styles";


export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  loginRegistrationContent: {
    paddingTop: '0', 
    width: '100%', 
    padding: '0 20%', 
    marginTop: '0',
    [theme.breakpoints.between("xs", "sm")]: {
      padding: '0 2%', 
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: '0 5%', 
    },
    [theme.breakpoints.up("md")]: {
      padding: '0 20%', 
    },
  },
  signInButton: {
      cursor: 'pointer', 
      width: '100%', 
      height: '45px', 
      borderRadius: '6px', 
      fontWeight: '900', 
      color: 'white', 
      fontSize: '110%', 
      borderStyle: 'none', 
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      backgroundColor: 'royalblue',
      transition: 'background-color .2s ease-out',
      "&:hover": {
        backgroundColor: 'white',
        color: 'royalblue'
      }
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "55%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "#536dfe",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  clearmdHeader: {
     color: 'royalblue', 
     [theme.breakpoints.down("md")]: {
      lineHeight: '32px',
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: '15px',
    },
  },
  formContainer: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      width: '70%', 
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: '55%', 
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: '60%'
    },
    [theme.breakpoints.up("lg")]: {
      width: '45%'
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,

  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    color: 'royalblue',
    fontSize: '1.3em',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5)
  },
  altButton: {
      backgroundColor: 'transparent', 
      width: '100%', 
      borderStyle: 'none', 
      height: '45px', 
      color: 'royalblue', 
      fontSize: '1.2em',
      transition: 'background-color .2s',
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: '#EEEEEE'
      }
  },
  verifyCodeButton: {
      marginTop: '15px', 
      height: '40px', 
      width: '35%', 
      height: '45px', 
      backgroundColor: '#EEEEEE', 
      color: 'white', 
      cursor: 'pointer',
      borderStyle: 'none', 
      color: 'royalblue',
      fontSize: '100%',
      transition: 'background-color .2s',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      "&:hover": {
        backgroundColor: 'royalblue',
        color: 'white',
      }
  },
  primaryButton: {
      backgroundColor: 'royalblue', 
      width: '100%', 
      borderStyle: 'none', 
      height: '45px', 
      color: 'white', 
      fontSize: '1.2em',
      cursor: 'pointer'
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  resendButton: {
    borderStyle: 'none',
    fontSize: '1em',
    padding: '2px 6px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'royalblue',
      borderRadius: '4px',
      color: 'white'
    }
  },
  formDividerContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
}));
