import React, {useState, useRef, useMemo} from 'react';
import {
    Grid,
    Button
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Menu from '@mui/material/Menu';
import { CSVLink } from 'react-csv';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tabs from '@mui/material/Tabs';
import CancelIcon from '@mui/icons-material/Cancel';
import AlertTitle from '@mui/material/AlertTitle';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import Alert from '@mui/material/Alert';
import * as CSV from 'csv-string';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from "@mui/styles";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import date from 'date-and-time';

const apiUrl = process.env.REACT_APP_ENV === 'prod'
? "https://5hh32psqcj.execute-api.us-east-1.amazonaws.com/prod" 
: "https://bjb0pwkls9.execute-api.us-east-1.amazonaws.com/dev";

const useStyles = makeStyles(theme => ({
    locationButtonsContainer: {
       [theme.breakpoints.between("xs", "sm")]: {
            padding: '0 0',
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: '0 25%',
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: '0 30%',
        },
        [theme.breakpoints.up("lg")]: {
            padding: '0 35%',
        },
    },
    selectionsContainer: {
        [theme.breakpoints.between("xs", "sm")]: {
             padding: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
             padding: '0 15%',
         },
         [theme.breakpoints.between("md", "lg")]: {
             padding: '0 20%',
         },
         [theme.breakpoints.up("lg")]: {
             padding: '0 25%',
         },
     },
    tableMargins: {
        [theme.breakpoints.between("xs", "sm")]: {
            margin: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
            margin: '0 6%',
         },
         [theme.breakpoints.between("md", "lg")]: {
              margin: '0 10%',
         },
         [theme.breakpoints.up("lg")]: {
             margin: '0 15%',
         },
     },
     scanTubesMargins: {
 
         [theme.breakpoints.between("sm", "md")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.between("md", "lg")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.up("lg")]: {
            marginRight: '3%'
         },
         backgroundColor: 'royalblue', 
        color: 'white', 
        borderRadius: '6px', 
        padding: '10px 4px',
        transition: 'background-color .4s',
        "&:hover": {
            backgroundColor: 'lightgrey',
            color: 'royalblue'
        },
        cursor: 'pointer'
     },
     sendResultsMargins: {
 
         [theme.breakpoints.between("sm", "md")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.between("md", "lg")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.up("lg")]: {
             marginRight: '3%'
         },
        backgroundColor: 'royalblue', 
        color: 'white', 
        transition: 'background-color .4s',
        "&:hover": {
            backgroundColor: 'lightgrey',
            color: 'royalblue'
        },
        borderRadius: '6px', 
        padding: '10px 4px',
        cursor: 'pointer'
     }
}));

const LOCATION_CODES = {
    'Chelsea': '600 6th avenue',
    'Noho': '734 broadway',
    'Midtown': '1051 third avenue',
    'Chinatown': '187 centre street',
    'Upper East Side': '1157 Madison Ave',
    'Upper West Side': '2007 broadway',
    'Astoria': '31-78 steinway street'
}


export default function ComplianceReports(props) {

    const classes = useStyles();
    const navigate = useNavigate();

    const csvLink = useRef();
    const filesubmissionref = useRef();
    const csvDataRef = useRef();

    const [sendingResult, setSendingResult] = useState(0);
    const [progressBar, setProgressBar] = useState(0);
    const [fileContent, setFileContent] = useState('');
    const [loading, setLoading] = useState(0);
    const [success, setSuccess] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [generalError, setGeneralError] = useState(0);
    const [generalErrorMessage, setGeneralErrorMessage] = useState('');
    const [resultsErrorList, setResultsErrorList] = useState([]);
    const [resultsError, setResultsError] = useState(0);
    const [resultsErrorMessage, setResultsErrorMessage] = useState('');
    const [unscannedPatientError, setUnscannedPatientError] = useState(0);
    const [unscannedPatientErrorMessage, setUnscannedPatientErrorMessage] = useState('');
    const [scannedPatientError, setScannedPatientError] = useState(0);
    const [scannedPatientErrorMessage, setScannedPatientErrorMessage] = useState('');
    const [dataAll, setDataAll] = useState([]);
    const [backupDataAll, setBackupDataAll] = useState([]);
    const [scanningType, setScanningType] = useState(0);
    const [csvData, setCsvData] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [data, setData] = useState([]);
    const [backupData, setBackupData] = useState([]);
    const [currentLocation, setCurrentLocation] = useState('');
    const [locationSelected, setLocationSelected] = useState(0);
    const [currentFileName, setCurrentFileName] = useState('');
    const [progress, setProgress] = useState(10);
    const [sendResults, setSendResults] = useState(0);
    const [scanResults, setScanResults] = useState(0);
    const [resultsData, setResultsData] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [searchAllCriteria, setSearchAllCriteria] = useState('');
    const [fileRetrievalSuccessful, setFileRetrievalSuccessful] = useState(0);
    const [loadingFile, setLoadingFile] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [patientCount, setPatientCount] = useState(0);
    const [uploadingReport, setUploadingReport] = useState(0);
    const [allScannedPatientStates, setAllScannedPatientStates] = useState([]);

    // Initiates Uploading of CSV to Corepoint
    function uploadReport() {
        csvDataRef.current.click();
        setShowOptionMenu(0);
    }
    
    // Handles scanning selection (Unscanned, all, and verification)
    const handleScanningChange = async(event, newValue) => {
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);
        setLoading(1);
        setVerificationGeneralError(0);
        setVerificationSuccess(0);
        setLvCodeDuplicateStatus("Pending");
        setCrossVerifyAcuityStatus("Pending");
        setVerifyMissingStatus("Pending");
        setVerifyHeadersStatus("Pending");
        setVerifyMissingStatus("Pending");
        setVerifyDatesStatus("Pending");
        setVerifyIdsStatus("Pending");
        setLvCodeDuplicateResult("None");
        setCrossVerifyAcuityResult("None");
        setVerifyMissingResult("None");
        setVerifyHeadersResult("None");
        setVerifyMissingResult("None");
        setVerifyDatesResult("None");
        setVerifyIdsResult("None");
        setLvCodeDuplicateView("None");
        setCrossVerifyAcuityView("None");
        setVerifyHeadersView("None");
        setVerifyMissingView("None");
        setVerifyDatesView("None");
        setVerifyMissingView("None");
        setVerifyIdsView("None");

        setScanningType(newValue);
        if(newValue === 0){
            let scannedStates = allScannedPatientStates;
            let newScannedStates = [];
            scannedStates.forEach((stateItem) => {
                newScannedStates.push(0);
            });   
            setAllScannedPatientStates(allScannedPatientStates => [...newScannedStates]);
             
            let deleteStates = deleteSampleStates;
            let newDeleteStates = [];
            deleteStates.forEach((stateItem) => {
                newDeleteStates.push(0);
            });   
            setDeleteSampleStates(deleteSampleStates => [...newDeleteStates]);

            setData(data => [...backupData]);
            setDataAll(dataAll => [...backupDataAll]);
            setSearchAllCriteria('');
            setStartVerification(0);
            setUploadedCurrentLocationCSV(0);
            setUploadedAcuityCSV(0);
            setPatientCount(backupData.length);
        }
        else if(newValue === 1){
            let scannedStates = allScannedPatientStates;
            let newScannedStates = [];
            scannedStates.forEach((stateItem) => {
                newScannedStates.push(0);
            });   
            setAllScannedPatientStates(allScannedPatientStates => [...newScannedStates]);
            let deleteStates = deleteSampleStates;
            let newDeleteStates = [];
            deleteStates.forEach((stateItem) => {
                newDeleteStates.push(0);
            });   
            setDeleteSampleStates(deleteSampleStates => [...newDeleteStates]);
            setData(data => [...backupData]);
            setDataAll(dataAll => [...backupDataAll]);
            setSearchCriteria('');
            setPatientCount(backupDataAll.length);
            setStartVerification(0);
            setUploadedCurrentLocationCSV(0);
            setUploadedAcuityCSV(0);
        }
        else{
            let scannedStates = allScannedPatientStates;
            let newScannedStates = [];
            scannedStates.forEach((stateItem) => {
                newScannedStates.push(0);
            });   
            setAllScannedPatientStates(allScannedPatientStates => [...newScannedStates]);
            let deleteStates = deleteSampleStates;
            let newDeleteStates = [];
            deleteStates.forEach((stateItem) => {
                newDeleteStates.push(0);
            });   
            setDeleteSampleStates(deleteSampleStates => [...newDeleteStates]);
            setData(data => [...backupData]);
            setSearchCriteria('');
            setDataAll(dataAll => [...backupDataAll]);
            setSearchAllCriteria('');
        }

        setLoading(0);
    };

    // Function that exports the CSV with patient LV Codes
    async function exportFile() {
        
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);
        setCsvData('');
        setShowOptionMenu(0);
        setLoading(1);

        const contents = {
            location: LOCATION_CODES[currentLocation]
        }

        let response;
        let errors = 0;
        try{
           response = await axios.post(`${apiUrl}/csv/corepoint`, contents);
           console.log(response);
        } catch(err) {
            console.log(err);
            errors += 1;
        } finally {
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage("CSV Generation Failed");
            }
            else{
                if(response.data && response.data.body && response.data.body === "Error"){
                    setGeneralError(1);
                    setGeneralErrorMessage("CSV Generation Failed");
                }
                else{
                    setCsvData(response.data.body);
                    csvLink.current.link.click();
                }
            }
            setLoading(0);
        }
    }

    // Gets All Patients without an LV Code
    async function getLocationNotScannedData(locationInstance) {
        //Small Change to trigger a push
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);

        const buildQuery = `location=${locationInstance}`;

        let response;
        let errors = 0;
        try {
            response = await axios.get(`${apiUrl}/unscannedpatients?${buildQuery}`);
            console.log("RESPONSE: ", response);
        } catch(err) {
            errors += 1;
        } finally {
            if(errors){
                setUnscannedPatientError(1);
                setUnscannedPatientErrorMessage("Error Getting Unscanned Patient Data");
            }
            else{
                if(response.data && response.data.data && response.data.data !== "ERROR"){   

                    let patientInfoArr = [];
                    const appointmentData = response.data.data; 

                    appointmentData.forEach((appointmentInfo) => {

                        const identifier = appointmentInfo.patientId + "=" + appointmentInfo.appointmentDatetime;
                        const time = appointmentInfo.appointmentDatetime.split('T')[1].split("-")[0];
                        const splitDate = appointmentInfo.appointmentDatetime.split('T')[0];
                        const dateandtime = splitDate + "T" + time;
                        const currentDatetime = date.parse(dateandtime, "YYYY-MM-DDTHH:mm:ss");
                        const formatCurrentDatetime = date.format(currentDatetime, 'hh:mm A');

                        if(appointmentInfo.firstName !== undefined && appointmentInfo.lastName !== undefined && appointmentInfo.dob !== undefined &&
                            appointmentInfo.firstName !== "" && appointmentInfo.lastName !== "" && appointmentInfo.dob !== ""){
                                patientInfoArr.push(createData(appointmentInfo.firstName, appointmentInfo.lastName, appointmentInfo.dob, identifier, identifier, formatCurrentDatetime));
                            }
                    });  

                    setPatientCount(appointmentData.length);
                    setData(data => [...patientInfoArr]);
                    setBackupData(backupData => [...patientInfoArr]);
                }
                else{
                    setUnscannedPatientError(1);
                    setUnscannedPatientErrorMessage("Error Getting Unscanned Patient Data");
                }
            }
        }
    }

    // Gets All Patients with an LV Code
    async function getLocationScannedData(locationInstance, countSetter) {
        
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);

        const buildQuery = `location=${locationInstance}`;

        let response;
        let errors = 0;
        try {
            response = await axios.get(`${apiUrl}/scannedpatients?${buildQuery}`);
        } catch(err) {
            errors += 1;
        } finally {
            if(errors){
                setScannedPatientError(1);
                setScannedPatientErrorMessage("Error Getting All Scanned Patient Data");
            }
            else{
                if(response.data && response.data.data && response.data.data !== "ERROR"){   
                    let patientInfoArr = [];
                    const appointmentData = response.data.data; 
                    let indexesArr = [];
                    appointmentData.forEach((appointmentInfo) => {

                        const identifier = appointmentInfo.patientId + "=" + appointmentInfo.appointmentDatetime;
                        const time = appointmentInfo.appointmentDatetime.split('T')[1].split("-")[0];
                        const splitDate = appointmentInfo.appointmentDatetime.split('T')[0];
                        const dateandtime = splitDate + "T" + time;
                        const currentDatetime = date.parse(dateandtime, "YYYY-MM-DDTHH:mm:ss");
                        const formatCurrentDatetime = date.format(currentDatetime, 'hh:mm A');

                        if(appointmentInfo.firstName !== undefined && appointmentInfo.lastName !== undefined && appointmentInfo.dob !== undefined &&
                            appointmentInfo.firstName !== "" && appointmentInfo.lastName !== "" && appointmentInfo.dob !== ""){
                                indexesArr.push(0);
                                patientInfoArr.push(createAllData(appointmentInfo.firstName, appointmentInfo.lastName, appointmentInfo.dob, identifier, appointmentInfo.lvCode, formatCurrentDatetime));
                            }
                    });  

                    if(countSetter){
                        setPatientCount(patientInfoArr.length);
                    }

                    setAllScannedPatientStates(allScannedPatientStates =>  [...indexesArr]);
                    setDeleteSampleStates(deleteSampleStates => [...indexesArr]);
                    setDataAll(dataAll => [...patientInfoArr]);
                    setBackupDataAll(backupDataAll => [...patientInfoArr]);
                     
                }
                else{
                    setScannedPatientError(1);
                    setScannedPatientErrorMessage("Error Getting All Scanned Patient Data");
                }
            }
        }
    }

    // Selects the location for LV Code Processing
    async function selectLocation(selectedLocation){

        setSuccess(0);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setLoading(1);
        setScanResults(0);

        const currentDate = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
        const splitDate = currentDate.split(',')[0];
        const parseDate = date.parse(splitDate, "M/D/YYYY");
        const formattedDates = date.format(parseDate, "YYYY-MM-DD");
        setFormattedDate(formattedDates);

        setLocationSelected(1);
        setCurrentLocation(selectedLocation);
        await getLocationScannedData(selectedLocation, 0);
        await getLocationNotScannedData(selectedLocation);
        setLoading(0);
    }

    // Sends User Back to Locations
    function backToLocations() {
        setVerificationGeneralError(0);
        setVerificationSuccess(0);
        setLvCodeDuplicateStatus("Pending");
        setCrossVerifyAcuityStatus("Pending");
        setVerifyHeadersStatus("Pending");
        setVerifyMissingStatus("Pending");
        setVerifyDatesStatus("Pending");
        setVerifyIdsStatus("Pending");
        setLvCodeDuplicateResult("None");
        setCrossVerifyAcuityResult("None");
        setVerifyHeadersResult("None");
        setVerifyDatesResult("None");
        setVerifyMissingResult("None");
        setVerifyIdsResult("None");
        setLvCodeDuplicateView("None");
        setCrossVerifyAcuityView("None");
        setVerifyHeadersView("None");
        setVerifyDatesView("None");
        setVerifyMissingView("None");
        setVerifyIdsView("None");
        setStartVerification(0);
        setSuccess(0);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setPatientCount(0);
        setScanningType(0);
        setSearchCriteria(''); 
        setSearchAllCriteria('');
        setUploadedCurrentLocationCSV(0);
        setUploadedAcuityCSV(0);
        setScanResults(1);
        setLocationSelected(0);
        setCurrentLocation('');
        setBackupData([]);
        setData([]);
        setBackupDataAll([]);
        setDataAll([]);
    }

    function createData(firstname, lastname, dob, patientId, buttons, time) {
        return { firstname, lastname, dob, patientId, buttons, time };
    }

    function searchPatient(value) {
    
        if(value === ''){
            setData(backupData);
        }
        else{
            const values = value.split(' ');
            if(values.length === 1){
                const newRows = backupData.filter(item => item.firstname.toLowerCase().includes(value.toLowerCase()) || item.lastname.toLowerCase().includes(value.toLowerCase()) ||
                    item.dob.includes(value));
                setData(newRows);
            }
            else if(values.length === 2){
                const [val1, val2] = values;
                const newRows = backupData.filter(item => (item.firstname.toLowerCase().includes(val1.toLowerCase()) && item.lastname.toLowerCase().includes(val2.toLowerCase())) ||
                    (item.firstname.toLowerCase().includes(val2.toLowerCase()) && item.lastname.toLowerCase().includes(val1.toLowerCase())));
                setData(newRows);
            }
            
        }
    } 
     
    function searchAllPatient(value) {
    
        if(value === ''){
            setDataAll(backupDataAll);
        }
        else{
            const values = value.split(' ');
            if(values.length === 1){
                console.log(backupDataAll);
                const newRows = backupDataAll.filter(item => item.firstname.toLowerCase().includes(value.toLowerCase()) || item.lastname.toLowerCase().includes(value.toLowerCase()) ||
                    item.dob.includes(value));
                setDataAll(newRows);
            }
            else if(values.length === 2){
                const [val1, val2] = values;
                const newRows = backupDataAll.filter(item => (item.firstname.toLowerCase().includes(val1.toLowerCase()) && item.lastname.toLowerCase().includes(val2.toLowerCase())) ||
                    (item.firstname.toLowerCase().includes(val2.toLowerCase()) && item.lastname.toLowerCase().includes(val1.toLowerCase())));
                setDataAll(newRows);
            }
            
        }
    } 

    function checkInput(currentKey){
        if(currentKey === "Enter"){
            searchPatient(searchCriteria);
        }
    }
     
    function checkAllInput(currentKey){
        if(currentKey === "Enter"){
            searchAllPatient(searchAllCriteria);
        }
    }

    // update this
    async function autoupdateData() {
        if(scanningType !== 2){
            setShowOptionMenu(0);
            setSuccess(0);
            setGeneralError(0);
            setScannedPatientError(0);
            setUnscannedPatientError(0);
            setLoading(1);
            setSearchCriteria('');
            setSearchAllCriteria('');
            setAllScannedPatientStates(allScannedPatientStates => []); 
            setDeleteSampleStates(deleteSampleStates => []);

            if(scanningType === 0){
                setData(data => []);
                setBackupData(backupData => []);
                await getLocationNotScannedData(currentLocation);
            }
            else{
                setDataAll(dataAll => []);
                setBackupDataAll(backupDataAll => []);
                const getCurrentCount = 1;
                await getLocationScannedData(currentLocation, getCurrentCount);
            }
            setLoading(0);
        }
    }

    const renderTable = useMemo(() => {
        console.log("rerender unscanned patients");
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>Time</th>
                        <th style={{paddingBottom: '10px'}}>First Name</th>
                        <th style={{paddingBottom: '10px'}}>Last Name</th>
                        <th style={{paddingBottom: '10px'}}>Date Of Birth</th>
                        <th style={{paddingBottom: '10px'}}>LV Code</th>
                        <th style={{paddingBottom: '10px'}}>Submit</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {data.map((rowItem) => {
                        return(
                            <tr key={rowItem.patientId}>
                                <td>{rowItem.time}</td>
                                <td>{rowItem.firstname}</td>
                                <td>{rowItem.lastname}</td>
                                <td>{rowItem.dob}</td>
                                <td><TextField style={{width: '90%', marginTop: '5px', marginBottom: '5px'}} size="small" id={rowItem.patientId} label="LV #" variant="outlined" /></td>
                                <td><Button size="small" onClick={() => sendSample(rowItem.patientId)} color="primary" variant="outlined">Send</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }, [backupData, data]);
    
    function createAllData(firstname, lastname, dob, patientId, lvcode, time) {
        return { firstname, lastname, dob, patientId, lvcode, time };
    }
     
    function editSample(patientID, index){
        let scannedPatientsArr = allScannedPatientStates;
        scannedPatientsArr[index] = 1;
        setAllScannedPatientStates(allScannedPatientStates => [...scannedPatientsArr]);
    }
     
    function exitSample(patientID, index){
        let scannedPatientsArr = allScannedPatientStates;
        scannedPatientsArr[index] = 0;
        setAllScannedPatientStates(allScannedPatientStates => [...scannedPatientsArr]);
    }
     
    const [deleteSampleStates, setDeleteSampleStates] = useState([]);
    function deleteSample(patientID, index) {
        let deleteSamplesArr = deleteSampleStates;
        deleteSamplesArr[index] = 1;
        setDeleteSampleStates(deleteSampleStates => [...deleteSamplesArr]);
    }
     
    function exitDeleteSample(patientID, index){
        let deleteSamplesArr = deleteSampleStates;
        deleteSamplesArr[index] = 0;
        setDeleteSampleStates(deleteSampleStates => [...deleteSamplesArr]);
    }

    const renderAllScannedPatients = useMemo(() => {
        console.log("rerender scanned patients");
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>Time</th>
                        <th style={{paddingBottom: '10px'}}>First Name</th>
                        <th style={{paddingBottom: '10px'}}>Last Name</th>
                        <th style={{paddingBottom: '10px'}}>Date Of Birth</th>
                        <th style={{paddingBottom: '10px'}}>LV Code</th>
                        <th style={{paddingBottom: '10px'}}>Edit / Remove</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {dataAll.map((rowItem, index) => {
                        return(
                            <tr key={rowItem.patientId}>
                                <td>{rowItem.time}</td>
                                <td>{rowItem.firstname}</td>
                                <td>{rowItem.lastname}</td>
                                <td>{rowItem.dob}</td>
                                <td>{allScannedPatientStates[index]? <TextField style={{width: '90%', marginTop: '5px', marginBottom: '5px'}} size="small" placeholder={rowItem.lvcode} id={rowItem.patientId} label={rowItem.lvcode} variant="outlined" /> : rowItem.lvcode}</td>
                                {allScannedPatientStates[index]? <td><Button size="small" onClick={() => sendAllSample(rowItem.patientId, index)} color="primary" variant="outlined">Send</Button><Button style={{marginLeft: '5px'}} size="small" onClick={() => exitSample(rowItem.patientId, index)} color="secondary" variant="outlined">Close</Button></td> : <td>{deleteSampleStates[index]? <div><Button size="small" onClick={() => removeLVCode(rowItem.patientId, index)} color="secondary" variant="outlined">Remove LV Code?</Button><Button size="small" style={{marginLeft: '5px'}} onClick={() => exitDeleteSample(rowItem.patientId, index)} color="primary" variant="outlined">Exit</Button></div>: <div><Button size="small" onClick={() => editSample(rowItem.patientId, index)} color="primary"><EditIcon /></Button><Button size="small" onClick={() => deleteSample(rowItem.patientId, index)} color="secondary"><CancelIcon /></Button></div>}</td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }, [backupDataAll, dataAll, allScannedPatientStates, deleteSampleStates]);
     
    function resultStatus(rowItem){
        if(rowItem.result === "DETECTED" || rowItem === 'detected'){
            return <td style={{color:'red'}}>DETECTED</td>;
        }
        else if(rowItem.result === "NOTDETECTED" || rowItem.result === 'notdetected'){
            return <td style = {{color:'royalblue'}}>NOT DETECTED</td>;
        }
        else{
            return <td style = {{color:'black'}}>{rowItem.result.toUpperCase()}</td>;
        }
    }

    function buildFileInfo(lvnumber, id, firstname, lastname, dob, result){
        return {lvnumber, id, firstname, lastname, dob, result};
    }

    const [fileData, setFileData] = useState([]);
    const [fileDataOriginal, setFileDataOriginal] = useState([]);
    const renderFileInformation = useMemo(() => {
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>LV Number</th>
                        <th style={{paddingBottom: '10px'}}>ID</th>
                        <th style={{paddingBottom: '10px'}}>First Name</th>
                        <th style={{paddingBottom: '10px'}}>Last Name</th>
                        <th style={{paddingBottom: '10px'}}>Date Of Birth</th>
                        <th style={{paddingBottom: '10px'}}>Status</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {fileData.map((rowItem) => {
                        return(
                            <tr key={rowItem.lvnumber}>
                                <td>{rowItem.lvnumber}</td>
                                <td>{rowItem.id}</td>
                                <td>{rowItem.firstname}</td>
                                <td>{rowItem.lastname}</td>
                                <td>{rowItem.dob}</td>
                                {resultStatus(rowItem)}
                                {/* {rowItem.result === "DETECTED"? <td style={{color: 'red'}}>DETECTED</td> : <td style={{color: 'royalblue'}}>NOT DETECTED</td>} */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }, [fileData]);

    const errorListTable = useMemo(() => {
        return(
            <table style={{width: '100%', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>Requisition #</th>
                        <th style={{paddingBottom: '10px'}}>First Name</th>
                        <th style={{paddingBottom: '10px'}}>Last Name</th>
                        <th style={{paddingBottom: '10px'}}>DOB</th>
                        <th style={{paddingBottom: '10px'}}>Result</th>
                        <th style={{paddingBottom: '10px'}}>Reason</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {resultsErrorList.map(rowItem => {
                        return(
                            <tr key={rowItem.id}>
                                <td>{rowItem.id}</td>
                                <td>{rowItem.firstName}</td>
                                <td>{rowItem.lastName}</td>
                                <td>{rowItem.dob}</td>
                                <td>{rowItem.result}</td>
                                {(rowItem.error === 'The conditional request failed') ? <td>Result Already Sent</td> : <td>{rowItem.reason}</td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    },[resultsErrorList]);

    //unused
    const [sendingResultsErrorsView,setSendingResultsErrorsView] = useState('none');
    function parseErrorListInformation() {
        const errorList = [];
        resultsErrorList.forEach(data => {
            const errorInformation = <Grid item xs={12}><p>First Name: {data.firstName} Last Name: {data.lastName}</p></Grid>
            errorList.push(errorInformation);
        })
        if(errorList !== []){
            const accordionInformation = <Accordion>
            <AccordionSummary 
            expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
            >
                <Typography>View Errors</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {errorList}
            </AccordionDetails>
        </Accordion>;
        setSendingResultsErrorsView(accordionInformation);
        }            
    }

    async function getFile(filename) {

        setGeneralError(0);
        setFileRetrievalSuccessful(1);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setFinishedSendingResult(0);
        setSendingResult(0);
        setResultsError(0);
        setLoading(1);
        setSuccess(0);
        setCurrentFileName(filename);

        let response;
        let errors = 0;
        try{
            response = await axios.get(`${apiUrl}/retrievecorepointfile?fileName=${filename}`);
        } catch(err) {
            errors += 1;
        } finally {
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage("Error Retrieving File");
            }
            else{
                if(response.data && response.data.data && response.data.data !== "ERROR"){
                    const parsedCsv = CSV.parse(response.data.data);
                    let buildFileData = [];
                    parsedCsv.forEach((csvRow, index) => {
                        if(index !== 0){
                            buildFileData.push(buildFileInfo(csvRow[0], csvRow[6], csvRow[2], csvRow[3], csvRow[4], csvRow[10]));
                        }
                    });
                    setFileData(fileData => [...buildFileData]);
                    setFileDataOriginal(fileData => [...parsedCsv]);
                }
                else{
                    setGeneralError(1);
                    setGeneralErrorMessage("Error Retrieving File");
                }
            }
            setLoading(0);
        }
    }
    
    const resultsTable = useMemo(() => {
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>File Name</th>
                        <th style={{paddingBottom: '10px'}}>Select</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {resultsData.map((rowItem, index) => {
                        return(
                            <tr key={rowItem.filename}>
                                <td>{rowItem.filename}</td>
                                <td><Button size="small" onClick={() => getFile(rowItem.filename)} color="primary" variant="outlined">Select File</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }, [resultsData]);
     
    const [resultsArchivedData, setResultsArchivedData] = useState([]);
    const resultsArchivedTable = useMemo(() => {
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>File Name</th>
                        <th style={{paddingBottom: '10px'}}>Select</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {resultsArchivedData.map((rowItem, index) => {
                        return(
                            <tr key={rowItem.filename}>
                                <td>{rowItem.filename}</td>
                                <td><Button size="small" onClick={() => getFile(rowItem.filename)} color="primary" variant="outlined">Select File</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }, [resultsData]);
     
    const [lvCodeDuplicateStatus, setLvCodeDuplicateStatus] = useState("Pending");
    const [crossVerifyAcuityStatus, setCrossVerifyAcuityStatus] = useState("Pending");
    const [verifyHeadersStatus, setVerifyHeadersStatus] = useState("Pending");
    const [verifyDatesStatus, setVerifyDatesStatus] = useState("Pending");
    const [verifyIdsStatus, setVerifyIdsStatus] = useState("Pending");
    const [verifyMissingStatus, setVerifyMissingStatus] = useState("Pending");
    const [lvCodeDuplicateResult, setLvCodeDuplicateResult] = useState("None");
    const [crossVerifyAcuityResult, setCrossVerifyAcuityResult] = useState("None");
    const [verifyHeadersResult, setVerifyHeadersResult] = useState("None");
    const [verifyDatesResult, setVerifyDatesResult] = useState("None");
    const [verifyMissingResult, setVerifyMissingResult] = useState("None");
    const [verifyIdsResult, setVerifyIdsResult] = useState("None");
    const [lvCodeDuplicateView, setLvCodeDuplicateView] = useState("None");
    const [crossVerifyAcuityView, setCrossVerifyAcuityView] = useState("None");
    const [verifyHeadersView, setVerifyHeadersView] = useState("None");
    const [verifyDatesView, setVerifyDatesView] = useState("None");
    const [verifyMissingView, setVerifyMissingView] = useState("None");
    const [verifyIdsView, setVerifyIdsView] = useState("None");
    const verificationTable = useMemo(() => {
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>Verification Test</th>
                        <th style={{paddingBottom: '10px'}}>Status</th>
                        <th style={{paddingBottom: '10px'}}>Results</th>
                        <th style={{paddingBottom: '10px'}}>View</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    <tr key="lvduplicatesid">
                        <td>LV Code Duplicates</td>
                        <td>{lvCodeDuplicateStatus}</td>
                        <td>{lvCodeDuplicateResult}</td>
                        <td>{lvCodeDuplicateView}</td>
                    </tr>
                    <tr key="crossverifyacuity">
                        <td>Cross Verify Acuity</td>
                        <td>{crossVerifyAcuityStatus}</td>
                        <td>{crossVerifyAcuityResult}</td>
                        <td>{crossVerifyAcuityView}</td>
                    </tr>
                    <tr key="headervalues">
                        <td>Verify Headers</td>
                        <td>{verifyHeadersStatus}</td>
                        <td>{verifyHeadersResult}</td>
                        <td>{verifyHeadersView}</td>
                    </tr>
                    <tr key="dateverification">
                        <td>Verify Dates</td>
                        <td>{verifyDatesStatus}</td>
                        <td>{verifyDatesResult}</td>
                        <td>{verifyDatesView}</td>
                    </tr>
                    <tr key="idverification">
                        <td>Verify Ids</td>
                        <td>{verifyIdsStatus}</td>
                        <td>{verifyIdsResult}</td>
                        <td>{verifyIdsView}</td>
                    </tr>
                    <tr key="missingverification">
                        <td>Missing Fields</td>
                        <td>{verifyMissingStatus}</td>
                        <td>{verifyMissingResult}</td>
                        <td>{verifyMissingView}</td>
                    </tr>
                </tbody>
            </table>
        );
    }, [verifyMissingStatus, crossVerifyAcuityResult, crossVerifyAcuityView, verifyMissingResult, verifyHeadersView, verifyMissingView, verifyHeadersResult, verifyHeadersStatus, lvCodeDuplicateStatus, verifyIdsView, lvCodeDuplicateView, verifyDatesStatus, verifyHeadersStatus, crossVerifyAcuityStatus, lvCodeDuplicateResult, verifyDatesResult, verifyIdsResult, verifyIdsStatus, verifyDatesView]);
   
    async function patchLvCode(patientID, patientDatetime, lvcode) {
        
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);

        const patientBody = {
            patientId: patientID,
            appointmentDatetime: patientDatetime,
            lvCode: lvcode
        }

        let response;
        let errors = 0;
        try{
            response = await axios.post(`${apiUrl}/patchlvlcode`, patientBody);
        } catch(error){
            errors += 1;
        } finally {
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage("Failed to submit LV Code");
                return "ERROR";
            }
            else{
                if(response.data && response.data.data && response.data.data !== "ERROR"){
                    setSuccess(1);
                    setSuccessMessage("LV Code Submitted Successfully");
                    return "SUCCESS";
                }
                else{
                    setGeneralError(1);
                    setGeneralErrorMessage("Failed to submit LV Code");
                    return "ERROR";
                }   
            }
        }
    }
     
    async function removeLVCode(sampleNumber, indexed){

        setSuccess(0);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setLoading(1);

        const [patientInnerId, patientInnerDatetime] = sampleNumber.split('=');
        const status = await patchLvCode(patientInnerId, patientInnerDatetime, "none");

        if(status === "SUCCESS"){
            const rowIndex = backupDataAll.filter(backup => backup.patientId !== sampleNumber);
            const getSample = backupDataAll.filter(backup => backup.patientId === sampleNumber);
            console.log("Removing SAMPLE: ", getSample);
            console.log("NEW LV is none");
            
            let scannedPatientsArr = allScannedPatientStates;
            scannedPatientsArr.splice(indexed, 1);
            setAllScannedPatientStates(allScannedPatientStates => [...scannedPatientsArr]);
             
            let deletePatientsArr = deleteSampleStates;
            deletePatientsArr.splice(indexed, 1);
            setDeleteSampleStates(deleteSampleStates => [...deletePatientsArr]);

            let unscannedDataArr = backupData;
            unscannedDataArr.push(createData(getSample[0].firstname, getSample[0].lastname, getSample[0].dob, sampleNumber, sampleNumber, getSample[0].time));
            setBackupData(backupData => [...unscannedDataArr]);
            setData(data => [...unscannedDataArr]);
            setBackupDataAll(backUpDataAll => [...rowIndex]);
            setDataAll(dataAll => [...rowIndex]);
            setPatientCount(rowIndex.length);
        }

        setLoading(0);
    }

    async function sendAllSample(sampleNumber, indexed) {

        setSuccess(0);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setLoading(1);

        let lvValue = document.getElementById(sampleNumber).value;

        if(lvValue !== '' && /^[0-9LV]+$/i.test(lvValue) && lvValue.includes('LV') && lvValue.charAt(0) == "L"){

            const [patientInnerId, patientInnerDatetime] = sampleNumber.split('=');
            const status = await patchLvCode(patientInnerId, patientInnerDatetime, lvValue);
            
            if(status === "SUCCESS"){

                const rowIndex = backupDataAll.filter(backup => backup.patientId !== sampleNumber);
                const getSample = backupDataAll.filter(backup => backup.patientId === sampleNumber);
                console.log("CHANGING SAMPLE: ", getSample);
                console.log("NEW LV: ", lvValue);

                let allDataArr = rowIndex;
                allDataArr.push(createAllData(getSample[0].firstname, getSample[0].lastname, getSample[0].dob, sampleNumber, lvValue, getSample[0].time));
                let scannedPatientsArr = allScannedPatientStates;
                scannedPatientsArr.splice(indexed, 1);
                scannedPatientsArr.push(0);

                setAllScannedPatientStates(allScannedPatientStates => [...scannedPatientsArr]);
                 
                let deletePatientsArr = deleteSampleStates;
                deletePatientsArr.splice(indexed, 1);
                deletePatientsArr.push(0);

                setDeleteSampleStates(deleteSampleStates => [...deletePatientsArr]);

                setBackupDataAll(backupDataAll => [...allDataArr]);
                setDataAll(dataAll => [...allDataArr]);
            }
        }
        else{
            setGeneralError(1);
            setGeneralErrorMessage("Input must be in the form: LVXXXXXXXXX");
        }
        setLoading(0);
    }
     
    async function sendSample(sampleNumber) {

        setSuccess(0);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setLoading(1);

        const lvValue = document.getElementById(sampleNumber).value;
        if(lvValue !== '' && /^[0-9LV]+$/i.test(lvValue) && lvValue.includes('LV') && lvValue.charAt(0) == "L"){

            const [patientInnerId, patientInnerDatetime] = sampleNumber.split('=');
            const status = await patchLvCode(patientInnerId, patientInnerDatetime, lvValue);
            
            if(status === "SUCCESS"){
                const rowIndex = backupData.filter(backup => backup.patientId !== sampleNumber);
                const getSample = backupData.filter(backup => backup.patientId === sampleNumber);
                console.log("ADDED SAMPLE: ", getSample);
                console.log("NEW LV: ", lvValue);
                
                let scannedPatientsArr = allScannedPatientStates;
                scannedPatientsArr.push(0);
                setAllScannedPatientStates(allScannedPatientStates => [...scannedPatientsArr]);
                 
                let deletePatientsArr = deleteSampleStates;
                deletePatientsArr.push(0);
                setDeleteSampleStates(deleteSampleStates => [...deletePatientsArr]);

                let allDataArr = backupDataAll;
                allDataArr.push(createAllData(getSample[0].firstname, getSample[0].lastname, getSample[0].dob, sampleNumber, lvValue, getSample[0].time));

                setBackupDataAll(backupDataAll => [...allDataArr]);
                setDataAll(dataAll => [...allDataArr]);

                setBackupData(backupData => [...rowIndex]);
                setData(data => [...rowIndex]);
                setPatientCount(rowIndex.length);
            }
        }
        else{
            setGeneralError(1);
            setGeneralErrorMessage("Input must be in the form: LVXXXXXXXXX");
        }
        setLoading(0);
    }

    const [showOptionMenu, setShowOptionMenu] = useState(0);
    function displayOptions(event) {

        setSuccess(0);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);

        if(!showOptionMenu){
            handleClick(event);
        }
        setShowOptionMenu(!showOptionMenu);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setShowOptionMenu(0);
        setAnchorEl(null);
    };

    async function sendFileToCorepoint(csvData, csvName) {

        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);

        const csvBody = {
            csvName: csvName,
            csvData: csvData,
        }

        setProgress(progress + 10);

        let response;
        let errors = 0;
        try{
            response = await axios.post(`${apiUrl}/corepointupload`, csvBody);
        } catch(err){
            errors += 1;
        } finally {
            setProgress(progress + 10);
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage("CSV Upload Failed");
            }
            else{
                if(response.data && response.data.data && response.data.data !== "ERROR"){
                    setSuccess(1);
                    setSuccessMessage("Uploaded CSV Successfully");
                }
                else{
                    setGeneralError(1);
                    setGeneralErrorMessage("CSV Upload Failed");
                }
            }
            setProgress(0);
            setUploadingReport(0);
        }
    }

    // will use this to load code onto lambda so its not on a cold start, not sure if it will work well though.
    async function warmUpLambda(){
        const response = await axios.post(`${apiUrl}/email/corepoint`,
        {
            body: ""
        });
        return response;
    }

    function splitToChunk(data){
        let temp = [...data];
        const chunks = [];
        let count = 0;
        const chunkLength = 2;
        let header = temp.shift();
        let tempChunk = header + "\n";

        temp.map(entry => {
            if(count < chunkLength){
                tempChunk+= entry + "\n";
            }
            else{
                chunks.push(tempChunk);
                count = 0;
                tempChunk = header + "\n" + entry + "\n";
            }
            count++;
        });
        if(tempChunk.length !== 0){
            chunks.push(tempChunk);
        }
        return chunks;
    }

    const [finishedSendingResult,setFinishedSendingResult] = useState(0);
    async function sendResultsAutomatic(){

        setFinishedSendingResult(0);
        setSendingResult(1);
        setSuccess(0);
        setProgressBar(0);
        setGeneralError(0);
        setResultsErrorMessage("");
        setResultsError(0);
        // console.log("sending results automatically");
        // console.log(fileDataOriginal);

        const chunks = splitToChunk(fileDataOriginal);
        // for testing
        // let errorList = [
        //     {id:"LV1234",firstName:"test",lastName:"test",dob:"01/10/2022",result: "NEGATIVE",reason:"test reason",error: "test error",acuityResponse: "test response"},
        //     {id:"LV12345",firstName:"tester",lastName:"tester",dob:"01/10/2022",result: "POSITIVE",reason:"another test reason",error: "another test error",acuityResponse: "another test response"}
        // ];
        let errorList = [];
        console.log(chunks);


        let i = 0;
        let response;
        let errors = 0;
        let networkError = 0;
        try{
            response = await warmUpLambda();
            if(response.data.statusCode === 200){
                console.log("Lambda Warmed Up");
            }
            for await(const c of chunks){
                response = await axios.post(`${apiUrl}/email/corepoint`,
                {
                    body: c
                });
                // console.log(response);
                // console.log(response.data);
                if(response.data.body === "Error"){
                    errorList = errorList.concat(response.data.errorList);
                    errors+=1;
                }
                if(response.data.errorType){
                    errors+=1;
                }
                setProgressBar(((i+1)/chunks.length) * 100);
                i++;
            }
            setFinishedSendingResult(1);
        }
        catch(e){
            networkError+=1;
            setFinishedSendingResult(1);
        }
        finally{
            if(networkError){
                setResultsError(1);
                setResultsErrorMessage("Network Error");
            }
            else if(errors){
                setResultsError(1);
                setResultsErrorMessage("Results beolow failed to send");
                setResultsErrorList(errorList);
                console.log(errorList);
            }
            else{
                setSuccess(1);
                setSuccessMessage("No errors sending emails");
            }
        }

    }

    function retrieveCsvData(csvData){

        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setGeneralErrorMessage("");
        setUploadingReport(1);
        setProgress(progress => progress + 30);
        csvDataRef.current.value = "";

        let csvString;
        var reader = new FileReader();
        reader.addEventListener('load', function (e) {  
            csvString = e.target.result;
            setProgress(progress => progress + 20);
            sendFileToCorepoint(csvString, csvData.name);
        });
        
        // reader.readAsBinaryString(csvData);
        reader.readAsText(csvData);
    }
     
    async function retrieveSftpFileInformation() {

        setLoadingFile(1);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);

        let response;
        let errors = 0;
        try{
            response = await axios.get(`${apiUrl}/corepointfiles`);
        } catch(err) {
            errors += 1;
        } finally {
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage("Failed to load corepoint directory");
                return "ERROR";
            }
            else{
                if(response.data && response.data.data && response.data.data !== "ERROR"){
                    return response.data.data;
                }
                else{
                    setGeneralError(1);
                    setGeneralErrorMessage("Failed to load corepoint directory");
                    return "ERROR";
                }
            }
        }
    }
     
    function formatFiles(filename){
        return {filename};
    }
     
    function criteriaChanged(value) {
        setSearchCriteria(value);
        searchPatient(value);
    }
     
    function criteriaAllChanged(value) {
        setSearchAllCriteria(value);
        searchAllPatient(value);
    }
     
    function buildDirectoryInfo(filename){
        return {filename};
    }
     
    async function selectSendResults() {

        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSuccess(0);
        setSendResults(1);
        setLoading(1);
         
        let errors = 0;
        let directoryInformation;
        try{
            directoryInformation = await retrieveSftpFileInformation();
        } catch(err) {
            errors += 1;
        } finally {
            if(errors){
                setGeneralError(1);
            }
            else{
                if(directoryInformation !== "ERROR"){
                    directoryInformation.sort();
                    directoryInformation.reverse();
                    let buildFiles = [];
                    directoryInformation.forEach((fileInDir) => {
                        buildFiles.push(buildDirectoryInfo(fileInDir));
                    });
                    setResultsData(resultsData => [...buildFiles]);
                    setFileRetrievalSuccessful(1);
                    setFileRetrievalSuccessful(0);
                    setLoadingFile(0);
                }
                else{
                    setGeneralError(1);
                }
            }
            setLoading(0);
        }
    }
     
    const currentLocationRef = useRef();
    const acuityCsvRef = useRef();
    function uploadAcuityCSV() {
        acuityCsvRef.current.click();
    }
     
    function uploadCurrentLocationCSV() {
        currentLocationRef.current.click();
    }
     
    const [uploadedCurrentLocationCSV, setUploadedCurrentLocationCSV] = useState(0);
    const [currentLocationCSVname, setCurrentLocationCSVname] = useState('');
    const [currentLocationData, setCurrentLocationData] = useState('');
    function retrieveCurrentLocationData(csvData){

        setLoading(1);
        setCurrentLocationCSVname(csvData.name);
        currentLocationRef.current.value = "";

        let csvString;
        var reader = new FileReader();
        reader.addEventListener('load', function (e) {  
            csvString = e.target.result; 
            setCurrentLocationData(csvString);
            setUploadedCurrentLocationCSV(1);
            setLoading(0);
        });
        
        reader.readAsBinaryString(csvData);
        
    }
     
    function checkDuplicatesIds() {
        
        let set = {};
        let dup = [];

        let errors = 0; 
        const datas = CSV.parse(currentLocationData);
        let patient_id_index;

        datas[0].forEach((head,i) => {
            if(head === "patient_id"){
                patient_id_index = i;
            }
        });

        datas.shift();

        try{
            datas.forEach(row => {
                if(!set[row[patient_id_index]]){
                    set[row[patient_id_index]] = row;
                }
                else{
                    dup.push(row);
                }
            });
        }
        catch(e){
            console.log(e);
            errors += 1;
        }
        
        if(errors){
            setLvCodeDuplicateResult("Test Failed");
            return "none";
        }   
        else{
            if(dup.length === 0){
                setVerifyIdsResult("No Duplicates");
                return "none";
            }
            else{
                const message = `Found ${dup.length} Duplicate(s)`;
                setVerifyIdsResult(message);
                return dup;
            }
        }
    }
     
    function checkDuplicatesLVcodes() {

        let set = {};
        let dup = [];

        let errors = 0; 
        const datas = CSV.parse(currentLocationData);
        let order_id_index;

        datas[0].forEach((head,i) => {
            if(head === "order_id"){
                order_id_index = i;
            }
        });

        datas.shift();
        try{
            datas.forEach(row => {
                if(!set[row[order_id_index]]){
                    set[row[order_id_index]] = row;
                }
                else{
                    dup.push(row);
                }
            });
        }
        catch(e){
            console.log(e);
            errors += 1;
        }
        
        if(errors){
            setLvCodeDuplicateResult("Test Failed");
            return "none";
        }   
        else{
            if(dup.length === 0){
                setLvCodeDuplicateResult("No Duplicates");
                return "none";
            }
            else{
                const message = `Found ${dup.length} Duplicate(s)`;
                setLvCodeDuplicateResult(message);
                return dup;
            }
        }
    }
     
    function verifyMissingFields() {

        let set = {};
        let dup = [];
        let missing = [];
        let incorrect = [];
        let indexes = [];

        let AVAILABLE_INPUTS = {
            Laboratory_Name: ["Reopen Diagnostics LLC"],
            facility_org_name: ["ClearMD"],
            facility_location_code: ["ClearMD Business"],
            patient_gender: ["Unknown"],
            // patient_address_city: ["New York"],
            // patient_address: ["600 6th Avenue","2007 Broadway","734 Broadway","187 Centre Street","1051 Third Avenue","31-78 Steinway Street","1157 Madison Ave"],
            // patient_address_state: ["NY"],
            // patient_phone_number:  ["18446325327"],
            test_name: ["COVID-19 ONLY"],
            provider_first_name: ["ALI"],
            provider_last_name: ["RASHAN"],
            provider_NPI: ["1841671476"],
            media: ["DNA/RNA Shield"],
            collection_sites: ["Nasopharyngeal"]
        }

        let REQUIRED_HEADERS = [
            "Laboratory_Name",
            "facility_org_name",
            "facility_location_code",
            "patient_id",
            "patient_first_name",
            "patient_last_name",
            "patient_gender",
            "patient_birthdate",
            "patient_address",
            "patient_address_city",
            "patient_address_state",
            "patient_address_zip",
            "patient_phone_number",
            "order_id",
            "test_name",
            "order_date",
            "provider_first_name",
            "provider_last_name",
            "provider_NPI",
            "media",
            "collection_sites"
        ];

        const check = (row) => {
            let missing = "";
            let incorrect = "";
            row.forEach((element,i) => {
                if(REQUIRED_HEADERS.includes(indexes[i].head) && element === ""){
                    incorrect += "" + indexes[i].head + ",";
                }
                if(AVAILABLE_INPUTS[indexes[i].head] != null && !AVAILABLE_INPUTS[indexes[i].head].includes(element)){
                    incorrect += "" + indexes[i].head + ", ";
                }
            });

            return {
                missing,
                incorrect
            };
        }

        const datas = CSV.parse(currentLocationData);

        let order_id_index;
        datas[0].forEach((head,i) => {
            indexes.push({head,i});
            if(head === "order_id"){
                order_id_index = i;
            }
        });
        datas.shift();
         
        let errors = 0;

        try{
            datas.forEach((row,i) => {
                let response = check(row);
                if(response.missing !== ""){
                    missing.push({ [i]: response.missing});
                }
                if(response.incorrect !== ""){
                    let incorrectResponse = response.incorrect.substring(0, response.incorrect.length - 2);
                    incorrect.push({[i + 2]: incorrectResponse});
                }
                if(!set[row[order_id_index]]){
                    set[row[order_id_index]] = row;
                }
                else{
                    dup.push(row);
                }
            });
        }
        catch(e){
            console.log(e);
            errors += 1;
        }
         
        if(errors){
            setVerifyMissingResult("Test Failed");
            return "none";
        }   
        else{
            if(incorrect.length === 0){
                setVerifyMissingResult("No Missing Fields");
                return "none";
            }
            else{
                const message = `Found ${incorrect.length} Missing Fields(s)`;
                setVerifyMissingResult(message);
                return incorrect;
            }
        }

    }
     
    function dateVerifier(dateStr, formatDateToYear) {
            
        let dateErrors = 0;
        const dateRegexExp = /^[0-9]{1,2}[\/][0-9]{1,2}[\/][0-9]{4}$/g;

        if(dateRegexExp.test(dateStr)){
            const dateSplit = dateStr.split('/');
            const monthDate = Number(dateSplit[0]);
            const dayDate = Number(dateSplit[1]);
            const yearDate = Number(dateSplit[2]);
             
            if(monthDate < 1 || monthDate > 12){
                dateErrors += 1;
            }
            if(dayDate < 1 || dayDate > 31){
                dateErrors += 1;
            }
            if(yearDate < 1910 || yearDate > formatDateToYear){
                dateErrors += 1;
            }
             
            if(dateErrors){
                return "ERROR";
            }
            else{
                return "SUCCESS";
            }
        }
        else{
            return "ERROR";
        }
    }
     
    function checkDateErrors(formatDateToYear) {

        const parsedCsv = CSV.parse(currentLocationData);
        let date_header_index;
        parsedCsv[0].forEach((head,i) => {
            if(head === "patient_birthdate"){
                date_header_index = i;
            }
        });
        
        let collectBadDates = [];
        let dateErrorCount = 0;
        parsedCsv.forEach((currentLocationItem, index) => {
            if(index > 0){
                const nextDate = currentLocationItem[date_header_index];
                const statusOfDate = dateVerifier(nextDate, formatDateToYear);
                if(statusOfDate === "ERROR"){
                    collectBadDates.push(currentLocationItem);
                    dateErrorCount += 1;
                }
            }
        });
         
        if(dateErrorCount){
            const message = `Found ${dateErrorCount} Date Error(s)`;
            setVerifyDatesResult(message);
            return collectBadDates;
        }
        else{
            setVerifyDatesResult("No Errors");
            return "none";
        }
    }
     
    function headerVerification(){

        const REQUIRED_HEADERS = [
            "Laboratory_Name",
            "facility_org_name",
            "facility_location_code",
            "patient_id",
            "patient_first_name",
            "patient_last_name",
            "patient_gender",
            "patient_birthdate",
            "patient_address",
            "patient_address_city",
            "patient_address_state",
            "patient_address_zip",
            "patient_phone_number",
            "barcode",
            "first_test",
            "order_id",
            "employed_in_healthcare",
            "symptomatic",
            "symptomatic_date",
            "icu",
            "congregate_care_resident",
            "pregnant",
            "hospitalized",
            "test_name",
            "order_date",
            "provider_first_name",
            "provider_last_name",
            "provider_NPI",
            "media",
            "collection_sites",
            "occupation",
            "employer_name",
            "employer_work_address",
            "employer_phone_number"
        ];                         

        let errors = 0;
        let missingHeaders = [];
        let extraHeaders = [];

        const datas = CSV.parse(currentLocationData);
        let buildCSVheaders = [];

        datas[0].forEach((dataHeader) => {
            const currentHeader = dataHeader.replace(/[^a-z0-9_]+/gi, "");
            console.log(currentHeader);
            buildCSVheaders.push(currentHeader);
        });
         
        REQUIRED_HEADERS.forEach((requiredHeader) => {
            if(!(buildCSVheaders.includes(requiredHeader))){
                missingHeaders.push(requiredHeader);
                errors += 1;
            }
        });
         
        if(errors){
            let message = `${errors} Missing Header(s)`;
            setVerifyHeadersResult(message);
            return missingHeaders;
        }
        else{
            buildCSVheaders.forEach((headItem) => {
                if(!(REQUIRED_HEADERS.includes(headItem))){
                    errors += 1;
                    extraHeaders.push(headItem);
                }
            });
             
            if(errors){
                let message = `${errors} extra header(s)`;
                setVerifyHeadersResult(message);
                return extraHeaders;
            }
            else{
                if(buildCSVheaders.length !== 34){
                    let message = "Incorrect Header Length";
                    setVerifyHeadersResult(message);
                    return "none";
                }
                else{
                    setVerifyHeadersResult("No Errors");
                    return "none";
                }
            }
        }
    }
     
    function cleanDate(dateStr) {
        if (dateStr === undefined) {
          return {
            date: dateStr,
            dateError: "Date undefined for some reason",
          };
        }
        let date = new Date(dateStr);
        if (date instanceof Date && !isNaN(date)) {
          return {
            date: date.toLocaleString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }),
            dateError: "",
          };
        }
      
        /* a number of bad dates include an 'O' instead of a '0' */
        date = new Date(dateStr.replace(/[A-Za-z]/g, ""));
      
        if (date instanceof Date && !isNaN(date)) {
          return {
            date: date.toLocaleString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }),
            dateError: "",
          };
        }
      
        let dateDigitStr = dateStr.replace(/\D/g, "");
      
        if (dateDigitStr.length < 4) {
          return {
            date: dateStr,
            dateError: "Date too short",
          };
        }
      
        if (dateDigitStr.length > 8) {
          return {
            date: dateStr,
            dateError: "Date too long",
          };
        }
      
        /* Currently I'm not bothering with 7 digits supplied. Reason being if we see something like 1211994 it can be either
         * 12/1/1994 or 1/21/1994 and there is no default case that makes sense. Some 7 digits are unambiguous but 7 digit
         * dates appear to be rare enough that we can manually correct the leftovers.
         */
        if (dateDigitStr.length === 8) {
          /* by default assume mm/dd/yyyy,
           * if it was actually dd/mm/yyyy but a valid mm/dd/yyyy date, then theres no way to tell and there's no point
           * trying to guess so no error is returned
           */
          date = new Date(
            dateDigitStr.substring(0, 2) +
              "/" +
              dateDigitStr.substring(2, 4) +
              "/" +
              dateDigitStr.substring(4, 8)
          );
          if (date instanceof Date && !isNaN(date)) {
            return {
              date: date.toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }),
              dateError: "",
            };
          }
      
          date = new Date(
            dateDigitStr.substring(2, 4) +
              "/" +
              dateDigitStr.substring(0, 2) +
              "/" +
              dateDigitStr.substring(4, 8)
          );
          if (date instanceof Date && !isNaN(date)) {
            return {
              date: date.toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }),
              dateError: "",
            };
          }
      
          /* finally try yyyy/mm/dd */
          date = new Date(
            dateDigitStr.substring(4, 8) +
              "/" +
              dateDigitStr.substring(2, 4) +
              "/" +
              dateDigitStr.substring(0, 2)
          );
          if (date instanceof Date && !isNaN(date)) {
            return {
              date: date.toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }),
              dateError: "",
            };
          }
      
          return {
            date: dateStr,
            dateError:
              "8 digit date supplied does not match any known date format",
          };
        }
      
        return {
          date: dateStr,
          dateError: "Date is invalid and/or too ambiguous",
        };
    }
     
    function crossReferenceDataWithAcuity() {


        let acuityFirstNameIndex;
        let acuityLastNameIndex;
        let acuityDobIndex;
        let acuityCalendarIndex;
        let acuityTestTypeIndex;
        let acuityLabelIndex;
        let dataFirstNameIndex;
        let dataLastNameIndex;
        let dataDobIndex;

        const parsedCsv = CSV.parse(currentLocationData);
        const acuityCsv = CSV.parse(acuityData);

        parsedCsv[0].forEach((currentLocationHeader, index) => {
            if(currentLocationHeader === "patient_first_name"){
                dataFirstNameIndex = index;
            }
            else if(currentLocationHeader === "patient_last_name"){
                dataLastNameIndex = index;
            }
            else if(currentLocationHeader === "patient_birthdate"){
                dataDobIndex = index;
            }
        });
         
        acuityCsv[0].forEach((currentLocationHeader, index) => {
            if(currentLocationHeader.includes("First Name") || currentLocationHeader.includes("First")){
                acuityFirstNameIndex = index;
            }
            else if(currentLocationHeader.includes("Last Name") || currentLocationHeader.includes("Last")){
                acuityLastNameIndex = index;
            }
            else if(currentLocationHeader.includes("Date of Birth") || currentLocationHeader.includes("DOB")){
                acuityDobIndex = index;
            }
            else if(currentLocationHeader.includes("Calendar")){
                acuityCalendarIndex = index;
            }
            else if(currentLocationHeader.includes("Type")){
                acuityTestTypeIndex = index;
            }
            else if(currentLocationHeader.includes("Label")){
                acuityLabelIndex = index;
            }
        });
         
        let missingPatients = [];
        let appDataDictionary = {};
         
        parsedCsv.forEach((dataRow) => {
            const firstName = dataRow[dataFirstNameIndex].toLowerCase();
            const lastName = dataRow[dataLastNameIndex].toLowerCase();
            const dateOfBirth = dataRow[dataDobIndex].toLowerCase();

            if(lastName in appDataDictionary){
                if(firstName in appDataDictionary[lastName]){
                    appDataDictionary[lastName][firstName].push(dateOfBirth);
                }
                else{
                    appDataDictionary[lastName][firstName] = [dateOfBirth];
                }
            }
            else{
                appDataDictionary[lastName] = {[firstName]: [dateOfBirth]};
            }
        });
         
        const acuityFilterLocation = LOCATION_CODES[currentLocation].toLowerCase();
        const acuityFilterLocationAlt = currentLocation.toLowerCase();
        acuityCsv.forEach((acuityRow, index) => {
            if(index !== 0){
                const firstName = acuityRow[acuityFirstNameIndex].toLowerCase();
                const lastName = acuityRow[acuityLastNameIndex].toLowerCase();
                
                let dateOfBirth = acuityRow[acuityDobIndex];
                if(!(firstName === "" || lastName === "" || dateOfBirth === "")){
                    const newDateOfBirth = cleanDate(dateOfBirth).date;
                    dateOfBirth = newDateOfBirth;
                    const calLocation = acuityRow[acuityCalendarIndex].toLowerCase();
                    const testType = acuityRow[acuityTestTypeIndex].toLowerCase();
                    let currentLabel = acuityRow[acuityLabelIndex].toLowerCase();
                    
                    if((calLocation.includes(acuityFilterLocation) || calLocation.includes(acuityFilterLocationAlt)) &&
                        (testType.includes("48 hour") || testType.includes("next day")) && (currentLabel !== "no show")){
                            if(currentLabel === ""){
                                currentLabel = "No Label";
                            }
                            if(lastName in appDataDictionary){
                                if(firstName in appDataDictionary[lastName]){
                                    if(!(appDataDictionary[lastName][firstName].includes(dateOfBirth))){
                                        missingPatients.push({"FirstName": firstName, "LastName": lastName, "Dob": dateOfBirth, "Test Type": testType, "Label": currentLabel});
                                    }
                                }
                                else{
                                    missingPatients.push({"FirstName": firstName, "LastName": lastName, "Dob": dateOfBirth, "Test Type": testType, "Label": currentLabel});
                                }
                            }
                            else{
                                missingPatients.push({"FirstName": firstName, "LastName": lastName, "Dob": dateOfBirth, "Test Type": testType, "Label": currentLabel});
                            }
                    }
                }
            }
        });
         
        if(missingPatients.length !== 0){
            let message = `${missingPatients.length} Missing Patients(s)`;
            setCrossVerifyAcuityResult(message);
            return missingPatients;
        }
        else{
            setCrossVerifyAcuityResult("No Missing Patients");
            return "none";
        }
    }
     
    function backToCorepointFiles() {
        setFileData(fileData => []);
        setFileRetrievalSuccessful(0);
        setCurrentFileName('');
    }

    const [startVerification, setStartVerification] = useState(0);
    const [verificationGeneralError, setVerificationGeneralError] = useState(0);
    const [verificationSuccess, setVerificationSuccess] = useState(0);
    const [verificationErrorMessage, setVerificationErrorMessage] = useState(0);
    const [verificationSuccessMessage, setVerificationSuccessMessage] = useState(0);
    function beginVerification() {

        setStartVerification(1);
        const currentDateOpt = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
        const splitDate = currentDateOpt.split(',')[0];
        const parseDate = date.parse(splitDate, "M/D/YYYY");
        const formattedDates = date.format(parseDate, "YYYY");
        const formatDateToYear = Number(formattedDates);
        let countErrors = 0;

        setLoading(1);
        const returnedDuplicates = checkDuplicatesLVcodes();
        if(returnedDuplicates !== "none"){
            countErrors += 1;
            let duplicatePatients = [];
            returnedDuplicates.forEach((dupValue) => {
                const patientInformation = <Grid item xs={12} style={{fontSize: '.9em'}}><p style={{marginBottom: '0'}}><b>LV Code:</b> {dupValue[13]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>First Name:</b> {dupValue[4]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Last Name:</b> {dupValue[5]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Dob:</b> {dupValue[7]}</p><hr style={{height: '1px', color: 'white'}} /></Grid>;
                duplicatePatients.push(patientInformation);
            });
            const accordionInformation = <Accordion  style={{boxShadow: 'none', marginTop: '8px', marginBottom: '8px', paddingTop: '0', paddingBottom: '0', backgroundColor: 'royalblue', color: 'white'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>View Duplicates</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {duplicatePatients}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            setLvCodeDuplicateView(accordionInformation);
        }
        setLvCodeDuplicateStatus("Complete");
        const returnedDates = checkDateErrors(formatDateToYear);
        if(returnedDates !== "none"){
            countErrors += 1;
            let badDates = [];
            returnedDates.forEach((dateValue) => {
                const patientInformation = <Grid item xs={12} style={{fontSize: '.9em'}}><p style={{marginBottom: '0'}}><b>LV Code:</b> {dateValue[13]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>First Name:</b> {dateValue[4]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Last Name:</b> {dateValue[5]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Dob:</b> {dateValue[7]}</p><hr style={{height: '1px', color: 'white'}} /></Grid>;
                badDates.push(patientInformation);
            });
            const accordionInformation = <Accordion  style={{boxShadow: 'none', marginTop: '8px', marginBottom: '8px', paddingTop: '0', paddingBottom: '0', backgroundColor: 'royalblue', color: 'white'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>View Dates</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {badDates}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            setVerifyDatesView(accordionInformation);
        }
        setVerifyDatesStatus("Complete");
        const returnedIds = checkDuplicatesIds();
        if(returnedIds !== "none"){
            countErrors += 1;
            let duplicateIds = [];
            returnedIds.forEach((dupValue) => {
                const patientInformation = <Grid item xs={12} style={{fontSize: '.9em'}}><p style={{marginBottom: '0'}}><b>ID:</b> {dupValue[3]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>First Name:</b> {dupValue[4]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Last Name:</b> {dupValue[5]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Dob:</b> {dupValue[7]}</p><hr style={{height: '1px', color: 'white'}} /></Grid>;
                duplicateIds.push(patientInformation);
            });
            const accordionInformation = <Accordion style={{boxShadow: 'none', marginTop: '8px', marginBottom: '8px', paddingTop: '0', paddingBottom: '0', backgroundColor: 'royalblue', color: 'white'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>View Ids</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {duplicateIds}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            setVerifyIdsView(accordionInformation);
        }
        setVerifyIdsStatus("Complete");
        const returnedFields = verifyMissingFields();
        if(returnedFields !== "none"){
            countErrors += 1;
            let missingFields = [];
            returnedFields.forEach((dupValue) => {
                Object.entries(dupValue).forEach(([key, value]) => {
                    const patientInformation = <Grid item xs={12} style={{fontSize: '.9em'}}><p style={{marginBottom: '0'}}><b>Row:</b> {key}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Missing Field(s):</b> {value}</p><hr style={{height: '1px', color: 'white'}} /></Grid>;
                    missingFields.push(patientInformation);
                });
            });
            const accordionInformation = <Accordion style={{boxShadow: 'none', marginTop: '8px', marginBottom: '8px', paddingTop: '0', paddingBottom: '0', backgroundColor: 'royalblue', color: 'white'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>View Missing Fields</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {missingFields}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            setVerifyMissingView(accordionInformation);
        }
        setVerifyMissingStatus("Complete");
        const returnHeaderVerification = headerVerification();
        if(!(returnHeaderVerification === "none")){
            countErrors += 1;
            let missingHeaders = [];
            returnHeaderVerification.forEach((dupValue) => {
                const patientInformation = <Grid item xs={12} style={{fontSize: '.9em'}}><p style={{marginBottom: '0'}}><b>Header:</b> {dupValue}</p><hr style={{height: '1px', color: 'white'}} /></Grid>;
                missingHeaders.push(patientInformation);
            });
            const accordionInformation = <Accordion style={{boxShadow: 'none', marginTop: '8px', marginBottom: '8px', paddingTop: '0', paddingBottom: '0', backgroundColor: 'royalblue', color: 'white'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>View Headers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {missingHeaders}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            setVerifyHeadersView(accordionInformation);
        }
        setVerifyHeadersStatus("Complete");
        const returnCrossRefAcuityData = crossReferenceDataWithAcuity();
        if(!(returnCrossRefAcuityData === "none")){
            countErrors += 1;
            let missingPatients = [];
            returnCrossRefAcuityData.forEach((dupValue) => {
                const patientInformation = <Grid item xs={12} style={{fontSize: '.9em'}}><p style={{marginBottom: '0'}}><b>First Name:</b> {dupValue["FirstName"]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Last Name:</b> {dupValue["LastName"]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>DOB:</b> {dupValue["Dob"]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Test Type:</b> {dupValue["Test Type"]}</p><p style={{marginTop: '0', marginBottom: '0'}}><b>Label:</b> {dupValue["Label"]}</p><hr style={{height: '1px', color: 'white'}} /></Grid>;
                missingPatients.push(patientInformation);
            });
            const accordionInformation = <Accordion style={{boxShadow: 'none', marginTop: '8px', marginBottom: '8px', paddingTop: '0', paddingBottom: '0', backgroundColor: 'royalblue', color: 'white'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography>View Headers</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        {missingPatients}
                    </Typography>
                    </AccordionDetails>
                </Accordion>
            setCrossVerifyAcuityView(accordionInformation);
        }
        setCrossVerifyAcuityStatus("Complete");
        if(countErrors){
            setVerificationGeneralError(1);
            setVerificationSuccess(0);
            setVerificationErrorMessage("Please Correct your CSV with the Suggestions Below");
        }
        else{
            setVerificationGeneralError(0);
            setVerificationSuccess(1);
            setVerificationSuccessMessage("CSV is Ready for Upload!");
        }
        setLoading(0);
    }
     
    const [uploadedAcuityCSV, setUploadedAcuityCSV] = useState(0);
    const [acuityCSVname, setAcuityCSVname] = useState('');
    const [acuityData, setAcuityData] = useState('');
    function retrieveAcuityCsvData(csvData){

        setLoading(1);
        setAcuityCSVname(csvData.name);
        acuityCsvRef.current.value = "";

        let csvString;
        var reader = new FileReader();
        reader.addEventListener('load', function (e) {  
            csvString = e.target.result; 
            setUploadedAcuityCSV(1);
            setAcuityData(csvString);
            setLoading(0);
        });
        
        reader.readAsBinaryString(csvData); 
    }

    function doneViewingResultErrors(){
        setSendingResult(0);
        setResultsError(0);
        setResultsErrorList([]);
        setSuccess(0);
    }
     
    function resetVerification(){
        setVerificationGeneralError(0);
        setVerificationSuccess(0);
        setLvCodeDuplicateStatus("Pending");
        setCrossVerifyAcuityStatus("Pending");
        setVerifyHeadersStatus("Pending");
        setVerifyDatesStatus("Pending");
        setVerifyIdsStatus("Pending");
        setVerifyMissingStatus("Pending");
        setLvCodeDuplicateResult("None");
        setCrossVerifyAcuityResult("None");
        setVerifyHeadersResult("None");
        setVerifyDatesResult("None");
        setVerifyMissingResult("None");
        setVerifyIdsResult("None");
        setLvCodeDuplicateView("None");
        setCrossVerifyAcuityView("None");
        setVerifyHeadersView("None");
        setVerifyMissingView("None");
        setVerifyDatesView("None");
        setVerifyIdsView("None");
        setUploadedCurrentLocationCSV(0);
        setUploadedAcuityCSV(0);
        setStartVerification(0);
    }
    
    function homePage() {
        setFileData(fileData => []);
        setVerificationGeneralError(0);
        setVerificationSuccess(0);
        setLvCodeDuplicateStatus("Pending");
        setCrossVerifyAcuityStatus("Pending");
        setVerifyHeadersStatus("Pending");
        setVerifyDatesStatus("Pending");
        setVerifyIdsStatus("Pending");
        setVerifyMissingStatus("Pending");
        setLvCodeDuplicateResult("None");
        setCrossVerifyAcuityResult("None");
        setVerifyHeadersResult("None");
        setVerifyDatesResult("None");
        setVerifyMissingResult("None");
        setVerifyIdsResult("None");
        setLvCodeDuplicateView("None");
        setCrossVerifyAcuityView("None");
        setVerifyHeadersView("None");
        setVerifyMissingView("None");
        setVerifyDatesView("None");
        setVerifyIdsView("None");
        setUploadedCurrentLocationCSV(0);
        setUploadedAcuityCSV(0);
        setStartVerification(0);
        setScanningType(0);
        setScanResults(0);
        setSendResults(0);
        setSearchCriteria('');
        setSearchAllCriteria('');
        setSuccess(0);
        setGeneralError(0);
        setScannedPatientError(0);
        setUnscannedPatientError(0);
        setSendResults(0);
        setPatientCount(0);
        setLocationSelected(0);
        setCurrentLocation('');
        setBackupData([]);
        setData([]);
        setBackupDataAll([]);
        setDataAll([]);
    }

    return(
        <Grid container>
            {scanResults? 
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                        <Button onClick={homePage} color="primary" style={{backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                            Home
                        </Button>
                    </Grid>
                    <h1 style={{color: 'royalblue', marginBottom: '75px', marginTop: '95px'}}>Select Location</h1>
                    <Grid container className={classes.locationButtonsContainer}>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Noho")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Noho
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Chelsea")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Chelsea
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Midtown")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Midtown
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Upper East Side")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Upper East Side
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Upper West Side")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Upper West Side
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Astoria")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Astoria
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Chinatown")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Chinatown
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("concierge")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Concierge
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => selectLocation("Mobile")} color="primary" style={{width: '100%', marginBottom: '5px'}} variant="outlined" size="medium">
                                Mobile
                            </Button>
                        </Grid>
                    </Grid>
            </Grid> :
            <Grid container>
                {locationSelected? <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Button onClick={displayOptions} color="primary" style={{backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none', float: 'left', boxShadow: 'none', marginBottom: '5px'}} variant="contained" size="medium">
                                Options
                                <KeyboardArrowDownIcon />
                            </Button>
                            {showOptionMenu? <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                >
                                <MenuItem onClick={exportFile} sx={{"&:hover": {backgroundColor: '#EDEEF7', color: 'royalblue'}, paddingTop: '10px', paddingBottom: '10px'}}><FileDownloadIcon style={{marginRight: '10px'}} /> Export Data</MenuItem>
                                <MenuItem onClick={autoupdateData} sx={{"&:hover": {backgroundColor: '#EDEEF7', color: 'royalblue'}, paddingTop: '10px', paddingBottom: '10px'}}><HistoryIcon style={{marginRight: '10px'}} /> Refresh Data</MenuItem>
                                <MenuItem onClick={uploadReport} sx={{"&:hover": {backgroundColor: '#EDEEF7', color: 'royalblue'}, paddingTop: '10px', paddingBottom: '10px'}}><CloudUploadIcon style={{marginRight: '10px'}} /> Upload CSV</MenuItem>
                                <MenuItem onClick={(event) => displayOptions(event)} sx={{"&:hover": {backgroundColor: '#EDEEF7', color: 'royalblue'}, paddingTop: '10px', paddingBottom: '10px'}}><CloseIcon style={{marginRight: '10px'}} /> Close</MenuItem>
                            </Menu> : 
                            ""}
                        </Grid>
                        <Grid item xs={10}>
                            <Button onClick={backToLocations} color="primary" style={{backgroundColor: '#EDEEF7',borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                                Back to Locations
                            </Button>
                            <Button onClick={homePage} color="primary" style={{marginRight: '7px',backgroundColor: '#EDEEF7', borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                                Home
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '80px'}}>
                        {uploadingReport? <Grid item xs={12}>
                            <h2 style={{color: 'royalblue', marginBottom: '35px'}}>Uploading CSV</h2> 
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress variant="determinate" value={progress} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption" component="div" color="text.secondary">
                                        {`${progress}%`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>:
                            <h1 style={{color: 'royalblue', marginBottom: '75px'}}>{currentLocation} Location</h1> 
                        }
                    </Grid>
                    {generalError? <Grid item className={classes.tableMargins} style={{paddingBottom: '15px'}} xs={12}><Alert style={{width: '100%'}} severity="error" variant="outlined">{generalErrorMessage}</Alert></Grid>: ""}
                    {success? <Grid item className={classes.tableMargins} style={{paddingBottom: '15px'}} xs={12}><Alert style={{width: '100%'}} severity="success" variant="outlined">{successMessage}</Alert></Grid>: ""}
                    {loading? 
                        <Grid item className={classes.tableMargins} xs={12}><LinearProgress style={{width: '100%', marginBottom: '10px'}} /> </Grid>: ""
                    }
                    {loading? "" :<Grid item xs={12} className={classes.tableMargins}>
                        <Tabs value={scanningType} onChange={handleScanningChange} centered>
                            <Tab label="Unscanned Patients" />
                            <Tab label="All Scanned" />
                            <Tab label="Verification" />
                        </Tabs>
                    </Grid>}
                    {scanningType === 2? 
                    "": <Grid container>
                    <Grid className={classes.tableMargins} item xs={12}>
                        <h3 style={{color: 'royalblue', textAlign: 'left'}}>Total Patients: <b style={{fontWeight: '800'}}>{patientCount}</b></h3>
                    </Grid>
                    {scanningType === 0?
                        <Grid item xs={12} className={classes.tableMargins} style={{borderTopRightRadius: '8px',borderTopLeftRadius: '8px',marginBottom: '0', padding: '14px 20px', backgroundColor: 'royalblue'}}>
                            {unscannedPatientError? <Grid item className={classes.tableMargins} style={{paddingBottom: '15px'}} xs={12}><Alert style={{width: '100%'}} severity="error" variant="outlined">{unscannedPatientErrorMessage}</Alert></Grid>: ""}
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Button style={{pointerEvents: 'none', backgroundColor: 'transparent', marginRight: '-65px', marginBottom: '3px', borderStyle: 'none', color: 'royalblue'}}><SearchIcon sx={{"&:hover": {backgroundColor: 'white'}}} /></Button>
                                        <input value={searchCriteria} onKeyUp={(event) => checkInput(event.key)} onChange={(event) => criteriaChanged(event.target.value)} placeholder="Search Patients" style={{borderStyle: 'none', height: '40px', width: '100%', padding: '10px 60px', fontSize: '105%', borderRadius: '50px'}}></input>
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>: 
                        <Grid item xs={12} className={classes.tableMargins} style={{borderTopRightRadius: '8px',borderTopLeftRadius: '8px',marginBottom: '0', padding: '14px 20px', backgroundColor: 'royalblue'}}>
                        {scannedPatientError? <Grid item className={classes.tableMargins} style={{paddingBottom: '15px'}} xs={12}><Alert style={{width: '100%'}} severity="error" variant="outlined">{scannedPatientErrorMessage}</Alert></Grid>: ""}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button style={{pointerEvents: 'none', backgroundColor: 'transparent', marginRight: '-65px', marginBottom: '3px', borderStyle: 'none', color: 'royalblue'}}><SearchIcon sx={{"&:hover": {backgroundColor: 'white'}}} /></Button>
                                    <input value={searchAllCriteria} onKeyUp={(event) => checkAllInput(event.key)} onChange={(event) => criteriaAllChanged(event.target.value)} placeholder="Search Patients" style={{borderStyle: 'none', height: '40px', width: '100%', padding: '10px 60px', fontSize: '105%', borderRadius: '50px'}}></input>  
                                </Grid>
                            </Grid>
                        </Grid> 
                        </Grid>}
                    </Grid>}
                    <Grid item xs={12} className={classes.tableMargins}>
                        {scanningType === 2? <Grid container>{startVerification? 
                        <Grid container spacing={2} style={{marginTop: '65px'}}>
                            <Grid item xs={12}>
                                <h2 style={{color: 'royalblue', textAlign: 'left', marginBottom: '5px', marginTop: '0'}}>Verifying Scanned Patients <Button onClick={resetVerification} color="primary" style={{backgroundColor: '#EDEEF7', borderStyle: 'none', float: 'right'}} variant="outlined" size="medium">
                                Reset
                                </Button></h2>
                            </Grid>
                            <Grid item xs={12}>
                                {verificationGeneralError? <Grid item style={{paddingBottom: '5px', paddingTop: '5px'}} xs={12}><Alert style={{width: '100%'}} severity="error" variant="outlined">{verificationErrorMessage}</Alert></Grid>: ""}
                                {verificationSuccess? <Grid item style={{paddingBottom: '5px', paddingTop: '5px'}} xs={12}><Alert style={{width: '100%'}} severity="success" variant="outlined">{verificationSuccessMessage}</Alert></Grid>: ""}
                            </Grid>
                            <Grid item xs={12}>
                                {loading? <LinearProgress /> : ""}
                            </Grid>
                            <Grid item xs={12}>
                                {verificationTable}
                            </Grid>
                        </Grid> : <Grid container spacing={2} style={{padding: '0 20%', marginTop: '65px'}}>
                                <Grid item xs={6}>
                                    {uploadedCurrentLocationCSV? "" : <Grid container><Button onClick={uploadCurrentLocationCSV} color="primary" style={{width: '100%', backgroundColor: '#EDEEF7', borderStyle: 'none'}} variant="outlined" size="medium">
                                        Upload {currentLocation} CSV
                                    </Button> 
                                    <input style={{display: 'none'}} type="file" ref={currentLocationRef} onChange={(e) => retrieveCurrentLocationData(e.target.files[0])}></input></Grid>}
                                    {uploadedCurrentLocationCSV?
                                    <Alert severity="success">
                                        <AlertTitle>File Uploaded Successfully</AlertTitle>
                                         <strong>{currentLocationCSVname}</strong>
                                    </Alert> : ""}
                                </Grid>
                                <Grid item xs={6}>
                                    {uploadedAcuityCSV? "" : <Grid container><Button onClick={uploadAcuityCSV} color="primary" style={{width: '100%',backgroundColor: '#EDEEF7', borderStyle: 'none'}} variant="outlined" size="medium">
                                        Upload Acuity Exported CSV
                                    </Button> 
                                    <input style={{display: 'none'}} type="file" ref={acuityCsvRef} onChange={(e) => retrieveAcuityCsvData(e.target.files[0])}></input></Grid>}
                                    {uploadedAcuityCSV?
                                    <Alert severity="success">
                                        <AlertTitle>File Uploaded Successfully</AlertTitle>
                                         <strong>{acuityCSVname}</strong>
                                 </Alert> : ""}
                                </Grid>
                                <Grid item xs={12} style={{marginTop: '5px', paddingTop: '0'}}>
                                    <Button disabled={!(uploadedCurrentLocationCSV && uploadedAcuityCSV)} onClick={beginVerification} color="primary" style={{width: '100%'}} variant="contained" size="medium">
                                        Begin Verification
                                    </Button> 
                                </Grid>
                               
                        </Grid>}</Grid>: <Grid container>
                        {scanningType === 0? <Grid container style={{maxHeight: '70vh', overflow: 'auto'}}>{renderTable}</Grid> : <Grid container style={{maxHeight: '70vh', overflow: 'auto'}}>{renderAllScannedPatients}</Grid>}
                        </Grid>}
                    </Grid>
                </Grid> :
                <Grid container>
                {sendResults? 
                <Grid container>
                    <Grid item xs={12}>
                        {fileRetrievalSuccessful && !loadingFile? <Grid item xs={12}>
                            <Button disabled={sendingResult} onClick={sendResultsAutomatic} color="primary" style={{float: 'left', backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none'}} variant="outlined" size="medium">
                                Send Results
                        </Button> 
                        <Button onClick={backToCorepointFiles} color="primary" style={{backgroundColor: '#EDEEF7',borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                                Back to Files
                        </Button></Grid>: ""}
                        <Button onClick={homePage} color="primary" style={{marginRight: '5px', float: 'right', backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none', marginBottom: '5px'}} variant="outlined" size="medium">
                            Home
                        </Button>
                    </Grid>
                    {loadingFile? 
                    <Grid item xs={12} className={classes.tableMargins} style={{maxHeight: '70vh', overflow: 'auto'}}>
                        <Grid item xs={12}>
                            <h1 style={{color: 'royalblue', marginBottom: '75px', textAlign: 'center', marginTop: '10px'}}>Fetching Files from Corepoint</h1> 
                            {loading? <LinearProgress style={{marginTop: '10px'}} /> : ""}
                        </Grid> 
                    </Grid> :
                    <Grid item xs={12} className={classes.tableMargins}>
                        {fileRetrievalSuccessful? <Grid container>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <h1 style={{color: 'royalblue', marginBottom: '75px', textAlign: 'center', marginTop: '75px'}}>{currentFileName}</h1> 
                                {sendingResult?
                                <Grid item xs={12}>
                                    <Grid style={{paddingBottom:'5px'}}>
                                        <Button disabled={!(resultsError || success)} onClick={doneViewingResultErrors} color="primary" style={{backgroundColor: '#EDEEF7',borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                                                Done
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3 style={{color: 'royalblue'}}>Emailing Results</h3>
                                        <LinearProgress variant="determinate" value={progressBar} />
                                    </Grid>
                                </Grid> :
                                <Grid item xs={12}>
                                </Grid>}
                                {loading? <LinearProgress style={{marginTop: '10px'}} /> : ""}
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '50px', maxHeight: '75vh', overflow: 'auto'}}>
                                {(!finishedSendingResult && sendingResult) ? 
                                <Grid container direction="row" justifyContent='center' style={{paddingBottom:'15px'}}>
                                    <CircularProgress />
                                </Grid> : ""}
                                {resultsError? 
                                <Grid item style={{paddingBottom: '15px'}} xs={12}>
                                    <Grid item xs={12} style={{paddingBottom: '15px'}}>
                                        <Alert style={{width: '100%'}} severity="error" variant="outlined">{resultsErrorMessage}</Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion style={{boxShadow: 'none', borderStyle:'solid', borderColor:'royalblue', borderWidth:'1px', borderRadius:'0px', marginTop: '8px', marginBottom: '8px', paddingTop: '0', paddingBottom: '0', backgroundColor: '#F6F7FF', color: 'royalblue'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon sx={{color: 'royalblue'}} />}
                                            >
                                            <Typography>View Errors</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {errorListTable}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>: ""}
                                {success?
                                <Grid item xs={12} style={{paddingBottom:'10px'}}>
                                    <Alert severity="success">
                                        <AlertTitle>{successMessage}</AlertTitle>
                                    </Alert>
                                </Grid> : ""}
                                
                                {renderFileInformation}
                            </Grid>
                        </Grid> :
                        <Grid container>
                            <Grid item xs={12}>
                                <h1 style={{color: 'royalblue', marginBottom: '75px', textAlign: 'center', marginTop: '75px'}}>Select a File</h1> 
                                <Grid item xs={12} style={{maxHeight: '75vh', overflow: 'auto'}}>
                                    <h3 style={{color: 'royalblue', marginBottom: '15px', textAlign: 'left', marginTop: '10px'}}>Recent Results</h3> 
                                    {resultsTable}
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>}
                </Grid>:
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                        <Button onClick={() => navigate(-1)} color="primary" style={{backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                            Back
                        </Button>
                    </Grid>
                    <h1 style={{color: 'royalblue', marginBottom: '75px', textAlign: 'left', marginTop: '10px'}}>Welcome to the Lab Menu</h1>
                    <Grid container className={classes.selectionsContainer}>
                        <Grid container style={{paddingTop: '75px'}}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid className={classes.sendResultsMargins} onClick={selectSendResults} item xs={12}>
                                    <Grid item xs={12} style={{paddingTop: '17px'}}>
                                        <SendIcon />
                                    </Grid> 
                                    <h3 style={{marginTop: '7px'}}>Send Results</h3>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid className={classes.scanTubesMargins} onClick={() => setScanResults(1)} item xs={12}>
                                    <Grid item xs={12} style={{paddingTop: '17px'}}>
                                        <DocumentScannerIcon />
                                    </Grid> 
                                    <h3 style={{marginTop: '7px'}}>Scan LV Codes</h3>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>}
                </Grid>}
            </Grid>}
            <CSVLink
                            data={csvData}
                            filename={`${formattedDate}-${currentLocation}.csv`}
                            className='hidden'
                            ref={csvLink}
                            target='_blank'
                        />
                        <input style={{display: 'none'}} type="file" ref={csvDataRef} onChange={(e) => retrieveCsvData(e.target.files[0])}></input>
        </Grid>
    )
}