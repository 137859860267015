// @ts-nocheck
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import useTheme from '@mui/styles/useTheme';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Fragment } from 'react';
import MenuTwoTone from '@mui/icons-material/MenuTwoTone'
import styled from '@mui/material/styles/styled'
import dayjs from 'dayjs'
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useEffect } from 'react'

import './form.css'
import { allergiesL, medications2, symptomsList, testTypes, vaccineTypes, port } from './constants'
import { HelpComponent } from './components/helpdrawer';

import { MissingAlerts } from './components/missing';
import { canSubmitMultiplex } from './functions/autofill';
import { autoFillFunctions } from './functions/format';
import { HistoryComponent } from './components/history';
import { enUS } from 'date-fns/locale';
import { submitMultiplexResult } from './lib/multiplexSubmit';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginLeft: '3%',
    marginRight: '3%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


//No Medications reported, General diabetes medication (insulin), 
//General birth control,General celiac disease medication,General antidepressant,General thyroid,
//General blood pressure, Hormone medication,General statins (cholesterol),Adderall,Albuterol,Aspirin,Crestor,Humira,
//Levothyroxine,Lexapro,Lyrica,Prozac,Seasonale,Sovaldi,Synthroid,Zoloft,Other


export function ProgressFormComponent() {
    const [dateOfService, setValue] = useState(dayjs(new Date()).toISOString());
    const [dateOfBirth, setDOB] = useState('Invalid Date');
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [location, setLocation] = useState('')
    const [firstNames, setFirstNames] = useState([])
    const [fName, setFName] = useState('')
    const [lastNames, setLastNames] = useState([])
    const [lastName, setLastName] = useState('')
    const [reason, setReason] = useState('')
    const [symptoms, setSymptoms] = useState([])
    const [testType, setTestType] = useState([])
    const [vaccine, setVaccine] = useState({ label: '' })
    const [pastMedical, setPastMedical] = useState([])
    const [smoker, setSmoker] = useState('')
    const [temperature, setTemperature] = useState('')
    const [heartRate, setHeartRate] = useState('')
    const [oxygen, setOxygen] = useState('')
    const [medicationsI, setMedicationsI] = useState([])
    const [allergies, setAllergies] = useState([''])
    const [result, setResult] = useState('')
    const [startTime, setStartTime] = useState(new Date())
    const [endTime, setEndTime] = useState(new Date())
    const [helpDrawerOpen, setHelpDrawerOpen] = useState(false)
    const [autoFillResponse, setAutoFillResponse] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [missingAlert2, setMissingAlert2] = useState([])
    const theme = useTheme();
    const [fNameColor, setfNameColor] = useState('warning')
    const [lastNameColor, setlastNameColor] = useState('warning')
    const [emailColor, setEmailColor] = useState('warning')
    const [phoneColor, setphoneColor] = useState('warning')
    const [tempColor, setTempColor] = useState('warning')
    const [heartColor, setHeartColor] = useState('warning')
    const [oxygenColor, setOxygenColor] = useState('warning')
    const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false)
    const [personName, setPersonName] = useState([]);
    const [claimNumber, setClaimNumber] = useState('')
    const [firstNameOpen, setFirstNameOpen] = useState(false)
    const [firstNameLoading, setFirstNameLoading] = useState(firstNameOpen && firstNames.length === 0)
    const [lastNameOpen, setLastNameOpen] = useState(false)
    const [lastNameLoading, setLastNameLoading] = useState(lastNameOpen && lastNames.length === 0 )
    const [mainfo , setMaInfo ] = useState('')

    const checkData = (async (event) => {
        let data = {
            firstName: fName,
            lastName: lastName,
            reason: reason,
            email: email,
            phone: new autoFillFunctions().formatPhoneNumber(phone),
            DOS: new Date(dateOfService).getTime(),
            DOB: (new Date(dateOfBirth).getTime()) + 14400000 ,
            testType: testType,
            vaccine: vaccine,
            symptoms: symptoms,
            location: location,
            smoker: smoker,
            pastMedical: pastMedical,
            temperature: temperature,
            oxygen: oxygen,
            medications: medicationsI,
            heartRate: heartRate,
            allergies: allergies,
            start: startTime,
            result: result,
            claimNumber: claimNumber,
            medical_assistant_info : mainfo
        }
        console.log(data)
        const canSubmit = canSubmitMultiplex(data)

        if (canSubmit.length !== 0) {
            setMissingAlert2(canSubmit)
            setShowAlert(true)
        }
        else {
            submitMultiplexResult(data)

            // console.log(data)
            // fetch(port + ':5001/check', {
            //     headers: {
            //         "Accept": "application/pdf",
            //         "Content-Type": "application/json"
            //     },

            //     method : 'POST' , 
            //     body :  JSON.stringify(data) , 

            // })
            // .then(response => response.blob())
            // .then(blob => { 
            //     var url = window.URL.createObjectURL(blob);
            //     var a = document.createElement('a');
            //     console.log(a)
            //     a.href = url;
            //     a.download = `${lastName+fName}`;
            //     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            //     a.click();    
            //     a.remove();  //afterwards we remove the element again 
            // })
            // .catch(error => { 
            //     console.log(error)
            // })


        }
    })


    const getTest = (async (event) => {
        let data = {
            firstName: fName,
            lastName: lastName,
            reason: reason,
            email: email,
            phone: phone,
            DOS: dateOfService,
            DOB: dateOfBirth,
            testType: testType,
            vaccine: vaccine,
            symptoms: symptoms,
            location: location,
            smoker: smoker,
            pastMedical: pastMedical,
            temperature: temperature,
            oxygen: oxygen,
            medicationsI: medicationsI,
            heartRate: heartRate,
            allergies: allergies,
            result: result,
            start: startTime,
            end: new Date(endTime).getTime() ,
            medical_assistant_info : mainfo

        }
        let canSubmit = canSubmitMultiplex(data)
        if (canSubmit.length !== 0) {
            console.log('Doing some setting')
            setMissingAlert2(canSubmit)
            setShowAlert(true)
        }
        else {
            let data = {
                firstName: fName,
                lastName: lastName,
                reason: reason,
                email: email,
                phone: phone,
                DOS: dateOfService,
                DOB: dateOfBirth,
                testType: testType,
                vaccine: vaccine,
                symptoms: symptoms,
                location: location,
                smoker: smoker,
                pastMedical: pastMedical,
                temperature: temperature,
                oxygen: oxygen,
                medicationsI: medicationsI,
                heartRate: heartRate,
                allergies: allergies,
                result: result,
                start: startTime,
                end: new Date(endTime).getTime(),
                medical_assistant_info : mainfo

            }
            let sendData = JSON.stringify(data)
            console.log(sendData)
            fetch(port + ':5001/test', {
                method: 'POST',
                body: sendData,
                headers: {
                    "Accept": "application/pdf",
                    'Content-Type': 'application/json'
                    // "Access-Control-Allow-Origin" : "true" 
                },

            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = `${fName + lastName + 'test.pdf'}`;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again 
                })
        }

    })
    const defaultProps = {
        options: firstNames,
        getOptionLabel: (option) => option.label || '',
    };

    const lastNameProps = {
        options: lastNames,
        getOptionLabel: (option) => option.label || '',
    };
    const actionObject = {
        setFirst: setFName,
        setLast: setLastName,
        setPhone: setPhone,
        setEmail: setEmail,
        setLocation: setLocation,
        setReason: setReason,
        setVaccine: setVaccine,
        setTemp: setTemperature,
        setHeartRate: setHeartRate,
        setOxygen: setOxygen,
        setSmoker: setSmoker,
        setMedical: setPastMedical,
        setResult: setResult,
        setDrugs: setAllergies,
        setDOS: setValue,
        setDOB : setDOB , 
        setSymptoms: setSymptoms,
        setMedicationsI: setMedicationsI
    }


    const autoFillData = async (event) => {
        let data = {
            firstName: fName,
            lastName: lastName,
            DOS: dateOfService,
        }
        data = JSON.stringify(data)
        console.log(data)
        // let host = 'https://staging.api.clearmdhealth.com';
        // host = '127.0.0.1:5050'
        let response = await fetch('https://api.clearmdhealth.com/v1/appointments/acuity/search?' + new URLSearchParams({
            firstName: fName,
            lastName: lastName,
        }), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': 'ca2269e0cd9ca81b4e60ba3e43de689f',
            }

        })
        let responseData = await response.json()
        setAutoFillResponse(responseData)
        setHelpDrawerOpen(!helpDrawerOpen)

    }

    const clearAll = async (event) => {
        setFName('')
        setLastName('')
        setPhone('')
        setEmail('')
        setLocation('')
        setReason('')
        setVaccine('')
        setTemperature('')
        setHeartRate('')
        setOxygen('')
        setSmoker('')
        setPastMedical([])
        setResult('')
        setAllergies([])
        setValue('')
        setSymptoms([])
        setMedicationsI([])
        setTestType('')
    }

    useEffect(() => {
        async function getSomeData() {

            let response = await fetch('https://api.clearmdhealth.com/v1/patients/autocomplete?' + new URLSearchParams({
                firstName: fName,
                lastName: lastName,
            }), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': 'ca2269e0cd9ca81b4e60ba3e43de689f',
                }
            })
            let nameList = await response.json()
            console.log(nameList)
            const firstNames = nameList.uniqueFirstNames.map((names, index) => {
                return { label: names, key: index }
            })
            const lastNames = nameList.uniqueLastNames.map((names, index) => {
                return { label: names, key: index }
            })


            console.log(lastNames)
            setFirstNames(firstNames)
            setLastNames(lastNames)
            
            // setFirstNames(nameList.unqueFirstNames)
            // console.log(response.body.json())
            // console.log(response.json())
            // response = response.json()
            // console.log(response)
            // setFirstNames(response.uniqueFirstNames)
            // setLastNames(response.uniqueFirstNames)




        }
        getSomeData()
    }, [fName, lastName]);
    useEffect(() => {
        if (!firstNameOpen) {
            setFirstNames([]);
        }
        if (!lastNameOpen) {
            setLastNames([])
        }
    }, [firstNameOpen, lastNameOpen])
    return (
        <Grid container spacing={2} >
            <HelpComponent toggle={helpDrawerOpen} setToggle={setHelpDrawerOpen}
                data={autoFillResponse}
                actions={actionObject}
            />
            <HistoryComponent
                toggle={historyDrawerOpen}
                setToggle={setHistoryDrawerOpen}>
            </HistoryComponent>
            <MissingAlerts missingAlert2={missingAlert2} showAlert={showAlert} setShowAlert={setShowAlert}></MissingAlerts>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed">

                    <Toolbar>
                        <IconButton
                            size="large"

                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuTwoTone onClick={() => { setHistoryDrawerOpen(true) }} />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Progress & Multiplex Generator
                        </Typography>
                        <Button color="inherit" onClick={() => { autoFillData() }}>Check Me</Button>
                    </Toolbar>
                </AppBar>
            </Box>

            {/* First Name Field*/}
            <Grid item xs={6}>
                <Item>
                    <FormControl>

                        <Autocomplete
                            {...defaultProps}
                            id="clear-on-escape"
                            
                            freeSolo

                            onInputChange={(event, newValue) => {
                                console.log(newValue)
                                console.log(newValue)
                                setFName(newValue)
                            }}
                            open={firstNameOpen}
                            onOpen={() => {
                                setFirstNameOpen(true);
                            }}
                            onClose={() => {
                                setFirstNameOpen(false);
                            }}
                            loading={firstNameLoading}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label="Patient First Name"
                                    variant="standard"
                                    value={fName} required
                                    color={fNameColor}
                                    onChange={fName === "" ? setfNameColor("error") : setfNameColor("success")}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Fragment>
                                                {firstNameLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <FormHelperText id="my-helper-text">Enter the Patients First Name as it appears on the Audit</FormHelperText>
                    </FormControl>
                </Item>
            </Grid>

            {/*Last Name Field*/}
            <Grid item xs={6}>
                <Item>
                    <FormControl>
                        <Autocomplete
                            {...lastNameProps}
                            id="clear-on-escape"
                            freeSolo
                            onInputChange={(event, input) => {
                                console.log(input)
                                setLastName(input)
                            }}
                            open={lastNameOpen}
                            onOpen={() => {
                                setLastNameOpen(true);
                            }}
                            onClose={() => {
                                setLastNameOpen(false);
                            }}
                            loading={lastNameLoading}
                            renderInput={(params) => (
                                <TextField {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Fragment>
                                                {firstNameLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </Fragment>
                                        ),
                                    }}
                                    label="Patient Last Name"
                                    color={lastNameColor}
                                    variant="standard"
                                    value={lastName} required
                                    onChange={lastName === "" ? setlastNameColor("error") : setlastNameColor("success")}

                                />
                            )}
                        />
                        <FormHelperText id="my-helper-text">Enter the Patients Last Name as it appears on the Audit</FormHelperText>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={6}>
                <Item>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DesktopDatePicker
                            disableFuture
                            label="Enter Patient Date of Birth"
                            openTo="year"
                            views={['year', 'month', 'day']}
                            value={dateOfBirth}

                            onChange={(newValue) => {
                                console.log(newValue)
                                if (newValue) {
                                    setDOB(new Date(newValue));
                                }

                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Item>
            </Grid>

            <Grid item xs={6}>
                <Item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            orientation='portrait'
                            renderInput={(props) => <TextField {...props} />}
                            label="Enter Patients Date of Service"
                            value={dateOfService}
                            onChange={(newValue) => {
                                if (newValue) {
                                    console.log(newValue)
                                    setValue(newValue.toISOString());
                                    console.log(newValue.toISOString())
                                }
                            }} />
                    </LocalizationProvider>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl>
                        <InputLabel htmlFor="phone">Patient Phone Number </InputLabel>
                        <Input id="phone" aria-describedby="my-helper-text"
                            value={phone}
                            color={phoneColor}
                            onChange={(event) => {
                                setPhone(new autoFillFunctions().formatPhoneNumber(event.target.value)); phone === '' || !phone.includes('-') || !phone.includes('()') ? setphoneColor('error') : setphoneColor('success')
                                console.log(event.target.value)
                            }}
                        />
                        <FormHelperText id="phone">Patient Phone Number</FormHelperText>
                    </FormControl>
                </Item>

            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl>
                        <InputLabel htmlFor="email">Patient Email Address</InputLabel>
                        <Input id="email" aria-describedby="my-helper-text" value={email}
                            color={emailColor} onChange={(event) => {
                                setEmail(event.target.value); email === '' || !email.includes('@') ? setEmailColor('error') : setEmailColor('success')
                            }} />
                        <FormHelperText id="email">Patient Email Address</FormHelperText>
                    </FormControl>
                </Item>

            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="select-location" >Appointment Location</InputLabel>
                        <Select
                            labelId="select-location"
                            id="select-location"
                            value={location}
                            label="Select Appointment 
                            
                            
                            "
                            onChange={(event) => { setLocation(event.target.value) }}>

                            <MenuItem value={'Chelsea'}>Chelsea</MenuItem>
                            <MenuItem value={'UWS2'}>Upper West Side 2</MenuItem>
                            <MenuItem value={'UWS'}>Upper West Side</MenuItem>
                            <MenuItem value={'Noho'}>Noho</MenuItem>
                            <MenuItem value={'Upper East Side'}>Upper East Side</MenuItem>
                            <MenuItem value={'Midtown'}>Midtown East</MenuItem>
                            <MenuItem value={'Chinatown'}>Chinatown</MenuItem>
                            <MenuItem value={'Astoria'}>Astoria</MenuItem>
                        </Select>
                    </FormControl>
                </Item>

            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="visit-reason" >Reason for Visit</InputLabel>
                        <Select
                            labelId="visit-reason"
                            id="reason"
                            value={reason}
                            label="Reason for Visit"
                            onChange={(event) => { setReason(event.target.value) }}>

                            <MenuItem value={'Potential'}>Potential Exposure to COVID-19</MenuItem>
                            <MenuItem value={'Confirmed'}>Confirmed Exposure to COVID-19</MenuItem>
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '80%' }}>
                        <InputLabel id="select-symp">Select Reported Patient Symptoms</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            value={symptoms}
                            onChange={(event) => {
                                console.log(event.target.value)
                                if (typeof (event.target.value === 'string')) {
                                    //console.log(event.target.value.split(','))
                                    setSymptoms(event.target.value)
                                }
                            }}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                        >
                            {symptomsList.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, personName, theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '80%' }}>
                        <InputLabel id="select-symp">Select The Type of Test/Tests Patient Had</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={testType}
                            onChange={(event) => {
                                setTestType(event.target.value)
                                console.log(testType)
                            }}
                            input={<OutlinedInput label="N" />}
                            MenuProps={MenuProps}
                        >
                            {testTypes.map((testName) => (
                                <MenuItem
                                    key={testName}
                                    value={testName}
                                    style={getStyles(testName, personName, theme)}
                                >
                                    {testName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="select-location" >Type of Vaccine</InputLabel>
                        <Select
                            labelId="select-location"
                            id="select-location"
                            value={vaccine}
                            label="Select Appointment Location"
                            onChange={(event) => {
                                console.log(event.target.value)
                                setVaccine(event.target.value)
                                console.log(vaccine)
                            }}>

                            {vaccineTypes.map((vaccineT) => (
                                <MenuItem
                                    key={vaccineT}
                                    value={vaccineT}
                                    style={getStyles(vaccine, personName, theme)}
                                >{vaccineT}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            {/* No Past Medical History, Hypertension, Diabetes Mellitus, Hyperlipidemia, Hypothyroidism, Asthma, COPD, Other */}
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="select-location" >Past Medical History</InputLabel>
                        <Select
                            labelId="select-location"
                            id="select-location"
                            value={pastMedical}
                            label="Select Appointment Location"
                            multiple
                            onChange={(event) => {
                                console.log(event.target.value)
                                setPastMedical(event.target.value)
                            }}>

                            <MenuItem value={'No Past Medical History'}>No Past Medical History</MenuItem>
                            <MenuItem value={'Hypertension'}>Hypertension</MenuItem>
                            <MenuItem value={'Diabetes Mellitus'}>Diabetes Mellitus</MenuItem>
                            <MenuItem value={'Hyperlipidemia'}>Hyperlipidemia</MenuItem>
                            <MenuItem value={'Hypothyroidism'}>Hypothyroidism</MenuItem>
                            <MenuItem value={'Asthma'}>Asthma</MenuItem>
                            <MenuItem value={'COPD'}>COPD</MenuItem>
                            <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="select-location" >Smoker</InputLabel>
                        <Select
                            labelId="select-location"
                            id="select-location"
                            value={smoker}
                            label="Select Appointment Location"
                            onChange={(event) => {
                                console.log(event.target.value)
                                setSmoker(event.target.value)
                            }}>

                            <MenuItem value={'Yes'}>Yes</MenuItem>
                            <MenuItem value={'No'}>No</MenuItem>
                            <MenuItem value={'Used to'}>Used to</MenuItem>
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl>
                        <InputLabel htmlFor="email">Temperature</InputLabel>
                        <Input id="email"
                            aria-describedby="my-helper-text"
                            type="text"
                            value={temperature}
                            color={tempColor}
                            onChange={(event) => {
                                setTemperature(new autoFillFunctions().formatNumToString(event.target.value)); temperature === '' ? setTempColor('error') : setTempColor('success');
                                console.log(event.target.value)
                            }}
                        />
                        <FormHelperText id="email">Temperature</FormHelperText>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl>
                        <InputLabel htmlFor="email">Heart Rate</InputLabel>
                        <Input id="email"
                            aria-describedby="my-helper-text"
                            type="text"

                            value={heartRate}
                            color={heartColor}
                            onChange={(event) => {
                                setHeartRate(event.target.value); heartRate === '' ? setHeartColor('error') : setHeartColor('success');
                                console.log(event.target.value)
                            }}
                        />
                        <FormHelperText id="email">Heart Rate</FormHelperText>
                    </FormControl>



                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl>
                        <InputLabel htmlFor="email">Oxygen %</InputLabel>
                        <Input id="email"
                            type="text"
                            aria-describedby="my-helper-text"
                            value={oxygen}
                            color={oxygenColor}
                            onChange={(event) => {
                                setOxygen(event.target.value); oxygen === '' ? setOxygenColor('error') : setOxygenColor('success');
                                console.log(event.target.value)
                            }}
                        />
                        <FormHelperText id="email">Oxygen %</FormHelperText>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="select-location" >Reported Medications</InputLabel>
                        <Select
                            multiple
                            labelId="select-location"
                            id="select-location"
                            value={medicationsI}
                            label="Select Appointment Location"
                            onChange={(event) => {
                                console.log(event.target.value)
                                setMedicationsI(event.target.value)
                            }}>

                            {medications2.map((vaccineT) => (
                                <MenuItem
                                    key={vaccineT}
                                    value={vaccineT}
                                    style={getStyles(vaccine, personName, theme)}
                                >{vaccineT}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>{/*  No Known Drug Allergies (NKDA), Penicillin, Sulfonamide, ACE inhibitors, Prozac, Amoxicillin, Other*/}
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="select-location" >Drug Allergies</InputLabel>
                        <Select
                            multiple
                            labelId="select-location"
                            id="select-location"
                            value={allergies}
                            label="Select Appointment Location"
                            onChange={(event) => {
                                console.log(event.target.value)
                                if (event.target.value.includes('No Known Drug Allergies (NKDA)') && allergies.includes('No Known Drug Allergies (NKDA)')) {
                                    setAllergies([])
                                }
                                if (event.target.value.includes('No Known Drug Allergies (NKDA)') && allergies.includes('No Known Drug Allergies (NKDA)')) {
                                    console.log('Hello')
                                    const all = ['No Known Drug Allergies (NKDA)']
                                    setAllergies(all)
                                }
                                else {
                                    setAllergies(event.target.value)
                                }

                            }}>

                            {allergiesL.map((allergy) => (
                                <MenuItem
                                    key={allergy}
                                    value={allergy}
                                    style={getStyles(vaccine, personName, theme)}
                                >{allergy}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <InputLabel id="select-location" >Test Result</InputLabel>
                        <Select
                            labelId="select-result"
                            id="select-location"
                            value={result}
                            label="Select Appointment Location"
                            onChange={(event) => {
                                console.log(event.target.value)
                                setResult(event.target.value)
                            }}>
                            <MenuItem value={'Positive'}>POSITIVE</MenuItem>
                            <MenuItem value={'Negative'}>NEGATIVE</MenuItem>
                        </Select>
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            orientation='portrait'
                            renderInput={(props) => <TextField {...props} />}
                            label="Enter Multiplex Start Time"
                            value={startTime}
                            onChange={(newValue) => {
                                if (newValue) {
                                    console.log(newValue)
                                    setStartTime(newValue);
                                    console.log(startTime)
                                }
                            }} />
                    </LocalizationProvider>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <TextField
                            id="standard-basic"
                            required
                            label="Claim Number"
                            value={claimNumber}
                            onChange={e => setClaimNumber(e.target.value)}
                        />
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <FormControl style={{ width: '60%' }}>
                        <TextField
                            id="standard-basic"
                            required
                            label="Medical Assistant Initals"
                            value={mainfo}
                            onChange={e => setMaInfo(e.target.value)}
                        />
                    </FormControl>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Grid container>
                <Item>
                        <Button
                            onClick={checkData}
                        >Send Me a Progress Note </Button>
                </Item>
                </Grid>

            </Grid>
            <Grid item xs={4}>
                <Item>
                    <Button color="inherit" onClick={() => { autoFillData() }}>Check Me</Button>
                </Item>
            </Grid>
            <Grid item xs={4}>
                <Item>
                    <Button
                        disabled={false}
                        onClick={clearAll} type='submit'>Clear All Data </Button>
                </Item>
            </Grid>
        </Grid>




    )
}