import { getUnit } from "@mui/material/styles/cssUtils"
import { PatientResultsSTI } from "../results"
import {turnAround}  from "../const"
/**
 * 
 * @param {Array} inputPatientArray - represents all the patients that were submitted to the getResults function.
 * @returns 
 */
// export function getUnqueIds(inputPatientArray){ 
//     let uniqueIds = inputPatientArray.map(patient => { 
//         return patient.patientId
//     })
//     return uniqueIds
// }

// export function getAllReturnedIds(returnedData){
//     let patientList = {}
//     returnedData.forEach(patientsResults => {
//         if(patientsResults.length > 0){ 
//             patientList[patientsResults[0].patientId] = patientsResults
//         }

//     })
//     return patientList

// }
export function getAllReturnedIds(returnedData){
    let patientList = {}
    returnedData.forEach(patientsResults => {
        if(patientsResults.length > 0){ 
            patientList[patientsResults[0].patientId] = patientsResults
        }

    })
    return patientList

}
/**
 * 
 * @param {*} initialData 
 * @param {*} returnedResultsData 
 * @returns Array that contains all of the notification data that the front end needs to dispaly data
 */

function timeCalc(dateTime, testType, addOns){

    const currDateTime = new Date()
    const lowerEnd = new Date(dateTime)
    const upperEnd = new Date(dateTime)
    const dueDateTime = new Date(dateTime)

    let output = []

    // Since antigen turnaround is less than an hour, we need to check if the midpoint is less than one
    // and add it as minutes if necessary
    if (turnAround[`${testType}`]){
        
        let midpoint = turnAround[`${testType}`].latest - turnAround[`${testType}`].earliest
        midpoint = midpoint / 2
        let midpointMinVal = midpoint * 60
        lowerEnd.setHours(lowerEnd.getHours() + turnAround[`${testType}`].earliest)
        midpoint < 1 ? upperEnd.setMinutes(upperEnd.getMinutes() + midpointMinVal) : upperEnd.setHours(upperEnd.getHours() + midpoint)  
        dueDateTime.setHours(dueDateTime.getHours() + turnAround[`${testType}`].latest)
    }
    else if (turnAround[`${addOns}`]){

        let midpoint = turnAround[`${addOns}`].latest - turnAround[`${addOns}`].earliest
        midpoint = midpoint / 2
        let midpointMinVal = midpoint * 60
        lowerEnd.setHours(lowerEnd.getHours() + turnAround[`${addOns}`].earliest)
        midpoint < 1 ? upperEnd.setMinutes(upperEnd.getMinutes() + midpointMinVal) : upperEnd.setHours(upperEnd.getHours() + midpoint)
        dueDateTime.setHours(dueDateTime.getHours() + turnAround[`${addOns}`].latest)
    }

    
    if (lowerEnd.getTime() == upperEnd.getTime()){
        output.push('#e8e5df')
    }
    else if (currDateTime >= lowerEnd && currDateTime < upperEnd) {
        output.push('#e3c266')
    }
    else if (currDateTime >= upperEnd && currDateTime > lowerEnd){
        output.push('#f58982')
    }
    else {
        output.push('#e8e5df')
    }
    
    let dueDate = dueDateTime.toLocaleDateString()
    let dueTime = dueDateTime.toLocaleTimeString()

    if (lowerEnd.getTime() == upperEnd.getTime()){
        output.push("Due date not available")
    }
    else {
        output.push(`Due before ${dueDate} at ${dueTime}`)
    }

    return output
}
/**
 * 
 * @param {*} returnedResultsData - The returned data from Gyasi's function
 * @returns 
 */

export function areApptsLateorDueNew(returnedResultsData){
    const unsentPatientTests = []
    let sampleTest = []

    returnedResultsData.forEach(patient => {
        
        if (patient.results.length == 0){
            sampleTest = {}
            sampleTest.patientId = patient.patientId
            sampleTest.results = patient.results
            sampleTest.firstName = patient.firstName
            sampleTest.lastName = patient.lastName
            sampleTest.testType = patient.testType
            sampleTest.appointmentDateTime = patient.appointmentDateTime
            let res = timeCalc(patient.appointmentDateTime, patient.testType, patient.results)
            sampleTest.color = res[0]
            sampleTest.dueDate = res[1]
            sampleTest.index = patient.index

            unsentPatientTests.push(sampleTest)
        }
        else {
            patient.results.forEach(result => {
                if (result.res == ''){
                    sampleTest = {}
                    sampleTest.patientId = patient.patientId
                    sampleTest.results = result.testType
                    sampleTest.firstName = patient.firstName
                    sampleTest.lastName = patient.lastName
                    sampleTest.testType = patient.testType
                    sampleTest.appointmentDateTime = patient.appointmentDateTime
                    let res = timeCalc(patient.appointmentDateTime, patient.testType, result.testType)
                    sampleTest.color = res[0]
                    sampleTest.dueDate = res[1]
                    sampleTest.index = patient.index

                    unsentPatientTests.push(sampleTest)
                }
            })
            

        }
    })

    console.error(unsentPatientTests)
    return unsentPatientTests
}
// export function areApptsLateorDue(initialData , returnedResultsData){ 
//     const uniqueIDArray = getUnqueIds(initialData)
//     //const returnedIdArray = getAllReturnedIds(returnedResultsData)

//     // Create an object of unsentPatientIds to avoid O(n^2) complexity
//     const unsentPatientTests = []
//     let sampleTest = []
    
//     uniqueIDArray.forEach(patientID => {
        
//         console.log(patientID)
        
//         try {
//             console.log(returnedResultsData)
//             // If there are returned results from getAllResults function for a patient, check if the patient has any missing results for that panel. 
//             if (returnedResultsData[patientID] !== undefined && returnedResultsData[patientID].results){
//                 const allResults =  {}
//                 returnedResultsData[patientID].results.forEach(returnedResult =>  {
//                     Object.keys(returnedResult).forEach(resultKey => { 
//                         if(allResults[resultKey] === '' && returnedResult[resultKey] !== ''){ 
//                             allResults[resultKey] = returnedResult[resultKey]
//                         }
//                         else if(allResults[resultKey] !== '' && returnedResult[resultKey]  !== ''){ 
//                             // Doesnt matter 
//                         }
//                         else if(allResults[resultKey] === '' && returnedResult[resultKey]  === ''){ 
//                             // Do Nothing 
//                         }
//                         else if(allResults[resultKey] !== '' && returnedResult[resultKey] !== ''){ 
//                             //Do Nothing 
//                         }
                        
//                     })
//                 })
//                 console.log(allResults)
                
//                 // returnedResultsData[patientID].slice(-1)[0][`${patientID}`].slice(-1)[0].results.forEach(testType => {

//                 //     if (testType['res'] == ''){
//                 //         sampleTest = []
//                 //         sampleTest.push(patientID)
//                 //         sampleTest.push(testType)
//                         // sampleTest.push(returnedResultsData[patientID}`].slice(-1)[0][`${patientID}`].slice(-1)[0].dob)
//                         // sampleTest.push(returnedResultsData[`${patientID}`].slice(-1)[0][`${patientID}`].slice(-1)[0].firstName)
//                         // sampleTest.push(returnedResultsData[`${patientID}`].slice(-1)[0][`${patientID}`].slice(-1)[0].lastName)
//                         // sampleTest.push(returnedResultsData[`${patientID}`].slice(-1)[0].testTime)
//                         // sampleTest.push(returnedResultsData[`${patientID}`].slice(-1)[0].testDate)
//                         // sampleTest.push(returnedResultsData[`${patientID}`].slice(-1)[0].testType)

//                         // unsentPatientTests.push(sampleTest)
        
                        
//                     // }
                    
//                 // })
//             }
//             // If there are NO results returned for the selected patient ID, check what tests need results
//             else {
//                 console.log(`This person has the ID: ${patientID}`)
//                 initialData.forEach(patient => {
//                     if (patient.patientId == patientID){
//                         console.log(patient.results.length)

//                         // This will deal with appointments made where the patients dont have tests made
//                         if (patient.results.length == 0){
//                             console.log("Boyy what the hell boy")
//                             sampleTest = []
//                             sampleTest.push(patientID)
//                             sampleTest.push(patient.results)
//                             sampleTest.push(patient.dob)
//                             sampleTest.push(patient.firstName)
//                             sampleTest.push(patient.lastName)
//                             sampleTest.push(patient.appointmentTime)
//                             sampleTest.push(patient.appointmentDate)
//                             sampleTest.push(patient.testType)

//                             unsentPatientTests.push(sampleTest)

//                         }

//                         else {

//                             patient.results.forEach(testType => {
                            
//                                 if (testType['res'] == '') {
//                                     sampleTest = []
//                                     sampleTest.push(patientID)
//                                     sampleTest.push(testType)
//                                     sampleTest.push(patient.dob)
//                                     sampleTest.push(patient.firstName)
//                                     sampleTest.push(patient.lastName)
//                                     sampleTest.push(patient.appointmentTime)
//                                     sampleTest.push(patient.appointmentDate)
//                                     sampleTest.push(patient.testType)
    
//                                     unsentPatientTests.push(sampleTest)
//                                 }
    
//                             })

//                         }
  
                            
//                     }
//                 })
            
//             }

   
//         } catch (error) {
//             console.log(error)
//         }
  
//     });

  
  
     
//     console.log("THIS IS UNIQUE ID ARRAY")
//     console.log(uniqueIDArray)
//     console.log("ThIS IS RETURNED ID ARRAY")
//     //console.log(returnedIdArray)

//     console.log(unsentPatientTests)

//     return unsentPatientTests
    

// }

