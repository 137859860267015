import {useState} from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import PersonAddAlt1SharpIcon from '@mui/icons-material/PersonAddAlt1Sharp';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import {createPatient} from './getAppointments'
import dayjs from 'dayjs'
export function PatientCreationPage3(props){
    const [patient] = useState(props.patient)
    const [createPtSuccessPopup , showCreatePatientSuccessPopup] = useState(false)
    const createPatientEvent = async() => { 
        console.log(patient)
        await createPatient(patient)
        showCreatePatientSuccessPopup(true)
    }
    return ( 

        <Card>  
        <Grid container xs = {12}>
            <Grid item xs = {4} >

            </Grid>
            <Grid item xs = {8}>
            <Box sx = {{display: 'flex',alignItems: 'center'}}>
          
            </Box>
                 <Grid container rowSpacing={4}>
                    <Grid>      <PersonAddAlt1SharpIcon fontSize='small'/> <Typography > Patient Name : {patient.firstName} {patient.lastName} </Typography></Grid>
                    <Grid item xs = {12}><Typography>Patient Email : {patient.email}</Typography></Grid>
                    <Grid item  xs = {12}> <Typography>Patient DOS: {dayjs(new Date(patient.dos)).format('MM/DD/YYYY hh:mm A')} </Typography></Grid>                       
                    <Grid item xs = {12} >  <Typography>Patient DOB: {new Date(patient.dob).toLocaleDateString()} </Typography> </Grid>
                    <Grid item xs={6}>
                        <Button onClick={(event) => createPatientEvent()}>Submit</Button>
                    </Grid>

                 </Grid>

                
            </Grid>
            </Grid>
            {createPtSuccessPopup ? <CreatePatientSuccessPopup showPopupS = {showCreatePatientSuccessPopup} popupS = {createPtSuccessPopup} closeCreate = {props.closeCreate}/> : ''}
        </Card>
        
    )
}

export function CreatePatientSuccessPopup(props) { 
    const handleClose = () => {
      props.showPopupS(false)
      props.closeCreate(false)
    };
  
    return ( 
      <Dialog onClose={handleClose} open={props.popupS}>
        <DialogTitle>Thank You. You have created a new patient. </DialogTitle> 
      </Dialog>
    )
  }