import {port} from '../constants'
export async function   submitMultiplexResult(data){ 
    console.log('Submitting Data: \n' + data)
    // fetch(port + ':5001/check', {
    //     headers: {
    //         "Accept": "application/pdf",
    //         "Content-Type": "application/json"
    //     },
        
    //     method : 'POST' , 
    //     body :  JSON.stringify(data) , 
        
    // })
        fetch('https://api.clearmdhealth.com/v1/patients/progressNotes', {
        headers: {
            "Accept": "application/pdf",
            "Content-Type": "application/json" , 
            'x-api-key' : 'ca2269e0cd9ca81b4e60ba3e43de689f'
        },

        
        method : 'POST' , 
        body :  JSON.stringify(data) , 
        
    })
    .then(response => response.blob())
    .then(blob => { 
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        console.log(a)
        a.href = url;
        a.download = `${data.lastName+data.firstName}`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again 
    })
    .catch(error => { 
        console.log(error)
    })
}
