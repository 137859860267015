import sad from "../../images/frown.png";

export default function AccessDenied() {
    return(
        <div style={{textAlign:'center'}}>
            <div style={{fontSize:"3em"}}><b>Access Denied</b></div>
            <img src={sad} style={{width:"15em",height:"auto"}} alt="Sad Face" />
            <div><p>If you see this page, it means you tried to access a page that was restricted</p></div>
        </div>
    )
}