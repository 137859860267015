import { useState , useEffect} from "react"
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'
import InfoIcon from '@mui/icons-material/Info';
import {getAppointment} from './getAppointments'
import ResultsPop from './resultspop'
import { ResultLogPopup } from "./resultLog";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { borderRight } from "@mui/system";

const testTypeRowColors = { 
  // 'sti' : '#8339E0'
    // 'sti' : '#9347f2' , 
    'sti' : '#b671e0' , 
    'strep' : '#dfb4e4' ,
    'rsv' : '#F7DE3A'
}
const testTypeTextColors = { 
  'sti' : '#ffffff'
}

export function ResultsNotSent(props){


}


export function PatientTable2(props ){ 
  const [apptData, setApptData]= useState([])
  const [showPopup , setShowPopup] = useState(false)
  const [resultDialog , showResultDialog ] = useState(false)
  const [patient , setPatient] = useState('')
  useEffect(() => { 
    async function setData(){
      setApptData([])
      const locationApptInfo = await getAppointment(props.location , props.searchDate , props.showCanceled)
      setApptData(locationApptInfo)
      props.passUp(locationApptInfo)
    }
    setData()

  } , [props.location , props.searchDate  , props.showCanceled])
  const resultPopup = (event , row) => {

      console.log(event.target.parentElement.dataset.cusid)
      setShowPopup(!showPopup)
      console.log(event.target.value)
      //console.log("GAAAAAAAAAAAAAAAAAAAAAAAAAAAAAaa")
      setPatient(apptData[parseInt(event.target.parentElement.dataset.cusid)])
    

  }
  const openResultLog = async(event , row) => { 
    showResultDialog(true)
    console.log(apptData[parseInt(event.target.parentElement.dataset.cusid)])
    setPatient(apptData[parseInt(event.target.parentElement.dataset.cusid)])
  }
    return ( 
      <Grid container >
    <TableContainer component={Paper} sx = {{marginTop : '5%'}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Patient First Name</TableCell>
            <TableCell>Patient Last Name</TableCell>
            <TableCell>Appointment Test Type</TableCell>
            <TableCell>Appointment Location</TableCell>
            <TableCell>Appointment Date</TableCell>
            <TableCell>Send Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apptData.map((row , index) => (
            <TableRow hover key = {index.toString()} data-cusid = {index} 
              style = {{color : `${testTypeTextColors[row.testType]}`}}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } , bgcolor: `${testTypeRowColors[row.testType]? testTypeRowColors[row.testType] : '#ffffff'}` , }}
            >
              <TableCell  component="th" scope="row" onClick={(e) => resultPopup(e , row)} > {row.firstName} </TableCell>
                <TableCell onClick={(e) => resultPopup(e , row)} >{row.lastName}</TableCell>
                  <TableCell onClick={(e) => resultPopup(e , row)} >{row.testType}</TableCell>
                  <TableCell onClick={(e) => resultPopup(e , row)} >{row.appointmentLocation}</TableCell>
                  <TableCell onClick={(e) => resultPopup(e , row)} >{row.appointmentDate}</TableCell>

                 <TableCell style ={{ borderWidth: 0, borderWidth: 1, borderColor: 'Highlight',borderStyle: 'solid' , borderRight : 'none' , borderBottom : 'none' , borderTop :'none' , alignItems : 'center'}} data-cusid = {index}  onClick={(event) => openResultLog(event , row)}><InfoIcon  data-cusid = {index}  onClick={(event) => openResultLog(event , row)}/> </TableCell>
                </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {showPopup && <ResultsPop setPopClose = {setShowPopup} patient = {patient} setApptData = {setApptData} apptData = {apptData} location={props.location}/>}
    {showResultDialog ? <ResultLogPopup open = {resultDialog} showPopup={showResultDialog} patient = {patient}/> : null}
    </Grid>
    )
}