export const resultsMap = {
    'Syphilis/HIV' : ['HIV', 'Syphilis'] , 
    'Syphilis/HIV (Rapid)' : ['HIV', 'Syphilis'] , 
    'HSV (Antibody Send-Out)' : ['Herpes Simplex Virus Type 1 (Antibody Send-Out)' , 'Herpes Simplex Virus Type 2 (Antibody Send-Out)'] , 
    'HIV (Rapid)' : ['HIV'] , 
    'HIV' : ['HIV'] , 
    'Chlamydia and Gonorrhea (PCR)' : ['Chlamydia (PCR)' , 'Gonorrhea (PCR)'], 
    'Chlamydia and Gonorrhea (PCR)-1C' : ['Chlamydia (PCR) Collection 1' , 'Gonorrhea (PCR) Collection 1'], 
    'Chlamydia and Gonorrhea (PCR)-2C' : ['Chlamydia (PCR) Collection 1' , 'Gonorrhea (PCR) Collection 1','Chlamydia (PCR) Collection 2' , 'Gonorrhea (PCR) Collection 2'], 
    'Chlamydia and Gonorrhea (PCR)-3C' : ['Chlamydia (PCR) Collection 1' , 'Gonorrhea (PCR) Collection 1','Chlamydia (PCR) Collection 2' , 'Gonorrhea (PCR) Collection 2','Chlamydia (PCR) Collection 3' , 'Gonorrhea (PCR) Collection 3'] , 
    'Chlamydia, Gonorrhea and Trichomoniasis (PCR)' : ['Chlamydia (PCR)' , 'Gonorrhea (PCR)' , 'Trichomoniasis (PCR)'],
    'Hepatitis C' : ['Hepatitis C'] , 
    'Hepatitis C (Rapid)' : ['Hepatitis C'] , 
    'Trichomoniasis (PCR)' : ['Trichomoniasis (PCR)'] , 
    'Syphilis' : [ 'Syphilis'] , 
    'Syphilis (Rapid)' : [ 'Syphilis'] , 
  
    'Full Panel with Hepatitis C' : 
    ['Chlamydia (PCR)' , 'Gonorrhea (PCR)' , 'Trichomoniasis (PCR)', 'Syphilis' , 
    'HIV', 'Herpes Simplex Virus Type 1 (Antibody Send-Out)', 'Herpes Simplex Virus Type 2 (Antibody Send-Out)',  'Chlamydia (PCR)', 'Hepatitis C'], 
    'Chlamydia, Gonorrhea, Trichomoniasis, HIV, and Syphilis' : ['Chlamydia (PCR)' , 'Gonorrhea (PCR)' , 'Trichomoniasis (PCR)' , 'HIV' , 'Syphilis'], 
    '7 Panel (Excludes Hepatitis C)' : ['Chlamydia (PCR)' , 'Gonorrhea (PCR)' , 'Trichomoniasis (PCR)', 'Syphilis' , 
    'HIV', 'Chlamydia (PCR)', 'Herpes Simplex Virus Type 1 (Antibody Send-Out)' , 'Herpes Simplex Virus Type 2 (Antibody Send-Out)'], 
    'Herpes Simplex Virus Type 1 (Antibody Send-Out)' : ["Herpes Simplex Virus Type 1 (Antibody Send-Out)"] , 
    'Multiplex Vaginal Panel (PCR)' : ['Candida (PCR)' , 'Bacterial Vaginosis (PCR)','Trichomoniasis (PCR)'] , 
    'Mycoplasma Genitalium (PCR)' : ['Mycoplasma Genitalium (PCR)'] , 
    'Ureaplasma Urealyticum (Culture)' : ['Ureaplasma Urealyticum (Culture)'] , 
    'Mycoplasma Genitalium & Ureaplasma Urealyticum' : ['Mycoplasma Genitalium (PCR)' , 'Ureaplasma Urealyticum (Culture)'] , 
    'Urinary Tract Infection (Rapid)' : ['Urinary Tract Infection (Rapid)'] , 
    'Follow Up Care' : [], 
    'strep(1hr)' : ['Strep A (PCR)'] , 
    'no insurance(antigen)' : [], 
    'flu' : ['Influenza A' , 'Influenza B'] , 
    'strep' : ['Strep A (PCR)'] , 
    'rsv' : ['Respiratory Syncytial Virus (PCR)'] ,    
    'antigen' : []

  
  }

export const locations = { 
    noho : '734 broadway' , 
    uws : '105 W 72nd St' , 
    ues : '' , 
    chelsea : '600 6th avenue' , 
    astoria : '31-78 steinway street' , 
    chinatown : '187 centre street' , 
    midtown : '1051 third avenue'
}

export const TestTypes = [
  'sti' , 
  'respiratory', 
  `women's health` 
]

export const addons = { 
  'sti' : ['Syphilis/HIV (Rapid)' , 'HSV (Antibody Send-Out)' , 'HIV (Rapid)' ,
  'Chlamydia and Gonorrhea (PCR)' , 'Chlamydia and Gonorrhea (PCR)-1C' , 'Chlamydia and Gonorrhea (PCR)-2C' , 
  'Chlamydia, Gonorrhea and Trichomoniasis (PCR)' , 'Hepatitis C' , 'Trichomoniasis (PCR)' , 'Syphilis (Rapid)' , 
  'Full Panel with Hepatitis C' , 'Chlamydia, Gonorrhea, Trichomoniasis, HIV, and Syphilis' , '7 Panel (Excludes Hepatitis C)' , 
  'Herpes Simplex Virus Type 1 (Antibody Send-Out)' , 'Multiplex Vaginal Panel (PCR)' , 'Mycoplasma Genitalium (PCR)' , 'Ureaplasma Urealyticum (Culture)', 
  'Urinary Tract Infection (Rapid)' , 'strep(1hr)', 'flu' , 'strep' , 'rsv' ] , 

  'respiratory' : ['pcr' , 'antigen' , 'strep' , 'rsv' , 'flu'] ,
  "women's health" : ['pregnancy test'] , 
  'antigen' : [] , 

  'pcr' : []
}
// The send out testTypes and the associated default values. 
export const sendOutTests = [
    {testType : 'HIV' , defaultValue : '(Rapid)'} , 
    {testType : 'Syphilis' , defaultValue : '(Rapid)'} , 
    {testType : 'Hepatitis C' , defaultValue : '(Rapid)'}
]

export const stiPrices = {
  'Syphilis/HIV (Rapid)' : {priceOptions : []} , 
  'HIV (Rapid)' : {priceOptions : []}  
  
}

export const listOfTests = [
  "HIV",
  "Syphilis",
  "Herpes Simplex Virus Type 1 (Antibody Send-Out)",
  "Herpes Simplex Virus Type 2 (Antibody Send-Out)",
  "Chlamydia (PCR)",
  "Gonorrhea (PCR)",
  "Chlamydia (PCR) Collection 1",
  "Gonorrhea (PCR) Collection 1",
  "Chlamydia (PCR) Collection 2",
  "Gonorrhea (PCR) Collection 2",
  "Chlamydia (PCR) Collection 3",
  "Gonorrhea (PCR) Collection 3",
  "Trichomoniasis (PCR)",
  "Hepatitis C",
  "Candida (PCR)",
  "Bacterial Vaginosis (PCR)",
  "Mycoplasma Genitalium (PCR)",
  "Ureaplasma Urealyticum (Culture)",
  "Urinary Tract Infection (Rapid)",
  "Strep A (PCR)",
  "Influenza A",
  "Influenza B",
  "Respiratory Syncytial Virus (PCR)",
  'Urine beta-hCG (Pregnancy Test)', 
  'SARS - CoV-2 (COVID-19) PCR' , 
  'SARS - CoV-2 (COVID-19) Antigen'
]
// In hours
export const turnAround = {
  "HIV" : {"latest" : 1, "earliest" : 0},
  "Syphilis" : {"latest" : 1, "earliest" : 0},
  "Herpes Simplex Virus Type 1 (Antibody Send-Out)" : {"latest" : 72, "earliest" : 1},
  "Herpes Simplex Virus Type 2 (Antibody Send-Out)" : {"latest" : 72, "earliest" : 1},
  "Chlamydia (PCR)" : {"latest" : 24, "earliest" : 0},
  "Gonorrhea (PCR)": {"latest" : 24, "earliest" : 1},
  "Chlamydia (PCR) Collection 1": {"latest" : 24, "earliest" : 0},
  "Gonorrhea (PCR) Collection 1": {"latest" : 24, "earliest" : 0},
  "Chlamydia (PCR) Collection 2": {"latest" : 24, "earliest" : 0},
  "Gonorrhea (PCR) Collection 2": {"latest" : 24, "earliest" : 0},
  "Chlamydia (PCR) Collection 3": {"latest" : 24, "earliest" : 0},
  "Gonorrhea (PCR) Collection 3": {"latest" : 24, "earliest" : 0},
  "Trichomoniasis (PCR)" : {"latest" : 24, "earliest" : 0},
  "Hepatitis C" : {"latest" : 1, "earliest" : 0},
  "Candida (PCR)" : {"latest" : 24, "earliest" : 0},
  "Bacterial Vaginosis (PCR)" : {"latest" : 24, "earliest" : 0},
  "Mycoplasma Genitalium (PCR)" : {"latest" : 192, "earliest" : 120},
  "Ureaplasma Urealyticum (Culture)" : {"latest" : 192, "earliest" : 120},
  "Urinary Tract Infection (Rapid)" : {"latest" : 24, "earliest" : .25},
  "Strep A (PCR)" : {"latest" : 1, "earliest" : 0},
  "Influenza A" : {"latest" : 1, "earliest" : 0},
  "Influenza B" : {"latest" : 1, "earliest" : 0},
  "Respiratory Syncytial Virus (PCR)" : {"latest" : 1, "earliest" : 0},
  "antigen" : {"latest" : .5, "earliest" : 0},
  "pcr 24hr": {"latest" : 24, "earliest" : 0},
  "pcr 1hr": {"latest" : 1, "earliest" : 0}
} 
// export const turnAround = [
//   {testType : "HIV" , latest : 1 , earliest : 0},
//   {testType : "Syphilis",latest : 24 , earliest : 0}, 
//   {testType : "Herpes Simplex Virus Type 1 (Antibody Send-Out)" , latest : 72 , earliest : 1} ,
//   {testType : "Herpes Simplex Virus Type 2 (Antibody Send-Out)" , latest : 72 , earliest : 1} ,
//   {testType : "Chlamydia (PCR)", latest : 24 , earliest : 0},
//   {testType : "Gonorrhea (PCR)" , latest : 24 , earliest : 1} ,
//   {testType : "Chlamydia (PCR) Collection 1" , latest : 24 , earliest : 0},
//   {testType : "Gonorrhea (PCR) Collection 1" , latest : 24 , earliest : 0} ,
//   {testType : "Chlamydia (PCR) Collection 2",latest : 24 , earliest : 0} , 
//   {testType : "Gonorrhea (PCR) Collection 2",latest : 24 , earliest : 0} , 
//   {testTyoe : "Chlamydia (PCR) Collection 3",latest : 24 , earliest : 0} , 
//   {testType : "Gonorrhea (PCR) Collection 3", latest : 24 , earliest : 0}  , 
//   {testType : "Trichomoniasis (PCR)",latest : 24 , earliest : 0 } , 
//   {testType : "Hepatitis C", latest : 24 , earliest : 1 } , 
//   {testType : "Candida (PCR)", latest :  72 , earliest : 0} ,
//   {testType : "Bacterial Vaginosis (PCR)" , latest : 24 , earliest : 0 },
//   {testType : "Mycoplasma Genitalium (PCR)"  , latest : 192 , earliest : 120},
//   {testType : "Ureaplasma Urealyticum (Culture)" , latest : 192 , earliest : 120}, 
//   {testType : "Urinary Tract Infection (Rapid)" , latest : 24 , earliest : .25},
//   {testType : "Strep A (PCR)", latest : 1 , earliest : 0}, 
//   {testType : "Influenza A", latest : 1 , earliest : 0},
//   {testType : "Influenza B", latest : 1 , earliest : 0},
//   {testType : "Respiratory Syncytial Virus (PCR)" , latest : 1 , earliest : 0}

// ]

// antigen
// pcr 24hr
// pcr 1hr