import React, {useState} from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MUIDataTable from "mui-datatables";
import  { Storage } from 'aws-amplify';
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

// components
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import mock from "../dashboard/mock";

const datatableData = [
  ["ABDHDHDHDHDH.pdf", "20.kb"],
];

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))


export default function UploadPatientData() {
    const classes = useStyles();
    const fileInput = React.useRef();
    const [uploadFileList, setUploadFileList] = useState([])
    const [isFilesReadyToUpload,SetIsFilesReadyToUpload] = useState(false)
    const filesToUpload=[]
    
    const handleClick = (event) => {
        event.preventDefault();
        let newArr = fileInput.current.files;

        for (let i = 0; i < newArr.length; i++) {
            filesToUpload.push([newArr[i].name,(newArr[i].size/1024).toFixed(2),'Not Uploaded'])            
        }
        setUploadFileList(filesToUpload) 
        SetIsFilesReadyToUpload(true) 
    };
    const uploadReportsNow = async() =>
    {
        SetIsFilesReadyToUpload(false)
        let newArr = fileInput.current.files;       
        if (newArr.length>0) { 
            for (let index = 0; index < newArr.length; index++) {
                try {
                    await Storage.put(newArr[index].name, newArr[index], {
                    level: 'public',
                    progressCallback(progress) {
                      console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                    },
                    contentType: 'application/pdf' // contentType is optional 
                    });
                    console.log('Uploaded Sucessfully',newArr[index].name)
                } catch (error) {
                    console.log('Error uploading file: ', error);
                }                 
            }
            console.log('Upload Complete')
            toast('All the files upload sucessfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
    }

  return (
    <>
      <PageTitle title="Upload PDF Reports" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />        
        <ToastContainer />
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <input type='file' id="uploadFiles" accept="application/pdf" multiple ref={fileInput} onChange={handleClick}  />      
        <Button            
            onClick={uploadReportsNow}
            variant="contained"
            size="medium"
            color="primary"    
            disabled ={!isFilesReadyToUpload}         
        >
            Upload Patient PDF Reports Now
        </Button>

          <MUIDataTable
            title="Upload Files"
            data={uploadFileList}
            columns={[ "File Name", "Size(kb)","Status"]}                        
          />
        </Grid>
      </Grid>
    </>
  );
}
