import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { Button } from '../../components/Wrappers/Wrappers';
import InfoCard from './InfoCard'
import PatientInfo from './PatientInfo'
import SearchForm from './SearchForm'
import ImageModal from './ImageModal'

export default function PatientLookup() {
  const [patients, setPatients] = useState([]);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  return (
    <Grid
      container
      width='100%'
      height='calc(100vh - 192px)'
      justifyContent='center'
      alignItems='center'
      columnGap={1}
      marginTop='3rem'
    >
      {patients.length === 0 ? (
        <></>
      ) : (
        <Grid position='absolute' top='5rem' left='2rem'>
          <Button
            variant='contained'
            color='white'
            onClick={() => {
              setSearchModalOpen(!searchModalOpen);
            }}
          >
            {searchModalOpen ? 'Patients' : 'Search'}
          </Button>
        </Grid>
      )}
      {patients.length === 0 || searchModalOpen ? (
        <SearchForm
          setPatients={patients => {
            setPatients(patients);
            setSearchModalOpen(false);
          }}
        />
      ) : (
        patients.map((patient, index) => {
          return (
            <Grid item key={`patient-${index}`}>
              <InfoCard
                patient={patient}
                setSelectedPatient={e => {
                  setSelectedPatient(patient);
                  setInfoModalOpen(true);
                }}
              />
            </Grid>
          );
        })
      )}
      <Grid>
        <PatientInfo
          patient={selectedPatient}
          open={infoModalOpen}
          onClose={() => {
            setInfoModalOpen(false);
          }}
        />
      </Grid>
    </Grid>
  );
}



