import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PersonIcon from '@mui/icons-material/Person';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import LockIcon from '@mui/icons-material/Lock';

import Amplify from "aws-amplify";
import awsconfig from "../../aws-exports";

// styles
import useStyles from "./styles";

// context
import {confirmSignUp, useUserState, useUserDispatch, loginUser,signUp, resendUserVerificationCode, setNewPassword, submitFinalPassword } from "../../context/UserContext";

import backgroundImage  from "../../images/414.jpg"

const stylesLogin = {
    paperContainer: {
        backgroundImage: `url(${backgroundImage})` ,
    }
};

Amplify.configure(awsconfig);

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  var userState = useUserState();
  var navigate = useNavigate();

  // local

  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [disableOTPBox, setdisableOTPBox] = useState(true);
  const [resetPasswordStatus, setResetPasswordStatus] = useState(0);
  const [success, setSuccess] = useState(0);
  const [successfulRegistration, setSuccessfulRegistration] = useState(0);
  const [successfulRegistrationMessage, setSuccessfulRegistrationMessage] = useState('');
  const [loginError, setLoginError] = useState(0);
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(0);
  const [confirmPasswordErrMessage, setConfirmPasswordErrMessage] = useState('');
  const [newPasswordErr, setNewPasswordErr] = useState(0);

  async function submitNewPassword(){
    setLoginError(0)
    let errors = 0

    if(!(loginValue.includes('@'))){
      errors += 1
      setConfirmPasswordErr(1)
      setConfirmPasswordErrMessage("Email is Invalid!")
    }
  
    if(confirmPasswordValue !== passwordValue){
      errors += 1
      setConfirmPasswordErr(1)
      setConfirmPasswordErrMessage("Passwords do not match!")
    }
    if(confirmPasswordValue.length < 8 || passwordValue.length < 8){
      errors += 1
      setConfirmPasswordErr(1)
      setConfirmPasswordErrMessage("Passwords must be at least 8 characters!")
    }

    if(!(errors)){
      let status = await setNewPassword(loginValue, setNewPasswordErr)

      if(status.STATUS === "ERROR"){
        errors += 1
        setSuccess(0)
        setConfirmPasswordErr(1)
        setConfirmPasswordErrMessage("Email not found or reached daily password reset limit!")
      }
      else{
        setConfirmPasswordErr(0)
        setdisableOTPBox(!disableOTPBox) 
        setSuccessfulRegistration(1)
        setSuccessfulRegistrationMessage("Verification Code was Sent to your Email!")
      }

    }

  }

  const [loginUserEmail, setLoginUserEmail] = useState('');
  const [loginUserPassword, setLoginUserPassword] = useState('');
  const [registerUserEmail, setRegisterUserEmail] = useState('');
  const [registerUserPassword, setRegisterUserPassword] = useState('');
  const [registerEmailError, setRegisterEmailError] = useState(0);
  const [registerPasswordErrorMessage, setRegisterPasswordMessage] = useState('');
  const [registerEmailErrorMessage, setRegisterEmailMessage] = useState('');
  const [signUpError, setSignUpError] = useState(0);
  const [signUpMessage, setSignUpMessage] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(0);
  const [registerPasswordError, setRegisterPasswordError] = useState(0);
  const [loginForm, setLoginForm] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState(0);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [verificationCodeStage, setVerificationCodeStage] = useState(0);
  const [verifyCodePassword, setVerifyCodePassword] = useState(0);
  const [verificationSuccessMessage, setVerificationSuccessMessage] = useState('Verification Code was Successfully Sent to your Email');
  const [unverifiedResendCode, setUnverifiedResendCode] = useState(0);
  const [unverifiedResendCodeSuccess, setUnverifiedResendCodeSuccess] = useState('');
  const [loading, setLoading] = useState(0);

  async function beginRegistrationProcess() {
      let errors = 0;

      if(!(registerUserEmail.includes('@'))){
          errors += 1;
          setRegisterEmailError(1);
          setRegisterEmailMessage("Email is Invalid");
      }
      else if(registerUserEmail.length < 4){
          errors += 1;
          setRegisterEmailError(1);
          setRegisterEmailMessage("Email Does Not Exist");
      }
      else if(!(/^[a-z0-9.@_!-]+$/i.test(registerUserEmail))){
          errors += 1;
          setRegisterEmailError(1);
          setRegisterEmailMessage("Email Contains Invalid Characters");
      }
      else{
          setRegisterEmailError(0);
          setRegisterEmailMessage("");
      }

      if(registerUserPassword.length < 8){
          errors += 1;
          setRegisterPasswordError(1);
          setRegisterPasswordMessage("Password is Too Short");
      }
      else{
          setRegisterPasswordError(0);
          setRegisterPasswordMessage("");
      }

      if(!(errors)){
          setLoading(1);
          let response;
          try{
              response = await signUp(registerUserEmail, registerUserPassword);
          } catch(err) {
              setSignUpError(1);
              setSignUpMessage("Error Creating Account");
              setLoading(0);
          } finally {
              if(response.STATUS === "success"){
                  setSignUpError(0);
                  setSignUpMessage("");
                  setVerificationCodeStage(1);
                  setLoading(0);
              }
              else{
                  setSignUpError(1);
                  setSignUpMessage(response.STATUS.message);
                  setLoading(0);
              }
          }
      }
      else{
          setSignUpError(0);
          setLoading(0);
      }
  }

  async function verifyCode() {

      setLoading(1);
      let response;
      try{
          response = await confirmSignUp(registerUserEmail, verificationCode);
      } catch(err) {
          setVerificationCodeError(1);
          setVerificationMessage("Incorrect Verification Code");
          setLoading(0);
      } finally {
          if(response.STATUS === "success"){
              setVerificationCodeError(0);
              setVerificationMessage('');
              setRegisterUserEmail('');
              setRegisterUserPassword('');
              setVerificationCode('');
              setVerificationCodeStage(0);
              setLoginForm(1);
              setLoginSuccess(1);
              setVerifyCodePassword(0);
              setUnverifiedResendCode(0);
              setLoginMessage("Registration was Successful!");
              setLoading(0);
          }
          else{
              setVerificationCodeError(1);
              setVerificationMessage("Incorrect Verification Code");
              setLoading(0);
          }
      }
  }

  async function verifyCodeUnverifiedUser() {

    setLoading(1);
    let response;
    try{
        response = await confirmSignUp(loginUserEmail, verificationCode);
    } catch(err) {
        setVerificationCodeError(1);
        setVerificationMessage("Incorrect Verification Code");
        setUnverifiedResendCode(0);
        setLoading(0);
    } finally {
        if(response.STATUS === "success"){
            setVerificationCodeError(0);
            setVerificationMessage('');
            setRegisterUserEmail('');
            setRegisterUserPassword('');
            setVerificationCode('');
            setVerificationCodeStage(0);
            setLoginForm(1);
            setLoginSuccess(1);
            setVerifyCodePassword(0);
            setUnverifiedResendCode(0);
            setLoginMessage("Registration was Successful!");
            setLoading(0);
        }
        else{
            setVerificationCodeError(1);
            setUnverifiedResendCode(0);
            setVerificationMessage("Incorrect Verification Code");
            setLoading(0);
        }
    }
}

  function setLoginForms(value){
      setLoginForm(value);
      setLoginUserEmail('');
      setLoginUserPassword('');
      setRegisterUserEmail('');
      setRegisterUserPassword('');
      setRegisterEmailError(0);
      setRegisterPasswordMessage('');
      setRegisterEmailMessage('');
      setSignUpError(0);
      setSignUpMessage('');
      setLoginMessage('');
      setLoginSuccess(0);
      setRegisterPasswordError(0);
      setLoginError(0);
      setLoading(0);
  }

  async function transferToLogin() {
      
      setLoading(1);

      if(!(/^[a-z0-9.@_!-]+$/i.test(loginUserEmail))){
          setLoginError(1);
          setLoginSuccess(0);
          setLoginMessage("Email Contains Invalid Characters");
          setLoading(0);
      }
      else if(loginUserPassword.length < 8){
          setLoginError(1);
          setLoginSuccess(0);
          setLoginMessage("Password is Invalid");
          setLoading(0);
      }
      else{
          let response;
          try{
            response = await loginUser(loginUserEmail, loginUserPassword, navigate, userDispatch);   
          } catch(err) {
              setLoginError(1);
              setLoginSuccess(0);
              setLoginMessage("Error Logging In");
              setLoading(0);
          } finally {
              console.log("Login Response: ", response);
              if(response.STATUS === "success") {
                  setLoginError(0);
                  setUnverifiedResendCode(0);
                  setLoginSuccess(0);
                  setLoading(0);
                  switch(userState.userGroup) {
                    case "Compliance":
                      navigate('/app/reportsmenu');
                      break;
                    default:
                      navigate('/app/dashboard');
                      break;
                  }
              }
              else if(response.STATUS.message === "User is not confirmed.") {
                  sendOtpCodeUnverified();
                  setVerificationCodeStage(1);
                  setVerifyCodePassword(1);
                  setLoading(0);
              }
              else{
                  setLoginError(1);
                  setLoginSuccess(0);
                  setLoginMessage(response.STATUS.message);
                  setLoading(0);
              }
              console.log("Logged In");
          }
      }
  }

  async function sendOtpCodeUnverified() {
      setLoading(1);
      let response;
      try{
          response = await resendUserVerificationCode(loginUserEmail);
      } catch(err) {
            setVerificationCodeError(1);
            
            setVerificationCode(0);
            setVerificationMessage("Error Resending Verification Code");
            setLoading(0);
      } finally {
          if(!(response.STATUS === "success")) {
              setVerificationCodeError(1);
              setVerificationCode(0);
              setVerificationMessage(response.STATUS.message);
              setLoading(0);
          }
      }
  }

  async function resendOtpUnverified() {

      setLoading(1);
      let response;
      try{
          response = await resendUserVerificationCode(loginUserEmail);
      } catch(err) {
            setVerificationCodeError(1);
            setVerificationCode(0);
            setUnverifiedResendCode(0);
            setVerificationMessage("Error Resending Verification Code");
            setLoading(0);
      } finally {
          if(response.STATUS === "success") {
              setVerificationCodeError(0);
              setUnverifiedResendCodeSuccess("Resent Verification Code to your Email Address!");
              setUnverifiedResendCode(1);
              setLoading(0);
          }
          else{
              setVerificationCodeError(1);
              setVerificationCode(0);
              setUnverifiedResendCode(0);
              setVerificationMessage("Error Resending Verification Code");
              setLoading(0);
          }
      }
  }

  async function resendOtpCode() {
      setLoading(1);
      let response;
      try{
          response = await resendUserVerificationCode(registerUserEmail);
      } catch(err) {
            setVerificationCodeError(1);
            setVerificationCode(0);
            setVerificationMessage("Error Resending Verification Code");
            setLoading(0);
      } finally {
          if(response.STATUS === "success") {
              setVerificationSuccessMessage("Resent Verification Code to your Email Address!");
              setVerificationCode(1);
              setVerificationCodeError(0);
              setLoading(0);
          }
          else{
              setVerificationCodeError(1);
              setVerificationCode(0);
              setVerificationMessage(response.STATUS.message);
              setLoading(0);
          }
      }
  }

  const [forgotPasswordForm, setForgotPasswordForm] = useState(0);
  const [forgotEmailErr, setForgotEmailErr] = useState(0);
  const [forgotEmail, setForgotEmail] = useState('');
  const [forgotEmailErrorMessage, setForgotEmailErrorMessage] = useState('');
  const [forgotPasswordErr, setForgotPasswordErr] = useState(0);
  const [forgotPassword, setForgotPassword] = useState('');
  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState('');
  const [forgotPasswordRetypeErr, setForgotPasswordRetypeErr] = useState(0);
  const [forgotPasswordRetype, setForgotPasswordRetype] = useState('');
  const [forgotPasswordRetypeErrorMessage, setForgotPasswordRetypeErrorMessage] = useState('');
  const [forgotPasswordFormErr, setForgotPasswordFormErr] = useState(0);
  const [forgotPasswordFormMessage, setForgotPasswordFormMessage] = useState(0);
  const [forgotPasswordVerificationForm, setForgotPasswordVerificationForm] = useState(0);

  async function submitNewPassword() {

      let errors = 0;
      setLoading(1);

      if(!(forgotEmail.includes('@'))){
          errors += 1;
          setForgotEmailErr(1);
          setForgotEmailErrorMessage("Email is Invalid");
      }
      else if(forgotEmail.length < 4){
          errors += 1;
          setForgotEmailErr(1);
          setForgotEmailErrorMessage("Email Does Not Exist");
      }
      else if(!(/^[a-z0-9.@_!-]+$/i.test(forgotEmail))){
          errors += 1;
          setForgotEmailErr(1);
          setForgotEmailErrorMessage("Email Contains Invalid Characters");
      }
      else{
          setForgotEmailErr(0);
          setForgotEmailErrorMessage("");
      }

      if(forgotPassword.length < 8){
          errors += 1;
          setForgotPasswordErr(1);
          setForgotPasswordErrorMessage("Password is Too Short");
      }
      else{
          setForgotPasswordErr(0);
          setForgotPasswordErrorMessage("");
      }

      if(forgotPasswordRetype.length < 8){
          errors += 1;
          setForgotPasswordRetypeErr(1);
          setForgotPasswordRetypeErrorMessage("Password is Too Short");
      }
      else if(forgotPasswordRetype !== forgotPassword){
          errors += 1;
          setForgotPasswordRetypeErr(1);
          setForgotPasswordRetypeErrorMessage("Passwords do not Match");
      }
      else{
          setForgotPasswordRetypeErr(0);
          setForgotPasswordRetypeErrorMessage("");
      }


      if(!(errors)){
          setForgotEmailErr(0);
          setForgotPasswordRetypeErr(0);
          setForgotPasswordRetypeErrorMessage("");
          setForgotEmailErrorMessage("");
          setLoading(0);
          setForgotPasswordErr(0);
          setForgotPasswordErrorMessage("");

          let response;

          try{
              response = await setNewPassword(forgotEmail);
          } catch(err) {
                  setForgotPasswordFormMessage("Error Generating New Password");
                  setForgotPasswordFormErr(1);
                  setLoading(0);
          } finally {
              if(response.STATUS === "success") {
                console.log("success");
                  setForgotPasswordFormErr(0);
                  setForgotPasswordFormMessage('');
                  setLoading(0);
                  setForgotPasswordForm(0);
                  setForgotPasswordVerificationForm(1);
                  setVerificationForgotSuccessMessage("Successfully Sent Verification Code to your Email!");
              }
              else{
                  setForgotPasswordFormMessage(response.STATUS.message);
                  setForgotPasswordFormErr(1);
                  setLoading(0);
              }
          }

      }
      else{
          setLoading(0);
          setForgotPasswordFormErr(0);
      }
  }

  async function resendForgotOtpCode() {
    setLoading(1);
    let response;
    try{
        response = await resendUserVerificationCode(forgotEmail);
    } catch(err) {
          setVerificationForgotMessage("Error Sending New Code");
          setVerificationForgotCodeError(1);
          setLoading(0);
    } finally {
        if(response.STATUS === "success") {
            setVerificationForgotCodeError(0);
            setVerificationForgotSuccessMessage("Resent Verification Code to your Email Address!");
            setLoading(0);
        }
        else{
            setVerificationForgotMessage(response.STATUS.message);
            setVerificationForgotCodeError(1);
            setLoading(0);
        }
    }
}

  const [verificationForgotSuccessMessage, setVerificationForgotSuccessMessage] = useState('');
  const [verificationForgotCodeError, setVerificationForgotCodeError] = useState(0);
  const [verificationForgotMessage, setVerificationForgotMessage] = useState('');
  const [verificationForgotCode, setVerificationForgotCode] = useState('');

  async function completeNewPassword() {
      setLoading(1);
      let response;
      try{
          response = await submitFinalPassword(forgotEmail, forgotPassword, verificationForgotCode);
      } catch(err) {
            setVerificationForgotMessage("Error Generating New Password");
            setVerificationForgotCodeError(1);
            setLoading(0);
      } finally {
          if(response.STATUS === "success") {
                setVerificationCodeError(0);
                setVerificationMessage('');
                setRegisterUserEmail('');
                setRegisterUserPassword('');
                setVerificationCode('');
                setVerificationCodeStage(0);
                setLoginForm(1);
                setLoginSuccess(1);
                setVerifyCodePassword(0);
                setUnverifiedResendCode(0);
                setLoginMessage("Registration was Successful!");
                setLoading(0);
                setVerificationForgotSuccessMessage('');
                setVerificationForgotCodeError(0);
                setVerificationForgotMessage('');
                setVerificationForgotCode('');
                setForgotPasswordForm(0);
                setForgotEmailErr(0);
                setForgotEmail('');
                setForgotEmailErrorMessage('');
                setForgotPasswordErr(0);
                setForgotPassword('');
                setForgotPasswordErrorMessage('');
                setForgotPasswordRetypeErr(0);
                setForgotPasswordRetype('');
                setForgotPasswordRetypeErrorMessage('');
                setForgotPasswordFormErr(0);
                setForgotPasswordFormMessage(0);
                setForgotPasswordVerificationForm(0);
          }
          else{
              setVerificationForgotMessage(response.STATUS.message);
              setVerificationForgotCodeError(1);
              setLoading(0);
          }
      }
  }

  return(

    <Grid container className={classes.container}>

        <div className={classes.logotypeContainer} style={stylesLogin.paperContainer} >
          <Typography className={classes.logotypeText} > ClearMD
              <LocalHospitalIcon color="primary"  style={{ fontSize: 84, margin:-12  }} />
          </Typography> 
          <Typography variant="h2">Patient Portal</Typography>
        </div>

        <div className={classes.formContainer}>
            <Grid item xs={12} style={{paddingBottom: '50px', height: '100%', textAlign: 'center'}}>
                <h1 className={classes.clearmdHeader}>Get Started with ClearMD</h1>
                <h3 style={{marginBottom: '0', paddingBottom: '0'}}>Access all of your results in one place.</h3>
            </Grid>

            <Grid container>
                {forgotPasswordVerificationForm?  <Grid container>
                     <Grid item xs={12} className={classes.loginRegistrationContent}>
                        <Grid item xs={12}>
                            {verificationForgotCodeError? <Alert severity="error">{verificationForgotMessage}</Alert> :
                            <Alert severity="success">{verificationForgotSuccessMessage}</Alert>}
                        </Grid>
                        <hr />
                        {loading? <LinearProgress sx={{width: '100%', height: '3px', marginBottom: '5px'}} /> : ''}
                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Please Enter your Verification Code Below</label><br/>
                            <input onChange={(e) => setVerificationForgotCode(e.currentTarget.value)} style={{marginBottom: '10px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} placeholder="Verification Code"></input>
                            <span>Didn't Receive a Code?<button onClick={resendForgotOtpCode} style={{backgroundColor: 'transparent', color: 'royalblue', borderStyle: 'none'}}><u>Click Here</u></button></span>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '35px'}}>
                            <button onClick={completeNewPassword} type="button" className={classes.signInButton}>Verify Code</button>
                        </Grid>
                    </Grid>
                </Grid> :
 
            <Grid container>
                {forgotPasswordForm? 
                     <Grid item xs={12} className={classes.loginRegistrationContent}>
                         <Grid item xs={12}>
                            {forgotPasswordFormErr? <Alert style={{marginBottom: '5px'}} severity="error">{forgotPasswordFormMessage}</Alert> : ""}
                             <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Forgot Password Form</label><br/>
                          </Grid>
                          <hr />
                                {loading? <LinearProgress sx={{width: '100%', height: '3px', marginBottom: '5px'}} /> : ''}
                                <Grid item xs={12} style={{marginTop: '20px'}}>
                                    {forgotEmailErr? <Alert style={{marginBottom: '5px'}} severity="error">{forgotEmailErrorMessage}</Alert> : ""}
                                    <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Email</label><br/>
                                    <input onChange={(e) => setForgotEmail(e.currentTarget.value)} style={{marginBottom: '20px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} placeholder="Enter Email Address"></input>
                                    <PersonIcon style={{marginLeft: '-40px', marginBottom: '-7px'}} />
                                    {forgotPasswordErr? <Alert style={{marginBottom: '5px'}} severity="error">{forgotPasswordErrorMessage}</Alert> : ""}
                                    <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>New Password</label><br/>
                                    <input onChange={(e) => setForgotPassword(e.currentTarget.value)} style={{marginBottom: '20px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} type="password" placeholder="Enter New Password"></input>
                                    <LockIcon style={{marginLeft: '-40px', marginBottom: '-7px'}} />
                                    {forgotPasswordRetypeErr? <Alert style={{marginBottom: '5px'}} severity="error">{forgotPasswordRetypeErrorMessage}</Alert> : ""}
                                    <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Re-enter Password</label><br/>
                                    <input onChange={(e) => setForgotPasswordRetype(e.currentTarget.value)} style={{marginBottom: '10px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} type="password" placeholder="Re-enter Password"></input>
                                    <LockIcon style={{marginLeft: '-40px', marginBottom: '-7px'}} />
                                </Grid>
                                <Grid item xs={12} style={{marginTop: '35px'}}>
                                    <button onClick={submitNewPassword} type="button" className={classes.signInButton}>Submit New Password</button>
                                </Grid>
                      </Grid> : <Grid container>
            {verificationCodeStage? 
                <Grid container>
                {verifyCodePassword? <Grid container>
                  <Grid item xs={12} className={classes.loginRegistrationContent}>
                        <Grid item xs={12}>
                            <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Your Account is Unverified. A Verification Code was Sent to your Email.</label><br/>
                        </Grid>
                        <hr />
                        {loading? <LinearProgress sx={{width: '100%', height: '3px', marginBottom: '5px'}} /> : ''}
                        {verificationCodeError? <Alert severity="error">{verificationMessage}</Alert> :
                            ''}{unverifiedResendCode? <Alert severity="success">{unverifiedResendCodeSuccess}</Alert> : ''}
                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            <label style={{fontSize: '1.2em', fontWeight: '800'}}>Please Enter your Verification Code Below</label><br/>
                            <input onChange={(e) => setVerificationCode(e.currentTarget.value)} style={{marginBottom: '10px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} placeholder="Verification Code"></input>
                            <span>Didn't Receive a Code?<button onClick={resendOtpUnverified} style={{backgroundColor: 'transparent', color: 'royalblue', borderStyle: 'none'}}><u>Click Here</u></button></span>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '35px'}}>
                            <button onClick={verifyCodeUnverifiedUser} type="button" className={classes.signInButton}>Verify Code</button>
                        </Grid>
                    </Grid>
                </Grid> :
                <Grid container>
                     <Grid item xs={12} className={classes.loginRegistrationContent}>
                        <Grid item xs={12}>
                            {verificationCodeError? <Alert severity="error">{verificationMessage}</Alert> :
                            <Alert severity="success">{verificationSuccessMessage}</Alert>}
                        </Grid>
                        <hr />
                        {loading? <LinearProgress sx={{width: '100%', height: '3px', marginBottom: '5px'}} /> : ''}
                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Please Enter your Verification Code Below</label><br/>
                            <input onChange={(e) => setVerificationCode(e.currentTarget.value)} style={{marginBottom: '10px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} placeholder="Verification Code"></input>
                            <span>Didn't Receive a Code?<button onClick={resendOtpCode} style={{backgroundColor: 'transparent', color: 'royalblue', borderStyle: 'none'}}><u>Click Here</u></button></span>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '35px'}}>
                            <button onClick={verifyCode} type="button" className={classes.signInButton}>Verify Code</button>
                        </Grid>
                    </Grid>
                </Grid>} </Grid>: <Grid container>
                {loginForm?
                    <Grid item xs={12} className={classes.loginRegistrationContent}>
                        <Grid container>
                            <Grid item xs={6}>
                                <button type="button" className={classes.primaryButton}>Login</button>
                            </Grid>
                            <Grid item xs={6}>
                                <button onClick={() => setLoginForms(0)} type="button" className={classes.altButton}>Register</button>
                            </Grid>
                        </Grid>
                        <hr />
                        {loading? <LinearProgress sx={{width: '100%', height: '3px', marginBottom: '5px'}} /> : ''}
                        {loginError? <Alert severity="error">{loginMessage}</Alert> : ""}
                        {loginSuccess? <Alert severity="success">{loginMessage}</Alert> : ""}
                        <Grid item xs={12} style={{marginTop: '35px'}}>
                            <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Email</label><br/>
                            <input onChange={(e) => setLoginUserEmail(e.currentTarget.value)} style={{height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} placeholder="Enter Email Address"></input>
                            <PersonIcon style={{marginLeft: '-40px', marginBottom: '-7px'}} />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Password</label><br/>
                            <input onChange={(e) => setLoginUserPassword(e.currentTarget.value)} style={{marginBottom: '10px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} type="password" placeholder="Enter Password"></input>
                            <LockIcon style={{marginLeft: '-40px', marginBottom: '-7px'}} />
                            <span>Forgot Password?<button onClick={() => setForgotPasswordForm(1)} style={{backgroundColor: 'transparent', color: 'royalblue', borderStyle: 'none'}}><u>Click Here</u></button></span>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '35px'}}>
                            <button onClick={transferToLogin} type="button" className={classes.signInButton}>Login</button>
                        </Grid>
                    </Grid>
                    :
                    <Grid item xs={12} className={classes.loginRegistrationContent}>
                        <Grid container>
                            <Grid item xs={6}>
                                <button onClick={() => setLoginForms(1)} type="button" className={classes.altButton}>Login</button>
                            </Grid>
                            <Grid item xs={6}>
                                <button type="button" className={classes.primaryButton}>Register</button>
                            </Grid>
                        </Grid>
                        <hr />
                        {loading? <LinearProgress sx={{width: '100%', height: '3px', marginBottom: '5px'}} /> : ''}
                        {signUpError? <Alert severity="error">{signUpMessage}</Alert> : ""}
                        <Grid item xs={12} style={{marginTop: '35px'}}>
                            {registerEmailError? <Alert style={{marginBottom: '5px'}} severity="error">{registerEmailErrorMessage}</Alert> : ""}
                            <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Email</label><br/>
                            <input onChange={(e) => setRegisterUserEmail(e.currentTarget.value)} style={{height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} placeholder="Enter Email Address"></input>
                            <PersonIcon style={{marginLeft: '-40px', marginBottom: '-7px'}} />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '20px'}}>
                            {registerPasswordError? <Alert style={{marginBottom: '5px'}} severity="error">{registerPasswordErrorMessage}</Alert> : ""}
                            <label style={{color: 'royalblue', fontSize: '1.2em', fontWeight: '800'}}>Password</label><br/>
                            <input onChange={(e) => setRegisterUserPassword(e.currentTarget.value)} style={{marginBottom: '10px', height: '45px', width: '100%', padding: '3px 10px', marginTop: '8px', fontSize: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '4px', borderStyle: 'none'}} type="password" placeholder="Enter Password"></input>
                            <LockIcon style={{marginLeft: '-40px', marginBottom: '-7px'}} />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '35px'}}>
                            <button onClick={beginRegistrationProcess} type="button" className={classes.signInButton}>Register</button>
                        </Grid>
                    </Grid> 
                } </Grid>
            } </Grid> } </Grid>  } </Grid>
        </div>
    </Grid>
  );
  
}

//export default withAuthenticator(Login);
export default Login;
