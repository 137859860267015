import DataTable from 'react-data-table-component';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Grid, Select, MenuItem, Input } from "@mui/material";
import Amplify from 'aws-amplify'
import { Auth } from 'aws-amplify';
import React,{useState,useEffect} from 'react';
//import { Patient,GroupInfo, LabOrder, OrderStatus } from '../../models';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from "react-toastify";
import { Button } from "@mui/material";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { ThemeConsumer } from 'styled-components';
// styles
import useStyles from "./styles";
import { Typography } from "../../components/Wrappers";

export default function UploadReports() {

const [groupList,setGroupList] = useState([])
const [patientList,setPatientList] = useState([])
const [columns, setColumns] = useState([]);
const [uploadData, setUploadData] = useState([]);
const [isFilesReadyToUpload,SetIsFilesReadyToUpload] = useState(false)

var [value, setValue] = useState("Feather");

var classes = useStyles();


useEffect(() => {
  setGroupList(["Feather","Prestige","ClearMD","Clear19"])
  return () => {
    console.log("Demo")
  }
}, [])


// process CSV data
 const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );
  
    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
  
        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
  
    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c
    }));
  
    setUploadData(list);
    setColumns(columns);
  
  };

  // handle file upload
const handleFileUpload = (e) => {
  
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = evt => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
      SetIsFilesReadyToUpload(true)
    };
    reader.readAsBinaryString(file);
  };

 // const getGroupName= async(groupName) =>  await DataStore.query(GroupInfo,gp =>gp.name("eq",groupName))

  /*const patientObj = (orderInfo) => 
                                new Patient({
                                  firstname: orderInfo.FirstName ,
                                  lastname: orderInfo.LastName,
                                  email: orderInfo.Email ,
                                  mobile: orderInfo.PhoneNumber,
                                  dob: new Date(orderInfo.DOB).toISOString().slice(0, 10)                                                                                            
                                })

                                
const labOrderObj = (orderInfo,patientInfo,grpInfo) =>
                              new LabOrder({
                                OrderId: orderInfo.OrderId,
                                CollectedDate: new Date(orderInfo.CollectedDate).toISOString(),
                                ReportedDate:  new Date(orderInfo.ReportedDate).toISOString(),
                                Status: OrderStatus.FINALIZED,
                                Result:orderInfo.Result,
                                ResultFile:orderInfo.ResultFile,
                                Notes:"",
                                DelveryStatus:false,
                                //Patient:patientInfo,
                               // GroupInfo: grpInfo 
                              })

*/
/*const createLabOrder= async(orderInfo) =>
   {
        let  orderingPatient = await savePatientOrGet(patientObj(orderInfo))
        let  groupObj        = await getGroupName(orderInfo.Group)
        let  newLabOrder     = labOrderObj(orderInfo,orderingPatient,groupObj[0])

        console.table(newLabOrder)
        try {
          await DataStore.save(newLabOrder)
          console.log(newLabOrder) 
        } catch(error)
        { 
          console.log("Error saving create LabOrder ", error ,' ',newLabOrder.OrderId);
        } 
   }
*/
  const savePatientOrGet = async(patientBasicInfo) =>
  {    
      let patientRecord = null
      try {                 
          const patientDataExisits= ""//await DataStore.query(Patient,pt => pt.firstname('eq',patientBasicInfo.firstname).lastname('eq',patientBasicInfo.lastname) )   
               

          if (patientDataExisits.length===0)
          {
            patientRecord = await DataStore.save(patientBasicInfo) 
            console.log("Patient Info saved successfully!");
          }
          else
          {
            patientRecord =patientDataExisits[0]
          }
    
    } catch (error) {
      console.log("Error saving Patient Info", error);
    }
    return patientRecord
  }

  const  createOrdersFromFile= async() =>
  {
      //uploadData.map(orderRecord =>  createLabOrder(orderRecord))
      
      toast('All the files upload sucessfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });     
  } 

  return (
    <>      
      <PageTitle title="Upload Covid Orders" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />        
        <ToastContainer />
      
        <Typography variant="h6">Group/Unit
      
        <Select
            value={value}
            onChange={e => setValue(e.target.value)}
            input={
              <Input
                disableUnderline
                classes={{ input: classes.selectInput }}
              />
            }
            className={classes.select}
          >
            {groupList.map(a=> <MenuItem value={a}>{a}</MenuItem> )}
          </Select>
          </Typography>

        <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload} />
        <Button                        
            variant="contained"
            size="medium"
            color="primary"
            onClick={createOrdersFromFile}            
            disabled ={!isFilesReadyToUpload} 
        >
            Upload Test Orders Now
        </Button>
        <DataTable pagination highlightOnHover columns={columns} data={uploadData} />
    </>
)
}