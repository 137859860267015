export const vaccineTypes = ['None', 'Johnson & Johnson',  'Pfizer' ,  'Moderna' ,'AstraZeneca' , 'Sinovac' ,  'Sinopharm' ,  'Other' ]
export const symptomsList = [
    'Fatigue' , 
    'Fever & Chills' , 
    'Cough' , 
    'Sore Throat' , 
    'Difficulty Breathing' , 
    'Loss of taste' , 
    'Nausea and vimiting' , 
    'Diarrahea' , 
    'Myalgia' , 
    'Headache' , 
    'Unspecified Abdominal pain' , 
    'Nasal Congestion' , 
    'No Symptoms' ]
export const medications2 = [
    'No Medications Reported',
    'General diabetes medication(insulin)' , 
     'General Birth Control' , 
      'General celiac disease medication' ,
      'General antidepressant',
      'General Thyroid' ,
      'Genral Blood Pressure' ,
      'Hormone medication' ,
      'General statins(cholesterol)' ,
      'Adderall' ,
      'Albuterol' ,
      'Aspirin' ,
      'Crestor' ,
      'Humira' ,
      'Levothyroxine' ,
      'Lexapro' ,
      'Lyrica' ,
      'Prozac' ,
      'Seasonale'  , 
      'Sovaldi' ,
      'Synthroid' ,
      'Zoloft' ,
      'Other' , 

]
export const allergiesL = [ 'No Known Drug Allergies (NKDA)', 'Penicillin', 'Sulfonamide', 'ACE inhibitors',' Prozac', 'Amoxicillin', 'Other']
export   const testTypes = [
    'RT-PCR (Covid Only)' , 
    'RT-PCR (Multiplex)' , 
    'Antigen' , 
    'Antigen + RT-PCR(Covid Only)', 
    'Antigen + RT-PCR(Multiplex)'
]
export const port ='http://172.16.0.245'


//Fatigue (R53.82), Fever & Chills (R50.81), Cough (R05), Sore Throat (R07.0),
// Difficulty Breathing (R06.02), Loss of taste (R43.9), Nausea and vomiting (R11.0) Diarrhea (R19.7),
// Myalgia (M79.10), Headache (R51.9), Unspecified Abdominal pain (R10), Nasal Congestion (R09.81), No Symptoms