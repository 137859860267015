import React from "react";
import {
  useJsApiLoader,
  // withScriptjs,
  GoogleMap,
  Marker,
  setMap,
} from "@react-google-maps/api";

// styles
import useStyles from "./styles";

const center = {
  lat: parseFloat(-37.813179),
  lng: parseFloat(144.950259),
}

function Maps() {
  const classes = useStyles();
  const [map, setMap] = React.useState(null)

  const onMapLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onMapUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    version: '3.exp',
    libraries: ["geometry","drawing","places"],
    googleMapsApiKey: "AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg" // todo: process.env.GOOGLE_MAPS_API_KEY
  })

  return isLoaded ? (
    <div className={classes.mapContainer}>
      <GoogleMap
        mapContainerStyle={{ height: "inherit", width: "inherit" }}
        center={center}
        zoom={10}
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        loadingElement={<div style={{ height: "inherit", width: "inherit" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  ) : <></>
}

export default React.memo(Maps);