import React, {useState,useMemo} from 'react';

import moment from "moment";

import axios from 'axios';
import { makeStyles } from "@mui/styles";
import ButtonGroup from '@mui/material/ButtonGroup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

import {
    Grid,
    Button
} from '@mui/material';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const apiUrl = process.env.REACT_APP_ENV === 'prod'
? "https://5hh32psqcj.execute-api.us-east-1.amazonaws.com/prod" 
: "https://bjb0pwkls9.execute-api.us-east-1.amazonaws.com/dev";

const useStyles = makeStyles(theme => ({
    locationButtonsContainer: {
       [theme.breakpoints.between("xs", "sm")]: {
            padding: '0 0',
        },
        [theme.breakpoints.between("sm", "md")]: {
            padding: '0 25%',
        },
        [theme.breakpoints.between("md", "lg")]: {
            padding: '0 30%',
        },
        [theme.breakpoints.up("lg")]: {
            padding: '0 35%',
        },
    },
    selectionsContainer: {
        [theme.breakpoints.between("xs", "sm")]: {
             padding: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
             padding: '0 15%',
         },
         [theme.breakpoints.between("md", "lg")]: {
             padding: '0 10%',
         },
         [theme.breakpoints.up("lg")]: {
             padding: '0 20%',
         },
     },
    tableMargins: {
        [theme.breakpoints.between("xs", "sm")]: {
            margin: '0 0',
         },
         [theme.breakpoints.between("sm", "md")]: {
            margin: '0 6%',
         },
         [theme.breakpoints.between("md", "lg")]: {
              margin: '0 10%',
         },
         [theme.breakpoints.up("lg")]: {
             margin: '0 15%',
         },
     },
     scanTubesMargins: {
 
         [theme.breakpoints.between("sm", "md")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.between("md", "lg")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.up("lg")]: {
            marginRight: '3%'
         },
         backgroundColor: 'royalblue', 
        color: 'white', 
        borderRadius: '6px', 
        padding: '10px 4px',
        transition: 'background-color .4s',
        "&:hover": {
            backgroundColor: 'lightgrey',
            color: 'royalblue'
        },
        cursor: 'pointer'
     },
     sendResultsMargins: {
 
         [theme.breakpoints.between("sm", "md")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.between("md", "lg")]: {
            marginRight: '3%'
         },
         [theme.breakpoints.up("lg")]: {
             marginRight: '3%'
         },
        backgroundColor: 'royalblue', 
        color: 'white', 
        transition: 'background-color .4s',
        "&:hover": {
            backgroundColor: 'lightgrey',
            color: 'royalblue'
        },
        borderRadius: '6px', 
        padding: '10px 4px',
        cursor: 'pointer'
     }
}));

export default function PaymentStatus(props){

    const classes = useStyles();
    const navigate = useNavigate();
    const [selectedLocation,setSelectedLocation] = useState('');
    const [generalLoading,setGeneralLoading] = useState(0);
    const [generalError,setGeneralError] = useState(0);
    const [generalErrorMessage,setGeneralErrorMessage] = useState('');
    const [generalSuccess,setGeneralSuccess] = useState(0);
    const [generalSuccessMessage,setGeneralSuccessMessage] = useState('');
    const [listTransactions,setListTransactions] = useState([]);
    const [listTransactionsBackup,setListTransactionsBackup] = useState([]);
    const [loading,setLoading] = useState(0);
    const [search,setSearch] = useState('');
    const [transactionData,setTransactionData] = useState([]);
    const [transactionDataBackup,setTransactionDataBackup] = useState([]);

    async function listTransactionFromSquare(location){
        let errors = 0;
        let response;
        let incompleteTransactions;
        try{
            // response = await axios.get(`https://bjb0pwkls9.execute-api.us-east-1.amazonaws.com/dev/listsquaretransaction?location=${location}`);
            [response,incompleteTransactions] = await Promise.all([axios.get(`${apiUrl}/unsettledTransaction`),axios.get(`${apiUrl}/incompleteorders?location=${location}`)]);
            // response = await axios.get('https://bjb0pwkls9.execute-api.us-east-1.amazonaws.com/dev/unsettledTransaction');
            // console.log(response);
            // incompleteTransactions = await axios.get(`https://bjb0pwkls9.execute-api.us-east-1.amazonaws.com/dev/incompleteorders?location=${location}`);
            // console.log("reponse: ",incompleteTransactions);
            // console.log("transaction: ",incompleteTransactions);
        }
        catch(e){
            errors+=1;
        }
        finally{
            if(errors){
                return "NETWORK_ERROR";
            }
            else{
                if(response.data.data === "ERROR" || incompleteTransactions.data.data === "ERROR"){
                    return "GENERAL_ERROR";
                }
                else{
                    // const completeTransactions = response.data.completed.sort((transaction1,transaction2) => new moment(transaction1.created) > new moment(transaction2.created));
                    // setListTransactions(completeTransactions);

                    const completeTransactions = response.data.message.list.filter(transaction => transaction.invoiceNumber !== undefined && transaction.invoiceNumber.includes(location));
                    setListTransactions(completeTransactions);
                    setListTransactionsBackup(completeTransactions);

                    const backup = incompleteTransactions.data.payments.sort((transaction1,transaction2) => new moment(transaction1.appointmentDatetime.S) - new moment(transaction2.appointmentDatetime.S));
                    setTransactionData(backup);
                    setTransactionDataBackup(backup);
                    // const completeTransactions = response.data.payments
                    // .filter(element => element.transactionComplete.BOOL == true)
                    // .sort((transaction1,transaction2) => new moment(transaction1.appointmentDatetime) > new moment(transaction2.appointmentDatetime));
                    // setListTransactions(completeTransactions);
                    // const backup = response.data.payments
                    // .filter(element => element.transactionComplete.BOOL == false)
                    // .sort((transaction1,transaction2) => new moment(transaction1.appointmentDatetime.S) - new moment(transaction2.appointmentDatetime.S));

                    // console.log(backup);
                    // setTransactionData(backup);
                    // setTransactionDataBackup(backup);
                    return "SUCCESS";
                }
            }
        }
    }

    async function selectLocation(location){
        setSelectedLocation(location);
        setLoading(1);

        let errors = 0;
        let response;

        try{
            response = await listTransactionFromSquare(location);
        }
        catch(e){
            errors+=1;
        }
        finally{
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage('Something Went Wrong');
            }
            else if(response !== "SUCCESS"){
                setGeneralError(1);
                setGeneralErrorMessage(response);
            }
            // setListTransactions(listTransactionsBackup.filter(transaction => transaction.invoiceNumber != undefined && transaction.invoiceNumber.includes(location)));
            setLoading(0);
        }
    }

    const renderTransactionTable = useMemo(() => {
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>Note</th>
                        <th style={{paddingBottom: '10px'}}>Time Paid</th>
                        <th style={{paddingBottom: '10px'}}>Amount</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {listTransactions.map((rowItem,index) => {
                        return(
                            <tr key={rowItem.transId}>
                                <td>{`${rowItem.firstName} ${rowItem.lastName} ${rowItem.invoiceNumber}`}</td>
                                <td>{moment(rowItem.submitTimeLocal).format('hh:mm a')}</td>
                                <td>{`$${rowItem.settleAmount}`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    },[listTransactions]);

    const renderTransactionDataTable = useMemo(() => {
        return(
            <table style={{width: '100%', borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', padding: '15px 15px'}}>
                <thead>
                    <tr style={{textAlign: 'left', color: 'royalblue'}}>
                        <th style={{paddingBottom: '10px'}}>First</th>
                        <th style={{paddingBottom: '10px'}}>Last</th>
                        <th style={{paddingBottom: '10px'}}>date</th>
                        <th style={{paddingBottom: '10px'}}>Amount</th>
                        <th style={{paddingBottom: '10px'}}>Confirm</th>
                    </tr>
                </thead>
                <tbody style={{textAlign: 'left'}}>
                    {transactionData.map((rowItem,index) => {
                        return(
                            <tr key={rowItem.patientId.S + '-' + index}>
                                <td>{rowItem.firstName}</td>
                                <td>{rowItem.lastName}</td>
                                <td>{moment(rowItem.appointmentDatetime.S).format("h:mm:a")}</td>
                                <td>{rowItem.price.S}</td>
                                <td><Button style={{color:''}} onClick={() => confirmPayment(index)} >Complete</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    },[transactionData]);

    const goBack = () => {
        if(selectedLocation !== ''){
            setSelectedLocation('');
        }
        else{
            navigate(-1);
        }
    }

    const closeGeneralError = () => {
        setGeneralErrorMessage('');
        setGeneralError(0);
    }
    
    const closeGeneralSuccess = () => {
        setGeneralSuccess(0);
        setGeneralSuccessMessage('');
    }

    const criteriaChanged = (value) => {
        setSearch(value);
        searchTransactions(value);
    }

    const [transactionIndex,setTransactionIndex] = useState(-1);
    const [confirm,setConfirm] = useState(0);
    const confirmPayment = (index) => {
        setTransactionIndex(index);
        setConfirm(1);
    }

    const searchTransactions = (value) => {
        if(value === ''){
            setTransactionData(transactionDataBackup);
        }
        else{
            const searchText = value.trim().split(' ');
            if(searchText.length == 1){
                const newData = transactionDataBackup.filter(transactions => transactions.firstName.toLowerCase().startsWith(searchText[0]) || transactions.lastName.toLowerCase().startsWith(searchText[0]));
                setTransactionData(newData);
            }
            else if(searchText.length == 2 && !searchText.includes('')){
                const [first,last] = searchText;
                const newData = transactionDataBackup.filter(transactions => transactions.firstName.toLowerCase().startsWith(first) && transactions.lastName.toLowerCase().startsWith(last))
                setTransactionData(newData);
            }
        }
    }

    // const delay = ms => new Promise(res => setTimeout(res, ms));

    const moveTransactionToTable = (index) => {
        // const newListTransactionData = [...listTransactions];
        // const temp = {...transactionDataBackup[index]};
        // temp.amountPaid = temp.price;
        // newListTransactionData.unshift(temp);
        // setListTransactions(newListTransactionData);
        const newTransactionData = transactionDataBackup.filter((element,i) => i != index);
        setTransactionData(newTransactionData);
        setTransactionDataBackup(newTransactionData);
    }

    const completePayment = async () => {
        setGeneralLoading(1);
        let errors=0;
        let response;
        let delresponse;
        try{
            const transactionToComplete = transactionData[transactionIndex];
            // console.log(transactionToComplete);
            response = await axios.post(`${apiUrl}/chargeAuthorizeProfile`,
            {
                patientId: transactionToComplete.patientId.S,
                appointmentDatetime: transactionToComplete.appointmentDatetime.S,
                cardId: transactionToComplete.cardId.S,
                customerId: transactionToComplete.customerId.S,
                location: selectedLocation,
                acuityId:transactionToComplete.acuityId.N,
                price: transactionToComplete.price.S,
                note: `ClearMD Scheduling: ${transactionToComplete.lastName},${transactionToComplete.firstName}`,
            });
            // console.log(response);
            if(response.data?.data === "SUCCESS"){
                delresponse = await axios.delete(`${apiUrl}/chargeAuthorizeProfile?customerPaymentProfileId=${transactionToComplete.cardId.S}&customerId=${transactionToComplete.customerId.S}`);
                console.log("Delete response: ",delresponse);
            }
        }
        catch(e){
            console.log(e);
            errors+=1;
        }
        finally{
            if(errors){
                setGeneralError(1);
                setGeneralErrorMessage('Network Error');
            }
            else if(response.data?.data === "ERROR"){
                setGeneralError(1);
                setGeneralErrorMessage(response.data.message);
            }
            else if(delresponse.data?.data === "ERROR"){
                setGeneralError(1);
                setGeneralErrorMessage(delresponse.data.message);
            }
            else{
                setGeneralSuccess(1);
                setGeneralSuccessMessage('Transaction completed');
                moveTransactionToTable(transactionIndex);
                setTransactionIndex(-1);
            }
        }
        setGeneralLoading(0);
    }

    const handleCloseConfirm = async (number) => {
        if(number){
            // console.log('yes');
            await completePayment();
        }
        else{
            // console.log('no');
            setConfirm(0);
            setGeneralLoading(0);
        }
        if(!generalLoading)
            setConfirm(0);
    }

    return(
        <Grid container>
            <Grid item xs={12} style={{textAlign: 'center'}}>
                <Grid item xs={12}>
                    <Button onClick={() => goBack()} color="primary" style={{backgroundColor: '#EDEEF7', color: 'royalblue', borderStyle: 'none', float: 'right', marginBottom: '5px'}} variant="outlined" size="medium">
                        Back
                    </Button>
                </Grid>
                <Snackbar open={generalError} autoHideDuration={6000} onClose={closeGeneralError}>
                    <Alert onClose={closeGeneralError} severity="error" sx={{ width: '100%' }}>
                    {generalErrorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={generalSuccess} autoHideDuration={6000} onClose={closeGeneralSuccess}>
                    <Alert onClose={closeGeneralSuccess} severity="success" sx={{ width: '100%' }}>
                    {generalSuccessMessage}
                    </Alert>
                </Snackbar>
                <h1 style={{color: 'royalblue', marginBottom: '75px', textAlign: 'left', marginTop: '10px'}}>Welcome To The Payment Menu</h1>
                <Grid container className={classes.selectionsContainer}>
                    <Grid container style={{paddingTop:'10px'}}>
                        {(!selectedLocation && !loading) ? <Grid item xs={12} sm={12} md={12}>
                            <ButtonGroup>
                                <Button onClick={() => selectLocation('noho')}>Noho</Button>
                                <Button onClick={() => selectLocation('chelsea')}>Chelsea</Button>
                                <Button onClick={() => selectLocation('chinatown')}>Chinatown</Button>
                                <Button onClick={() => selectLocation('uws')}>UWS</Button>
                                <Button onClick={() => selectLocation('ues')}>UES</Button>
                                <Button onClick={() => selectLocation('midtown')}>Midtown</Button>
                                <Button onClick={() => selectLocation('astoria')}>Astoria</Button>
                            </ButtonGroup>
                        </Grid> 
                        : 
                        <Grid container style={{paddingTop:'10px'}} >
                            {loading? <LinearProgress style={{width: '100%', marginBottom: '10px'}} /> 
                            :
                            <Grid container>
                                <Grid item xs={10}>
                                    <h2 style={{color:'royalblue'}}>Latest transactions for {selectedLocation}</h2>
                                </Grid>
                                <Grid item xs={2} style={{paddingTop:'15px'}}>
                                    <Button style={{color:'royalblue'}} onClick={() => selectLocation(selectedLocation)}>Refresh</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {renderTransactionTable}
                                </Grid>
                                <Grid container style={{paddingTop:'15px'}}>
                                    <Grid item xs={12} style={{paddingBottom: '15px'}}>
                                        <Button style={{pointerEvents: 'none', backgroundColor: 'transparent', marginRight: '-65px', marginBottom: '3px', borderStyle: 'none', color: 'royalblue'}}><SearchIcon sx={{"&:hover": {backgroundColor: 'white'}}} /></Button>
                                        <input value={search} onChange={(event) => criteriaChanged(event.target.value)} placeholder="Search Transactions" style={{borderStyle: 'solid', borderColor: 'royalblue', borderWidth: '1px', height: '40px', width: '100%', padding: '10px 60px', fontSize: '105%', borderRadius: '50px'}}></input>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {renderTransactionDataTable}
                                        {confirm ? 
                                            <Dialog open={confirm} onClose={() => handleCloseConfirm(0)}>
                                                <DialogTitle>
                                                    {"Confirmation"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        Are you sure you want to complete this transaction
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    {generalLoading ? <Grid item style={{paddingRight:'1%',paddingTop:'1%'}}><CircularProgress size={20} /></Grid> : ''}
                                                    <Button disabled={generalLoading} onClick={() => handleCloseConfirm(1)}>Yes</Button>
                                                    <Button onClick={() => handleCloseConfirm(0)}>No</Button>
                                                </DialogActions>
                                            </Dialog> 
                                            : ''
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>}
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}