import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip
} from "@mui/material";
import useStyles from "../../styles";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const states = {
  negative: "success",
  positive: "secondary",
  inconclusive: "warning" ,
};

export default function TableComponent({ data }) {
  const classes = useStyles();
  var keys = Object.keys(data[0]).map(i => i.charAt(0).toUpperCase()+i.slice(1).toLowerCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {keys.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, name, email, phone, lvlube, collected, resulted,report, status }) => (
          <TableRow key={id}>
            <TableCell className="pl-3 fw-normal">{name}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{phone}</TableCell>            
            <TableCell>{lvlube}</TableCell>
            <TableCell>{collected}</TableCell>
            <TableCell>{resulted}</TableCell> 
            <TableCell><a href='www.google.com '><PictureAsPdfIcon/>{report}</a></TableCell>
                       
            <TableCell>
              <Chip label={status}  classes={{root: classes[states[status.toLowerCase()]]}}/>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
