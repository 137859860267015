import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/styles';

import { Typography } from '../../components/Wrappers/Wrappers';

export const Label = styled(Typography)(({ theme, children, ...props }) => {
  return {
    gutterBottom: true,
    noWrap: true,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    ...props
  };
});

export const Value = styled(Typography)(({ theme, children, ...props }) => ({
  gutterBottom: true,
  noWrap: true,
  color: theme.palette.text.primary,
  ...props
}));

export const Heading = styled(Typography)(({ theme, children, ...props }) => ({
  gutterBottom: true,
  noWrap: true,
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  ...props
}));

export const IdImage = ({ ...props }) => {
  return (
    <Box
      component='img'
      width={{
        xs: '100%',
        md: 190,
        lg: 265
      }}
      height='auto'
      boxShadow={10}
      borderRadius={2}
      {...props}
    />
  );
};