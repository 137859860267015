import React, { useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar
} from '@mui/material';
import { 
  Home, 
  Menu,
  Summarize,
  Dashboard,
  Upload,
  Search,
  ForwardToInbox,
  Paid,
 } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import { useUserState } from '../../context/UserContext';

const menuItems = [{
  label: 'Home',
  icon: <Home />,
  path: '/app',
  allowed: [],
  blocked: [],
},
{
  label: 'My Reports',
  icon: <Summarize />,
  path: '/app/myreports',
  allowed: ['Patient'],
  blocked: [],
},
// Admin
// Compliance
{
  label: 'Dashboard',
  icon: <Dashboard />,
  path: '/app/dashboard',
  allowed: ['Admin', 'Compliance'],
  blocked: [],
},
{
  label: 'Compliance Reports',
  icon: <Summarize />,
  path: '/app/compliancereports',
  allowed: ['Admin', 'Compliance'],
  blocked: [],
},
// Doctor
{
  label: 'Patient Upload',
  icon: <Upload />,
  path: '/app/uploadpatientdata',
  allowed: ['Admin', 'Compliance', 'Doctor'],
  blocked: [],
},
{
  label: 'Reports Menu',
  icon: <Menu />,
  path: '/app/reportsmenu',
  allowed: ['Admin', 'Compliance', 'Doctor'],
  blocked: [],
},
{
  label: 'Reports Upload',
  icon: <Upload />,
  path: '/app/uploadreports',
  allowed: ['Admin', 'Compliance', 'Doctor'],
  blocked: [],
},
// MedicalStaff
{
  label: 'Payment Status',
  icon: <Paid />,
  path: '/app/paymentstatus',
  allowed: [],
  blocked: ['Patient'],
},
{
  label: 'Result Search',
  icon: <Search />,
  path: '/app/resultsearch',
  allowed: [],
  blocked: ['Patient'],
},
{
  label: 'Send Results',
  icon: <ForwardToInbox />,
  path: '/app/results',
  allowed: [],
  blocked: ['Patient'],
},
{
  label: 'Patient Lookup',
  icon: <Search />,
  path: '/app/patientlookup',
  allowed: [],
  blocked: ['Patient'],
} , 
{
  label: 'Progress Notes',
  icon: <Search />,
  path: '/app/progressnotes',
  allowed: [],
  blocked: ['Patient'],
} , 

];

const allowed = (userGroup, item) => {
  return item.allowed.includes(userGroup) || item.allowed.length === 0;
}

const blocked = (userGroup, item) => {
  return item.blocked.includes(userGroup);
}

export function SideNav() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const { userGroup } = useUserState();

  useEffect(() => {
    console.log(userGroup);
  }, [userGroup])

  return (
    <>
      <IconButton 
        sx={{
          color: 'white',
          '&:hover': {
            backgroundColor: 'transparent'
          },
        }}
        onClick={() => setOpen(!open)}
      >
        <Menu />
      </IconButton>
      <Drawer open={open} onClose={()=> setOpen(false)} variant='temporary' PaperProps={{
        sx: { width: 240 }
      }}>
        <Toolbar />
        <List>
            {menuItems.map((item, index) => (
              allowed(userGroup, item) && !blocked(userGroup, item)
              ? <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => navigate(item.path)}>
                  <ListItemIcon>
                      {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem> : <></>
            ))}
        </List>
      </Drawer>
    </>
  );
}

export default SideNav;

